import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Radio, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { NJVInput } from "../../../components/core-component";
import Theme from "../../../components/theme";
import { checkValidFileSize } from "../../../components/validator";
import Api from "../../../network/api";
import { FILE_UPLOAD_PATH } from "../../../network/filePath";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { ApiHandler, FileUpload } from "../../../network/network-manager";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ShipperDirectoryProductForm = ({ open = false, data = null, onFinish }) => {


    const [currentStore, setCurrentStore] = useState(null)
    const [openProductModal, setOpenProductModel] = useState(false)
    const [productImageUrl, setProductImageUrl] = useState(null)
    const [productFileList, setProductFileList] = useState([])
    const [isCreating, setIsCreating] = useState(false)

    const [form] = Form.useForm();

    useEffect(() => {
        setCurrentStore(data)
        form.resetFields();
        setOpenProductModel(open)
    }, [data, form, open])

    const clearForm = () => {
        setProductImageUrl(null)
        setProductFileList([])
        form.resetFields();
        if (onFinish) {
            onFinish()
        }
        setOpenProductModel(false)
    }

    const saveStoreForm = async (formData) => {
        setIsCreating(true)
        formData['storeId'] = currentStore.id
        formData['productCoverUrl'] = productImageUrl
        ApiHandler({ url: Api.userstore_product, method: HTTP_METHOD.POST, requestData: formData })
            .then(() => {
                clearForm()
            })
            .catch(() => { })
            .finally(() => {
                setIsCreating(false)
            })
    }

    const handleChange = (uploadObject) => {
        if (checkValidFileSize(uploadObject.file)) {
            FileUpload({ file: uploadObject?.file, filePath: FILE_UPLOAD_PATH.USER_STORE_PRODUCT_IMAGES })
                .then((response) => {
                    setProductImageUrl(response)
                    setProductFileList(uploadObject.fileList)
                })
                .catch(() => { })
        }
    }

    return (
        <>
            <Modal
                title="Product Form"
                open={openProductModal}
                closable={true}
                onCancel={() => clearForm()}
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    initialValues={{ statusFlag: 'ACTIVE' }}
                    onFinish={saveStoreForm}
                >
                    <Row gutter={[24, 16]}>
                        <Col span={24}>
                            <Upload
                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                listType="picture-card"
                                fileList={productFileList}
                                multiple={false}
                                maxCount={1}
                                action={null}
                                onChange={(obj) => handleChange(obj)}
                                beforeUpload={() => false}
                            >
                                {productFileList?.length >= 1 ? null :
                                    <div>
                                        <PlusOutlined />
                                        <div
                                            style={{
                                                marginTop: 8,
                                            }}
                                        >
                                            Upload Product Image
                                        </div>
                                    </div>}
                            </Upload>
                        </Col>

                        <Col span={24} >
                            <Form.Item name="productName" label={"Product Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                <NJVInput placeholder={"Enter product name"} bgcolor={Theme.colors.input_bg_color} />

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="productDescription" label={"Description"}>
                                <NJVInput placeholder={"Enter product description"} bgcolor={Theme.colors.input_bg_color} istextareainput={true} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="statusFlag">
                                <Radio.Group>
                                    <Radio value={'ACTIVE'}>Active</Radio>
                                    <Radio value={'INACTIVE'}>Inactive</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={isCreating}>Create</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal>
        </>
    );
}
export default ShipperDirectoryProductForm