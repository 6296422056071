import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, Divider, Form, Input, Modal, Row, Select, Upload, message } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVBackButton, NJVSearchFilterOption } from "../../../components/core-component";
import DynamicEnumSelect from "../../../components/fetch-enum";
import Theme, { Colors } from "../../../components/theme";
import { AdminPasswordValidator, CharacterLimit, NumberOnly, NumberOnlyWithLimit, NumberOrCharacterOnly, RestrictPhoneNumberValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { MEDIA_TYPE } from "../../../network/mediaType";
import { ApiFilter, ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const { TextArea } = Input
class LoyaltyShopForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shopTypes: [],
            previewImage: null,
            previewTitle: null,
            roleData: [],
            roleOptions: [],
            currentDate: '',
            profileFileList: [],
            coverFileList: [],
            singleFile: {},
            isLoading: false,
            previewOpen: false,
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
            showBranchCodeInputBox: false,
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchAllDivision()
    }

    fetchAllDivision = async () => {
        try {
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                })
                this.fetchData(townshipMap)
            }
        } catch (error) {
        }
    }

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }

    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };


    saveLoyaltyShop = async (values) => {
        this.setState({
            isLoading: true
        })
        if (values.password !== values.confirmPassword) {
            message.destroy()
            message.error("Confirm password does not match")
            this.setState({
                isLoading: false
            })
            return
        }
        let formData = new FormData()
        formData.append('fullName', values.fullName);
        formData.append('shopType', values.shopType);
        formData.append('tag', values.tag);
        formData.append('shopAbout', values.shopAbout ? values.shopAbout : '');
        formData.append("address1", values.address1)
        formData.append("township.id", values.townshipId)
        formData.append("phoneNumber", values.contactPhoneNumber ? values.contactPhoneNumber : '')
        formData.append('contactPhoneNumber', values.contactPhoneNumber ? values.contactPhoneNumber : '')
        formData.append('contactFacebook', values?.contactFacebook ? values?.contactFacebook : '')
        formData.append('contactMessenger', values?.contactMessenger ? values?.contactMessenger : '')
        formData.append('contactViber', values?.contactViber ? values?.contactViber : '')
        formData.append('contactTelegram', values?.contactTelegram ? values?.contactTelegram : '')
        formData.append('contactWebsite', values?.contactWebsite ? values?.contactWebsite : '')
        formData.append('branchCode', values.branchCode ? values.branchCode : '')
        formData.append('latitude', values?.latitude ? values?.latitude : 0)
        formData.append('longitude', values?.longitude ? values?.longitude : 0)
        formData.append('approvalStatus', 'APPROVED');
        formData.append('userStatus', values.active ? "ACTIVE" : "INACTIVE");
        formData.append('accounts[0].loginId', values.shopCode);
        formData.append('accounts[0].password', values.password);
        formData.append('accounts[0].accountType', "UNIQUE_ID");
        formData.append('shipperDiscount', values.shipperDiscount);
        formData.append('goldDiscount', values.goldDiscount);
        formData.append('platinumDiscount', values.platinumDiscount);
        formData.append('loyaltyDiscountType', values.loyaltyDiscountType);
        if (values?.profileUrl?.file) {
            formData.append('shopProfile', values.profileUrl.file)
        }
        if (values?.coverUrl?.file) {
            formData.append('shopCover', values.coverUrl.file)
        }
        await ApiHandler({ url: Api.loyalty_shop, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData })
            .then(() => { this.props.navigate(CustomPath.loyalty_shop) })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }



    handleChange = (type, uploadObject) => {

        if (this.checkValidFileSize(uploadObject.file)) {
            if (type === 'cover') {
                this.setState({
                    coverFileList: uploadObject.fileList
                })
            } else {
                this.setState({
                    profileFileList: uploadObject.fileList
                })
            }
        }


    }
    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    };
    checkValidFileSize = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.destroy()
            message.error('Image must smaller than 5MB!');
        }
        return isLt2M;
    };

    onFinishFailed = () => {
        message.destroy()
        message.error('Please fill required fields');
    }

    render() {
        const { showBranchCodeInputBox, isLoading, profileFileList, coverFileList, previewOpen, previewImage, previewTitle, division_collection, township_collection, shopTypes } = this.state
        return (
            <>
                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.loyalty_shop}>Loyalty Shop</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />

                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Loyalty Shop Form"}
                    extra={<Link to={CustomPath.loyalty_shop}><NJVBackButton /></Link>}
                >
                    <Form
                        ref={this.inputRef}
                        layout="vertical"
                        initialValues={{ active: true }}
                        onFinish={this.saveLoyaltyShop}
                        onFinishFailed={this.onFinishFailed}
                    >
                        <Row gutter={[16, 16]}>
                            <Col span={8} >
                                <Form.Item name="shopCode" label={"Shop Code"} rules={[{ required: true, message: '*(requried)' }, { validator: NumberOrCharacterOnly }]}>
                                    <Input placeholder={"Enter shop code"} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="password" label={"Password"}
                                    rules={[{ required: true, message: '*(requried)' },
                                    { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" autocomplete="new-password" />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="confirmPassword" label={"Confirm Password"}
                                    rules={[{ required: true, message: '*(requried)' },
                                    { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter confirm password" />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item name="fullName" label={"Shop Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter shop name"} />
                                </Form.Item>
                            </Col>
                            <Col span={8}>
                                <Form.Item name="shopType" label={"Shop Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <DynamicEnumSelect enumtype="LOYALTY_SHOP_TYPE" size="large" style={{ width: '100%' }} placeholder="Select Shop Type" />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item name="tag" label={"Tag"} rules={[{ required: true, message: '*(requried)' }, {
                                    validator: CharacterLimit
                                }]}>
                                    <Input placeholder={"Enter shop tag"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select Division"}
                                        showSearch
                                        filterOption={NJVSearchFilterOption}
                                        onChange={this.handleDivisionSelect}
                                        size="large"
                                        options={
                                            division_collection && division_collection.map((division) => {
                                                return {
                                                    value: division.id,
                                                    label: division.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select township"}
                                        showSearch
                                        filterOption={NJVSearchFilterOption}
                                        onChange={this.handleTownshipSelect}
                                        size="large"
                                        options={
                                            township_collection && township_collection.map((township) => {
                                                return {
                                                    value: township.id,
                                                    label: township.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="address1" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter address"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="shopAbout" label={"Exclusive Member Discounts"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        maxLength={200}
                                        showCount={true}
                                        style={{ height: 60 }} placeholder="Enter exclusive member discounts" />
                                </Form.Item>
                            </Col>

                            <Col span={6} >
                                <Form.Item name="latitude" label={"Latitude"} >
                                    <Input placeholder={"Enter latitude"} />
                                </Form.Item>
                            </Col>
                            <Col span={6} >
                                <Form.Item name="longitude" label={"Longitude"} >
                                    <Input placeholder={"Enter longitude"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} />
                            <Col span={8}>
                                <Form.Item name="loyaltyDiscountType" label={"Discount Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <DynamicEnumSelect enumtype="LOYALTY_DISCOUNT_TYPE" size="large" style={{ width: '100%' }} placeholder="Select discount type" />
                                </Form.Item>
                            </Col>
                            <Col span={16} />
                            <Col span={8} >
                                <Form.Item name="shipperDiscount" label={"Shipper Level Discount"} rules={
                                    [
                                        { required: true, message: '*(requried)' },
                                        {
                                            validator: NumberOnly
                                        }
                                    ]}>
                                    <Input placeholder={"Enter shipper level discount"} />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item name="goldDiscount" label={"Gold Level Discount"} rules={
                                    [
                                        { required: true, message: '*(requried)' },
                                        {
                                            validator: NumberOnly
                                        }
                                    ]}>
                                    <Input placeholder={"Enter gold level discount"} />
                                </Form.Item>
                            </Col>
                            <Col span={8} >
                                <Form.Item name="platinumDiscount" label={"Platinum Level Discount"} rules={
                                    [
                                        { required: true, message: '*(requried)' },
                                        {
                                            validator: NumberOnly
                                        }
                                    ]}>
                                    <Input placeholder={"Enter platinum level discount"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="branchCodeCheckBox"
                                    valuePropName="checked">
                                    <Checkbox onChange={(event) => this.setState({ showBranchCodeInputBox: event.target.checked })}>Branch Code</Checkbox>
                                </Form.Item>
                            </Col>
                            {
                                showBranchCodeInputBox ?
                                    <Col span={12}>
                                        <Form.Item name="branchCode" label={"Branch Code"} rules={[{ validator: (_, value) => NumberOnlyWithLimit(value, 10) }]}>
                                            <Input placeholder={"Enter branch code"} />
                                        </Form.Item>
                                    </Col>
                                    :
                                    <></>
                            }

                            <Col span={24}>
                                <Divider orientation="left" variant="dashed">
                                    Contact Info
                                </Divider>
                            </Col>

                            <Col span={12} >
                                <Form.Item name="contactPhoneNumber" label={"Phone Number"} rules={[{ required: true, message: '*(requried)' }, { validator: RestrictPhoneNumberValidator }]}>
                                    <Input placeholder={"09xxxxxxxxx"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="contactFacebook" label={"Facebook Page ID"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter facebook page ID"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="contactMessenger"
                                    label={
                                        <>
                                            Messenger Page ID
                                        </>
                                    }
                                >
                                    <Input placeholder={"Enter messenger page ID"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="contactViber"
                                    label={
                                        <>
                                            Viber Phone Number
                                        </>
                                    }>
                                    <Input placeholder={"Enter Viber Phone Number"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="contactTelegram"
                                    label={
                                        <>
                                            Telegram <span style={{ color: Colors.gray, fontStyle: 'italic', marginLeft: 5 }}>(Hint: https://t.me/yourusername)</span>
                                        </>
                                    } >
                                    <Input placeholder={"Enter telegram link"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="contactWebsite"
                                    label={
                                        <>
                                            Website <span style={{ color: Colors.gray, fontStyle: 'italic', marginLeft: 5 }}>(Hint: https://yourwebsite.com/)</span>
                                        </>
                                    }>
                                    <Input placeholder={"Enter website link "} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item name="profileUrl" label={"Upload Profile"}>
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={profileFileList}
                                        multiple={false}
                                        maxCount={1}
                                        action={null}
                                        onPreview={this.handlePreview}
                                        onChange={(obj) => this.handleChange('profile', obj)}
                                        beforeUpload={() => false}
                                    >
                                        {profileFileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={3}>
                                <Form.Item name="coverUrl" label={"Upload Cover"}>
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={coverFileList}
                                        multiple={false}
                                        maxCount={1}
                                        action={null}
                                        onPreview={this.handlePreview}
                                        onChange={(obj) => this.handleChange('cover', obj)}
                                        beforeUpload={() => false}
                                    >
                                        {coverFileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(LoyaltyShopForm)