import { compose } from "@reduxjs/toolkit";
import { Button, Col, Flex, Row, Space, Typography } from 'antd';
import React, { Component } from 'react';
import { SvgExcelOutlined, SvgPdfOutlined, SvgPngOutlined } from '../../components/custom-svg';
import { TitleLevel3 } from '../../components/general-component';
import NJVTable from '../../components/njv-table';
import Theme from '../../components/theme';
import { FORM_ITEM } from '../../core/constant';
import Api from '../../network/api';
import { DownloadFileWithUrl } from '../../network/network-manager';
import withRouter from "../../network/with-router";
const { Paragraph } = Typography;

class InvoiceList extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDownloading: false
    }
  }

  updateStorageUrl = (url) => {
    return url.replace(
      'https://d1jejw4ssai0l5.cloudfront.net/',
      'https://ninjavanstorages.s3.ap-southeast-1.amazonaws.com/'
    );
  }

  downloadInvoice = (downloadLink, invoice) => {
    const updateLink = this.updateStorageUrl(downloadLink);
    const fileName = invoice.shipperName + "_Invoice_" + invoice.invoiceDate

    this.setState({ isDownloading: true })

    DownloadFileWithUrl(updateLink, fileName)
      .then(() => { })
      .catch(() => { })
      .finally(() => {
        this.setState({
          isDownloading: false,
        })
      });
  }

  isInvoiceDownload = (name, record, url) => {
    if (url) {
      this.downloadInvoice(url, record)
    }
    this.setState({
      currentInvoice: {
        name,
        id: record.id
      }
    })
  }

  render() {
    const { isDownloading, currentInvoice } = this.state

    const shipperColumns = [
      {
        title: 'Shipper Name',
        key: 'shipperName',
        dataIndex: 'shipperName'
      },
      {
        title: 'Delivery Completed Date',
        key: 'fromDate',
        dataIndex: 'fromDate',
        render: (_, record) => (
          <Flex vertical gap={5}>
            <span>
              <span style={{ fontWeight: 500 }}>From Date</span>: {record.fromDate}
            </span>
            <span>
              <span style={{ fontWeight: 500 }}>To Date</span>: {record.toDate}
            </span>
          </Flex>
        )
      },
      {
        title: 'Total Remittance Value',
        key: 'totalRemittanceAmount',
        dataIndex: 'totalRemittanceAmount',
        align: 'center'
      },
      {
        title: 'Invoice Number',
        key: 'invoiceNo',
        dataIndex: 'invoiceNo',
      },
      {
        title: 'Invoice Date',
        key: 'invoiceDate',
        dataIndex: 'invoiceDate',
      },
      {
        title: 'Invoice Unique Id',
        key: 'uniqueId',
        dataIndex: 'uniqueId',
        width: 200,
        render: (value) => value && (
          <Paragraph copyable={{ text: `https://www.ninjavan-mm.co/public/invoice?uniqueId=${value}` }}>
            {value}
          </Paragraph>
        ),
      },
      {
        title: 'Action',
        key: 'action',
        dataIndex: 'action',
        align: 'center',
        render: (_, record) => (
          <Space>
            <Button size={'large'} type="text" shape="circle" loading={currentInvoice?.name === 'PDF' && currentInvoice?.id === record.id ? isDownloading : false} onClick={() => this.isInvoiceDownload('PDF', record, record?.pdfDownloadUrl)}><SvgPdfOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button>
            <Button size={'large'} type="text" shape="circle" loading={currentInvoice?.name === 'EXCEL' && currentInvoice?.id === record.id ? isDownloading : false} onClick={() => this.isInvoiceDownload('EXCEL', record, record?.excelDownloadUrl)}><SvgExcelOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button>
            <Button size={'large'} type="text" shape="circle" loading={currentInvoice?.name === 'IMG' && currentInvoice?.id === record.id ? isDownloading : false} onClick={() => this.isInvoiceDownload('IMG', record, record?.imgDownloadUrl)}><SvgPngOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button>
          </Space>
        )
      }
    ]

    const filterColumn = [
      {
        key: 'invoiceNo',
        name: 'Invoice No',
        type: FORM_ITEM.INPUT
      },
      {
        key: 'shipperName',
        name: 'Shipper Name',
        type: FORM_ITEM.INPUT
      },
    ]

    return (
      <Row gutter={[16, 16]}>
        <Col span={12}>
          <Flex align='center' gap={10}>
            <TitleLevel3 label={"Shipper Invoice"} />
          </Flex>
        </Col>
        <Col span={24}>
          <NJVTable
            apiUrl={Api.shipper_invoice}
            columns={shipperColumns}
            filters={filterColumn}
            />
        </Col>
      </Row>
    )
  }
}

export default compose(withRouter)(InvoiceList)