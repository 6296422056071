import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Button, Col, Modal, Row, Space, Tag } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { NJVAddButton } from '../../../components/core-component';
import { TitleLevel3 } from '../../../components/general-component';
import NJVTable from '../../../components/njv-table';
import { FORM_ITEM } from "../../../core/constant";
import Api from '../../../network/api';
import { HTTP_METHOD } from "../../../network/httpMethod";
import { ApiHandler } from "../../../network/network-manager";
import ShipperDirectoryProductForm from '../shipper-directory/shipper-directory-product-form';
import ShipperDirectoryProductUpdate from "./shipper-directory-product-update";

const filterColumn = [
    {
        key: 'name',
        name: 'Search by name',
        type: FORM_ITEM.INPUT,
    }
]

const ShipperDirectoryProductPage = ({ open = false, data = null }) => {

    const [openProductModal, setOpenProductModel] = useState(false)
    const [currentStore, setCurrentStore] = useState(null)
    const [currentProduct, setCurrentProduct] = useState(null)
    const [openProductUpdateModel, setOpenProductUpdateModel] = useState(false)
    const [deleteItem, setDeleteItem] = useState(null)
    const [showDeleteModel, setShowDeleteModel] = useState(false)
    const tableRef = useRef()

    useEffect(() => {
        setCurrentStore(data)
    }, [data])

    const productFormFinish = () => {
        setOpenProductModel(false)
        tableRef?.current?.refreshData();
    }

    const deleteProduct = () => {
        ApiHandler({ url: Api.userstore_product, method: HTTP_METHOD.DELETE, specificId: deleteItem })
            .then((response) => {
                tableRef?.current?.refreshData();
                setDeleteItem(null)
                setShowDeleteModel(false)
            })
            .catch((error) => { })
    }
    const columns = [
        {
            title: 'Product Name',
            key: 'productName',
            dataIndex: 'productName'
        },
        {
            title: 'Product Description',
            key: 'productDescription',
            dataIndex: 'productDescription'
        },
        {
            title: 'Status',
            key: 'active',
            render: (_, { statusFlag }) => (
                <>
                    {
                        (
                            statusFlag === "ACTIVE" ? <Tag color="#87d068">{statusFlag}</Tag> : <Tag color="#cd201f">{statusFlag}</Tag>
                        )
                    }
                </>
            ),

        }, {
            title: 'Action',
            key: 'action',
            dataIndex: '',
            textAlign: 'center',
            width: 60,
            render: (product) => (
                <Space>
                    <Button size={'middle'} type="primary" shape="circle" style={{ backgroundColor: 'red' }} icon={<DeleteOutlined />}
                        onClick={() => {
                            setDeleteItem(product.id)
                            setShowDeleteModel(true)
                        }} />
                    <Button size={'middle'} type="primary" shape="circle" icon={<EditOutlined />}
                        onClick={() => {
                            setCurrentProduct(product)
                            setOpenProductUpdateModel(true)
                        }} />
                </Space>
            ),
        },
    ]

    return (
        <>
            <Modal
                title="Delete"
                open={showDeleteModel}
                onOk={() => deleteProduct()}
                onCancel={() => {
                    setDeleteItem(null)
                    setShowDeleteModel(false)
                }}
                okText="Confirm"
                cancelText="Cancel"
            >
                <p>Are you sure to delete?</p>
            </Modal>
            <ShipperDirectoryProductForm
                data={currentStore}
                open={openProductModal}
                onFinish={productFormFinish} />

            <ShipperDirectoryProductUpdate
                data={currentProduct}
                open={openProductUpdateModel}
                onFinish={productFormFinish} />

            <Row gutter={[16, 16]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Shipper Directory Product"} />
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <NJVAddButton handleClick={() => setOpenProductModel(true)} />
                </Col>
                <Col span={24}>
                    {
                        currentStore?.id && <NJVTable
                            ref={tableRef}
                            columns={columns}
                            apiUrl={Api.userstore_product_filter + "/" + currentStore?.id}
                            filters={filterColumn}
                            noUrlPagination={true}
                        />
                    }


                </Col>
            </Row>
        </>
    )
}

export default ShipperDirectoryProductPage