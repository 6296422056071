import { message } from 'antd';
import axios from 'axios';
import { LOC_clear_token, LOC_getAccessToken, LOC_storeToken } from '../core/custom-local-storage';
import { RefreshToken } from '../page/auth/auth-provider';
import CustomPath from '../routes/custom-path';
import Api from './api';
import { HTTP_METHOD } from './httpMethod';
import { MEDIA_TYPE } from './mediaType';

const controllers = new Map();

const axiosInstance = axios.create({
    baseURL: Api.host
});

axiosInstance.interceptors.request.use(
    async config => {
        const token = LOC_getAccessToken()
        config.headers = {
            'Authorization': `Bearer ${token}`,
        }
        const controller = new AbortController();
        config.signal = controller.signal;
        controllers.set(config.url, controller);
        return config;
    },
    error => {
        Promise.reject(error)
    });

axiosInstance.interceptors.response.use(
    (response) => {
        controllers.delete(response.config.url);
        return response;
    },
    async (error) => {
        if (error.response.status === 401) {
            try {
                const responseData = await RefreshToken();
                LOC_storeToken(responseData)
                axiosInstance.defaults.headers.common['Authorization'] = `Bearer ${responseData.access_token}`;
                return axiosInstance(error.config);
            } catch (refreshError) {
                LOC_clear_token()
                message.error("Session expired! Please login again");
                window.location.href = '/'
            }
        } else if (error.response.status === 500) {
            if (error.response.data && error.response.data.message) {
                message.destroy()
                message.error(error.response.data.message)
            } else {
                message.destroy()
                message.error("Something Wrong!")
            }
        }
        controllers.delete(error.config?.url);
        return Promise.reject(error);
    }
);

export const cancelRequest = (url) => {
    const controller = controllers.get(url);
    if (controller) controller.abort();
};

export const ApiHandler = async ({ url, method = HTTP_METHOD.GET, mediaType = MEDIA_TYPE.JSON, requestData, specificId, requestParams, customMessage, disableShowMessage }) => {
    try {
        let responseData = null
        let response = null
        const finalUrl = specificId ? `${url}/${specificId}` : url
        const header = {
            headers: {
                'Content-Type': mediaType,
            }
        }
        switch (method) {
            case HTTP_METHOD.GET:
                response = await axiosInstance.get(finalUrl, { params: requestParams });
                responseData = response.data;
                break;
            case HTTP_METHOD.POST:
                responseData = await axiosInstance.post(finalUrl, requestData, { params: requestParams }, header);
                break;
            case HTTP_METHOD.PUT:
                responseData = await axiosInstance.put(finalUrl, requestData, { params: requestParams }, header);
                break;
            case HTTP_METHOD.DELETE:
                responseData = await axiosInstance.delete(finalUrl);
                break;
            default:
                throw new Error(`Unsupported HTTP method: ${method}`);
        }

        message.destroy();

        if (!disableShowMessage) {
            if (customMessage) {
                message.success(customMessage);
            } else {
                switch (method) {
                    case HTTP_METHOD.POST:
                        message.success("Successfully Created");
                        break;
                    case HTTP_METHOD.PUT:
                        message.success("Successfully Updated");
                        break;
                    case HTTP_METHOD.DELETE:
                        message.success("Successfully Deleted");
                        break;
                    default:
                        break;
                }
            }
        }
        return responseData;
    } catch (error) {
        throw error;
    }
}

export const ApiGet = async (url, id, props) => {
    try {
        let compileUrl = url
        if (id) {
            compileUrl = compileUrl + `/${id}`
        }

        const response = await axiosInstance.get(compileUrl);
        return response.data;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
};

export const ApiFilter = async (url, data, props) => {
    try {
        let response;
        if (data) {
            response = await axiosInstance.get(url, { params: data })
        } else {
            response = await axiosInstance.get(url)
        }
        return response.data;

    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
}

export const ApiFilterByBody = async (url, params, body, props) => {
    try {
        // const response = await axiosInstance.post(url, body, { headers: { 'Authorization': `Bearer ${props.accessToken}` } });
        const response = await axiosInstance.post(url, body);
        return response.data;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
}

export const ApiFilterById = async (url, id, params, props) => {
    try {
        const compileUrl = url + `/${id}`
        if (params) {
            const response = await axiosInstance.get(compileUrl, { params });
            return response.data;
        } else {
            const response = await axiosInstance.get(compileUrl);
            return response.data;
        }

    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
}

export const ApiPost = async (url, data, props) => {
    try {
        const response = await axiosInstance.post(url, data);
        message.destroy()
        message.success("Create Successful")
        return response;
    } catch (error) {
        throw error
        // errorHandler(error, props)
        // return error.response.data;
    }
};

export const ApiPostWithParam = async (url, requestParams, data, props) => {
    try {
        const response = await axiosInstance.post(url, data, { params: requestParams });
        message.destroy()
        return response;
    } catch (error) {
        errorHandler(error, props)
        return error.response.data;
    }
}


export const ApiPut = async (url, id, data, props) => {
    try {
        if (id) {
            url = url + `/${id}`
        }
        const response = await axiosInstance.put(url, data);
        message.destroy()
        message.success("Update Successful")
        return response;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
};

export const ApiPutBatch = async (url, data, props) => {
    try {
        const response = await axiosInstance.put(url, data);
        message.destroy()
        message.success("Update Successful")
        return response;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
};

export const ApiDelete = async (url, id, props) => {
    try {
        url = url + `/${id}`
        const response = await axiosInstance.delete(url);
        message.destroy()
        message.success("Delete Successful")
        return response;
    } catch (error) {
        errorHandler(error, props)
        throw error;
    }
}

export const ApiPostWithFormData = async (url, data, props) => {
    try {
        const response = await axiosInstance.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        message.destroy()
        message.success("Create Successful")
        return response;
    } catch (error) {
        throw error
    }
};

export const ApiPostWithFormDataOrderCreate = async (url, data) => {
    try {
        const response = await axiosInstance.post(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        return response;
    } catch (error) {
        throw error
    }
};

export const ApiPutWithFormData = async (url, data, props) => {
    try {
        const response = await axiosInstance.put(url, data, {
            headers: {
                'Content-Type': 'multipart/form-data',
            }
        });
        message.destroy()
        message.success("Create Successful")
        return response;
    } catch (error) {
        throw error
    }
};

export const PushNotificatonByTopic = async (id, props) => {
    try {
        const compileUrl = Api.push_notification_topic + `/${id}`
        const response = await axiosInstance.get(compileUrl);
        return response.data;
    } catch (error) {
        throw error
    }
}


const errorHandler = (error, props) => {
    if (error && error.response && error.response.status === 401) {
        props.navigate(CustomPath.login)
    }
}

export const downloadExcel = async (url, params, excelName) => {
    let responseData = null
    let name = excelName ? excelName + ".xlsx" : 'order_excel_template_format.xlsx'
    try {
        await axiosInstance.get(url,
            {
                params: params,
                responseType: 'blob',
            }).then(response => {
                const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const link = document.createElement('a');
                link.href = window.URL.createObjectURL(blob);
                link.download = name
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                responseData = response
            }).catch(error => {
                responseData = error
            });
    } catch (error) {
    }
    return responseData
}

export const urlChecker = async (url) => {
    try {
        await axios.get(url);
        return true
    } catch (error) {
        return false
    }
}

// export const DownloadFileWithUrl = async (fileUrl, fileName) => {
//     await axiosInstance.get(Api.file_download,
//         {
//             params: { fileName: fileUrl },
//             responseType: 'blob',
//         }).then(response => {
//             const fileType = fileUrl.split('.').pop().toLowerCase();
//             const blob = new Blob([response.data], {
//                 type: getMimeType(fileType),
//             });
//             const link = document.createElement('a');
//             link.href = URL.createObjectURL(blob);
//             link.download = fileName;
//             document.body.appendChild(link);
//             link.click();
//             link.remove();
//             URL.revokeObjectURL(link.href);
//         }).catch(error => {
//             message.error("Failed to download file")
//         });

//     return;
// };

export const DownloadFileWithUrl = async (fileUrl, fileName) => {
    const fileType = fileUrl.split('.').pop().toLowerCase();

    if (fileType === 'jpg' || fileType === 'png' || fileType === 'jpeg') {
        await axiosInstance.get(Api.file_download,
            {
                params: { fileName: fileUrl },
                responseType: 'blob',
            }).then(response => {                
                const blob = new Blob([response.data], {
                    type: getMimeType(fileType),
                });

                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);

                const extension = fileType.startsWith('.') ? fileType : `.${fileType}`;
                const correctFileName = fileName.endsWith(extension) ? fileName : `${fileName}${extension}`;

                link.download = correctFileName;
                document.body.appendChild(link);
                link.click();
                link.remove();
                URL.revokeObjectURL(link.href);
            }).catch(error => {
            });
    } else {
        let presignedUrl = null
        await axiosInstance.get(Api.file_generate_presign_url, {
            params: { fileName: fileUrl },
        }).then(response => {
            presignedUrl = response?.data
        })
            .catch(error => { })
        if (presignedUrl) {
            if (urlChecker(presignedUrl)) {
                if (fileType === 'pdf') {
                    const response = await fetch(presignedUrl, { method: "GET", redirect: "follow" });

                    if (!response.ok) throw new Error(`HTTP error! Status: ${response.status}`);

                    const blob = await response.blob();
                    const url = window.URL.createObjectURL(blob);

                    const link = document.createElement("a");
                    link.href = url;
                    const extension = fileType.startsWith('.') ? fileType : `.${fileType}`;
                    const correctFileName = fileName.endsWith(extension) ? fileName : `${fileName}${extension}`;
                    link.download = correctFileName;
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                } else {
                    window.location.href = presignedUrl;
                }
            } else {
                message.error("File to download")
            }
        } else {
            message.error("File to download")
        }
    }
};

export const fileDownload = async (fileUrl, fileName) => {
    let blob;
    await axiosInstance.get(Api.file_download,
        {
            params: { fileName: fileUrl },
            responseType: 'blob',
        }).then(response => {
            const fileType = fileUrl.split('.').pop().toLowerCase();

            blob = new Blob([response.data], {
                type: getMimeType(fileType),
            });
        }).catch(error => {
            message.error("Failed to download file")
        });

    return blob;
};

export const uploadAndDownload = async (url, requestData, excelName) => {
    let responseData = null
    let name = excelName ? excelName + ".xlsx" : 'downloaded-excel.xlsx';
    try {
        const header = {
            headers: {
                'Content-Type': 'multipart/form-data',
            },
            responseType: 'blob'
        }
        await axiosInstance.post(url, requestData, header).then(response => {
            const blob = new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            link.download = name;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link)
            responseData = response
        }).catch(error => {
            console.log(error)
            responseData = error
        })
    } catch (error) {
        throw error
    }
    return responseData
}

// export const InvoiceDownloadByFormat = async (url, requestData, param, merchantName, date, method) => {

//     try {
//         let responseData = null
//         let requestUrl = Api.invoice_host + "/" + url
//         let contentType = 'application/pdf'

//         if (param.format === 'pdf') {
//             contentType = 'application/pdf'
//         } else if (param.format === 'img') {
//             contentType = 'image / png'
//         } else if (param.format === 'excel') {
//             contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
//         }
//         await axios({
//             method: method,
//             url: requestUrl,
//             params: param,
//             data: requestData,
//             responseType: 'blob',
//         }).then(response => {
//             let fileName = merchantName + "_Invoice_" + date
//             const blob = new Blob([response.data], { type: contentType });
//             if (blob.type === 'application/pdf') {
//                 fileName = fileName + ".pdf"
//             } else if (blob.type === 'image / png') {
//                 fileName = fileName + ".png"
//             } else if (blob.type === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
//                 fileName = fileName + ".xlsx"
//             }
//             const link = document.createElement('a');
//             link.href = window.URL.createObjectURL(blob);
//             link.download = fileName;
//             document.body.appendChild(link);
//             link.click();
//             document.body.removeChild(link);
//             responseData = response
//         }).catch(error => {
//             message.destroy();
//             message.error("Fail to download")
//             responseData = error
//         });
//         return responseData
//     } catch (error) {
//         throw error;
//     }

// }

export const FileUpload = async ({ file = null, filePath = null }) => {
    let uploadedUrl = null;
    let formData = new FormData()
    formData.append('file', file);
    formData.append('filePath', filePath);
    const header = {
        headers: {
            'Content-Type': 'multipart/form-data',
        },
    }
    await axiosInstance.post(Api.file_upload, formData, header)
        .then(response => { uploadedUrl = response.data })
        .catch(error => { message.error("Upload failed") })
        .finally(() => { })
    return uploadedUrl
}


const getMimeType = (fileType) => {
    switch (fileType) {
        case 'pdf':
            return 'application/pdf';
        case 'docx':
            return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
        case 'doc':
            return 'application/msword';
        case 'xlsx':
        case 'excel':
            return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        case 'xls':
            return 'application/vnd.ms-excel';
        case 'png':
        case 'jpg':
        case 'jpeg':
            return 'image/png';
        case 'zip':
            return 'application/zip';
        default:
            return 'application/octet-stream'; // Generic binary stream
    }
};
