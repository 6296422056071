import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Image, Input, Modal, Row, Select, Skeleton, Upload, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVBackButton } from "../../../components/core-component";
import Theme, { Colors } from "../../../components/theme";
import { AdminPasswordValidator, CharacterLimit, RestrictPhoneNumberValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { MEDIA_TYPE } from "../../../network/mediaType";
import { ApiFilter, ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";
const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const { TextArea } = Input
const EMPLOYMENT_TYPE = [
    {
        value: 'Permanent',
        label: 'Permanent'
    },
    {
        value: 'Contract',
        label: 'Contract'
    }
]
const RANK_TYPE = [
    {
        value: 1,
        label: 1
    },
    {
        value: 2,
        label: 2
    },
    {
        value: 3,
        label: 3
    },
    {
        value: 4,
        label: 4
    },
    {
        value: 5,
        label: 5
    },
    {
        value: 6,
        label: 6
    },
    {
        value: 7,
        label: 7
    },
    {
        value: 8,
        label: 8,
    },
    {
        value: 9,
        label: 9
    },
    {
        value: 10,
        label: 10
    }

]
class EmployeeUpdate extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: {},
            previewImage: null,
            previewTitle: null,
            roleData: [],
            roleOptions: [],
            currentDate: '',
            profileFileList: [],
            coverFileList: [],
            singleFile: {},
            isLoading: false,
            previewOpen: false,
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
            showBranchCodeInputBox: false,
            employeeAccount: null,
            shopTypes: [],
            department_collection: [],
            team_collection: [],

        };
        this.inputRef = React.createRef()
    }

    componentDidMount = async () => {
        await Promise.all([
            this.fetchAllDivision(),
            this.fetchData(),
            this.fetchDepartmentData()
        ])
    }

    fetchDepartmentData = async () => {
        await ApiHandler({ url: Api.department_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then(response => {
                let temp = []
                response.forEach((res) => {
                    temp.push({
                        key: res.id,
                        value: res.id,
                        label: res.name,
                    });
                });

                this.setState({
                    department_collection: temp,
                });
            }).catch(error => {
            }).finally(() => {
            })
    }

    fetchTeamData = async (id) => {

        await ApiHandler({ url: Api.team_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: id })
            .then(response => {
                let temp = []
                response.forEach((res) => {
                    temp.push({
                        key: res.id,
                        value: res.id,
                        label: res.name,
                    });
                });

                this.setState({
                    team_collection: temp,
                });
            })
            .catch(error => { })
            .finally(() => { })

    }


    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current?.setFieldsValue({ townshipId: null });
    }

    fetchData = async () => {
        this.setState({
            isFetching: true
        })
        await ApiHandler({ url: Api.employee, method: HTTP_METHOD.GET, specificId: this.props.params.id })
            .then(response => {
                let employeeAccount = null
                if (response?.dateOfBirth) {
                    const dob = dayjs(response.dateOfBirth, 'YYYY-MM-DD');
                    response['dateOfBirth'] = dob
                }
                if (response?.joinedDate) {
                    const joinedDate = dayjs(response.joinedDate, 'YYYY-MM-DD');
                    response['joinedDate'] = joinedDate
                }
                if (response?.userStatus === 'ACTIVE') {
                    response['active'] = true
                } else {
                    response['active'] = false
                }
                if (response?.team) {
                    response['department'] = response?.team?.department?.id
                    response['team'] = response?.team?.id
                    this.fetchTeamData(response?.department)
                }
                if (response.accounts) {
                    response['loginId'] = response.accounts[0]?.loginId
                    response['password'] = response.accounts[0]?.password
                    response['confirmPassword'] = response.accounts[0]?.password
                    employeeAccount = response.accounts[0]
                }
                if (response.township) {
                    response['divisionId'] = response.township.division ? response.township.division.id : null
                    response['townshipId'] = response.township ? response.township.id : null
                }

                this.setState({
                    employeeAccount,
                    data: response,
                    isFetching: false
                }, () => {
                    this.inputRef.current?.setFieldsValue({ ...response, divisionId: null, townshipId: null });
                    const currentDivision = this.inputRef.current.getFieldValue('divisionId');
                    const currentTownship = this.inputRef.current.getFieldValue('townshipId');

                    if (!currentDivision && !currentTownship) {
                        this.fetchAllDivision(response.divisionId, response.townshipId)
                    }
                })
            })
            .catch(() => { })
    }


    fetchAllDivision = async (currentDivisionId, currentTownshipId) => {
        try {
            this.setState({
                isFetchingDivision: true
            })
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionList = []
                let townshipList = []
                let townshipMap = {}
                response.forEach(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                townshipList = townshipMap[currentDivisionId];
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap,
                    township_collection: townshipList,
                    isFetchingDivision: false
                }, () => {
                    this.inputRef.current?.setFieldsValue({ divisionId: currentDivisionId, townshipId: currentTownshipId })
                })
            }
        } catch (error) {
        }
    }

    updateEmployee = async (values) => {
        const { employeeAccount, data } = this.state
        this.setState({
            isLoading: true
        })
        if (values.password !== values.confirmPassword) {
            message.error("Confirm password does not match")
            return
        }
        let formData = new FormData()
        formData.append('id', data.id);
        formData.append('firstName', values.firstName);
        formData.append('lastName', values.lastName);
        formData.append('fullName', values.firstName + " " + values.lastName);
        formData.append("phoneNumber", values.phoneNumber)
        formData.append("emailAddress", values.emailAddress)
        formData.append("address1", values.address1)
        formData.append("township.id", values.townshipId)
        formData.append('approvalStatus', 'APPROVED');
        formData.append('userStatus', values.active ? "ACTIVE" : "INACTIVE");
        formData.append("gender", values.gender)
        formData.append("dateOfBirth", values.dateOfBirth.format("YYYY-MM-DD"))

        formData.append("employeeId", values.employeeId)
        formData.append("position", values.position)
        formData.append("workLocation", values.workLocation)
        formData.append("employmentType", values.employmentType)
        formData.append("rank", values.rank)
        formData.append("joinedDate", values.joinedDate.format("YYYY-MM-DD"))
        formData.append("note", values.note || '')
        formData.append("team.id", values.team)
        formData.append('accounts[0].id', employeeAccount?.id);
        formData.append('accounts[0].loginId', values.phoneNumber);
        formData.append('accounts[0].password', values.password ? values.password : '');
        formData.append('accounts[0].accountType', "PHONE");


        if (values?.profileUrl?.file) {
            formData.append('profile', values.profileUrl?.file)
        }
        if (data?.profileUrl === null) {
            formData.append('deleteProfileUrl', true)
        } else {
            formData.append('profileUrl', data.profileUrl)
        }


        await ApiHandler({ url: Api.employee, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, specificId: data.id })
            .then(() => { this.props.navigate(CustomPath.employee) })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleChange = (type, uploadObject) => {
        if (this.checkValidFileSize(uploadObject.file)) {
            if (type === 'cover') {
                this.setState({
                    coverFileList: uploadObject.fileList
                })
            } else {
                this.setState({
                    profileFileList: uploadObject.fileList
                })
            }
        }
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    };
    checkValidFileSize = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.destroy()
            message.error('Image must smaller than 5MB!');
        }
        return isLt2M;
    };

    onFinishFailed = () => {
        message.destroy()
        message.error('Please fill required fields');
    }

    render() {
        const { data, isLoading, profileFileList, department_collection, team_collection, previewOpen, previewImage, previewTitle, division_collection, township_collection, isFetching } = this.state

        return (
            <>
                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                {
                    isFetching ? <Skeleton /> : (
                        <Row gutter={[16, 16]}>
                            <Col span={24}>
                                <Breadcrumb
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: <Link to={CustomPath.employee}>Employee</Link>,
                                        }
                                    ]}
                                />
                            </Col>
                            <Col span={24}>
                                <Card
                                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                    bordered={false}
                                    title={"Employee Update Form"}
                                    extra={<Link to={CustomPath.employee}><NJVBackButton /></Link>}
                                >
                                    <Form
                                        ref={this.inputRef}
                                        layout="vertical"
                                        initialValues={{ active: true }}
                                        onFinish={this.updateEmployee}
                                        onFinishFailed={this.onFinishFailed}
                                    >
                                        <Row gutter={[16, 16]}>
                                            <Col span={24}>

                                                {
                                                    data?.profileUrl ?
                                                        <>
                                                            <Form.Item name="profileUrl">
                                                                <Image width={100} style={{ border: '1px dashed gray', borderRadius: 100 }} src={data?.profileUrl} />
                                                            </Form.Item>
                                                            <div style={{ width: 100, textAlign: 'center' }}>
                                                                <Button type="primary" style={{ backgroundColor: Colors.red }} onClick={() => this.setState(prevState => ({
                                                                    data: {
                                                                        ...prevState.data,
                                                                        profileUrl: null
                                                                    }
                                                                }))}>Remove</Button>
                                                            </div>

                                                        </>
                                                        :

                                                        <Form.Item name="profileUrl" >
                                                            <Upload
                                                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                                                listType="picture-circle"
                                                                fileList={profileFileList}
                                                                multiple={false}
                                                                maxCount={1}
                                                                action={null}
                                                                onPreview={this.handlePreview}
                                                                onChange={(obj) => this.handleChange('profile', obj)}
                                                                beforeUpload={() => false}
                                                                style={{ width: 200 }}
                                                            >
                                                                {profileFileList.length >= 1 ? null :
                                                                    <div style={{ width: 200 }}>
                                                                        <PlusOutlined />
                                                                        <div
                                                                            style={{
                                                                                marginTop: 8,
                                                                            }}
                                                                        >
                                                                            Upload
                                                                        </div>
                                                                    </div>}
                                                            </Upload>
                                                        </Form.Item>
                                                }
                                            </Col>

                                            <Col span={12} >
                                                <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Input placeholder={"Enter first name"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} >
                                                <Form.Item name="lastName" label={"Last Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Input placeholder={"Enter last name"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} >
                                                <Form.Item name="phoneNumber" label={"Phone Number"} rules={[{ required: true, message: '*(requried)' }, {
                                                    validator: RestrictPhoneNumberValidator
                                                }]}>
                                                    <Input placeholder={"Enter phone number"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} >
                                                <Form.Item name="emailAddress" label={"Email Address"}
                                                    rules={[
                                                        {
                                                            type: 'email',
                                                            message: 'Invalid email address',
                                                        }]}
                                                >
                                                    <Input placeholder={"Enter email address"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="password" label={"Password"}
                                                    rules={[{ validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                                    <Input.Password size="small" placeholder="Enter password" autocomplete="new-password" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="confirmPassword" label={"Confirm Password"}
                                                    rules={[{ validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                                    <Input.Password size="small" placeholder="Enter confirm password" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} style={{ paddingLeft: 5 }}>
                                                <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                                    <DatePicker
                                                        allowClear={false}
                                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} style={{ paddingRight: 5 }}>
                                                <Form.Item name="gender" label={"Gender"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select
                                                        size="large"
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                        placeholder="Select gender"
                                                        options={[
                                                            {
                                                                label: 'Male',
                                                                value: 'MALE',
                                                            },
                                                            {
                                                                label: 'Female',
                                                                value: 'FEMALE',
                                                            },
                                                        ]}
                                                    />
                                                </Form.Item>

                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select
                                                        className="my-location-selector"
                                                        placeholder={"Select Division"}
                                                        onChange={this.handleDivisionSelect}
                                                        size="large"
                                                        options={
                                                            division_collection && division_collection.map((division) => {
                                                                return {
                                                                    value: division.id,
                                                                    label: division.name,
                                                                    type: 'division'
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>

                                            <Col span={12}>
                                                <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select
                                                        className="my-location-selector"
                                                        placeholder={"Select township"}
                                                        onChange={this.handleTownshipSelect}
                                                        size="large"
                                                        options={
                                                            township_collection && township_collection.map((township) => {
                                                                return {
                                                                    value: township.id,
                                                                    label: township.name,
                                                                    type: 'division'
                                                                }
                                                            })
                                                        }
                                                    />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="address1" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <TextArea
                                                        style={{ height: 60 }} placeholder={"Enter address"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} />
                                            <Col span={12} >
                                                <Form.Item name="employeeId" label={"Employee ID"} rules={[{ required: true, message: '*(requried)' }, {
                                                    validator: CharacterLimit
                                                }]}>
                                                    <Input placeholder={"Enter employee ID"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} >
                                                <Form.Item name="position" label={"Position"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Input placeholder={"Enter position"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="rank" label={"Rank"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select options={RANK_TYPE} size="large" style={{ width: '100%' }} placeholder="Select rank" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="employmentType" label={"Employment Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select options={EMPLOYMENT_TYPE} size="large" style={{ width: '100%' }} placeholder="Select employment type" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="department" label={"Department"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select onChange={(event) => this.fetchTeamData(event)} options={department_collection} size="large" style={{ width: '100%' }} placeholder="Select department" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="team" label={"Team"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Select options={team_collection} size="large" style={{ width: '100%' }} placeholder="Select team" />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12}>
                                                <Form.Item name="workLocation" label={"Work Location"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <Input placeholder={"Enter work location"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={12} style={{ paddingLeft: 5 }}>
                                                <Form.Item name="joinedDate" label={"Joined Date"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                                    <DatePicker
                                                        allowClear={false}
                                                        placeholder="Joined Date"
                                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item name="note" label={"Note"} rules={[{ required: true, message: '*(requried)' }]}>
                                                    <TextArea
                                                        style={{ height: 60 }} placeholder={"Enter note"} />
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item
                                                    name="active"
                                                    valuePropName="checked">
                                                    <Checkbox>Active</Checkbox>
                                                </Form.Item>
                                            </Col>
                                            <Col span={24}>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" loading={isLoading}>Update</Button>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                                </Card>
                            </Col>
                        </Row>
                    )
                }
            </>
        );
    }
}

export default compose(withRouter)(EmployeeUpdate)