import { compose } from '@reduxjs/toolkit'
import { Breadcrumb, Button, Card, Col, Form, Input, Row, TimePicker } from 'antd'
import dayjs from 'dayjs'
import React, { Component } from 'react'
import { Link } from 'react-router-dom'
import { NJVBackButton } from '../../components/core-component'
import DynamicEnumSelect from '../../components/fetch-enum'
import Theme from '../../components/theme'
import Api from '../../network/api'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'

class BannerCreate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: false,
            bannerType: null
        }
        this.formRef = React.createRef();
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = () => {
        this.setState({
            isDataFetching: true
        })
        ApiHandler({ url: Api.banner_list, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then((response) => {
                this.setState({
                    data: response
                })
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isDataFetching: false
                })
            })
    }

    createBanner = async (values) => {
        this.setState({
            isLoading: true
        })
        const requestData = {
            'bannerName': values.name,
            'type': values.type,
            'startTime': dayjs(values.time[0]).format('HH:mm'),
            'endTime': dayjs(values.time[1]).format('HH:mm'),
            'status': values.status === true ? 'ACTIVE' : 'INACTIVE'
        }
        await ApiHandler({ url: Api.banner, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData })
            .then(() => {
                this.props.navigate(CustomPath.banner)
            }).catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    render() {
        const { data, isDataFetching } = this.state;
        // const renderTimeInterval = (data, bannerType) => {
        //     return data.filter(item => item.type === bannerType).map((item, index) => (
        //         <span key={index} style={{
        //             display: 'inline-flex',
        //             alignItems: 'center',
        //             border: '1px solid #ff0000',
        //             width: 'fit-content',
        //             padding: '10px 20px',
        //             borderRadius: 10,
        //             marginRight: 10,
        //             marginBottom: 5
        //         }}>
        //             <span>{`${item?.startTime.slice(':', 5)} to ${item?.endTime.slice(':', 5)}`}</span>
        //         </span>
        //     ))
        // }

        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.banner}>Loyalty Banner</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                </Row>

                <Row style={{ marginBottom: "20px" }}>
                    <Col span={1} />
                    <Col span={22}>
                        {/* {
                            isDataFetching ? <Skeleton active /> :
                                <Flex vertical wrap="wrap" gap={15} style={{ marginBottom: 20 }}>
                                    {
                                        Object.keys(bannerTypeMap).map((bannerType) => {
                                            const hasInterval = data?.some(item => item.type === bannerType)
                                            return hasInterval ? (
                                                <React.Fragment key={bannerType}>
                                                    <Typography.Title level={5} style={{ margin: 0 }}>
                                                        Unavailable <span style={{ color: Colors.primary }}>{bannerTypeMap[bannerType]}</span> Time Interval
                                                    </Typography.Title>
                                                    <Flex wrap="wrap">
                                                        {
                                                            renderTimeInterval(data, bannerType)
                                                        }
                                                    </Flex>
                                                </React.Fragment>
                                            ) : null
                                        })
                                    }
                                </Flex>
                        } */}
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"Loyalty Banner Form"}
                            extra={<Link to={CustomPath.banner}><NJVBackButton /></Link>}
                        >
                            <Form
                                ref={this.formRef}
                                layout="vertical"
                                initialValues={{ status: true }}
                                onFinish={this.createBanner}>
                                <Row gutter={[24, 24]}>
                                    <Col span={8}>
                                        <Form.Item name="name" label={"Banner Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <Input placeholder={"Enter Banner name"} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="type" label={"Banner Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <DynamicEnumSelect enumtype='BANNER_TYPE' size='large' placeholder={"Select Type"} width="100%" 
                                            onChange={(value) => {
                                                this.setState({ bannerType: value })
                                                this.formRef.current?.setFieldsValue({ 'time': null })
                                            }} />
                                        </Form.Item>
                                    </Col>
                                    <Col span={8}>
                                        <Form.Item name="time" label={"Start Time / End Time"} rules={[{ required: true, message: '*(requried)' }]}>
                                            <TimePicker.RangePicker
                                                format="HH:mm"
                                                size="large"
                                                style={{ width: '100%' }}
                                                allowClear
                                                disabledTime={() => {
                                                    let disabledHours = new Set();
                                                    let disabledMinutes = {};

                                                    data?.forEach(item => {
                                                        if (item.type === this.state.bannerType) {
                                                            const [startHour, startMinute] = item.startTime.split(":").map(Number);
                                                            const [endHour, endMinute] = item.endTime.split(":").map(Number);

                                                            for (let i = startHour; i <= endHour; i++) {
                                                                disabledHours.add(i);
                                                                disabledMinutes[i] = Array.from({ length: 60 }, (_, m) => m); // Disable all minutes
                                                            }

                                                            // Disable specific minutes in startHour
                                                            disabledMinutes[startHour] = Array.from({ length: 60 - startMinute }, (_, i) => startMinute + i);

                                                            // Disable specific minutes in endHour (except full 23:59)
                                                            if (endMinute !== 59) {
                                                                disabledMinutes[endHour] = Array.from({ length: endMinute + 1 }, (_, i) => i);
                                                            }
                                                        }
                                                    });

                                                    return {
                                                        disabledHours: () => Array.from(disabledHours),
                                                        disabledMinutes: (selectedHour) => disabledMinutes[selectedHour] || []
                                                    };
                                                }}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>

                                <Form.Item style={{ textAlign: 'end' }}>
                                    <Button type="primary" htmlType="submit" loading={this.state.isLoading}>Create</Button>
                                </Form.Item>
                            </Form>
                        </Card>
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(BannerCreate);