import { InboxOutlined, UploadOutlined } from "@ant-design/icons";
import { compose } from '@reduxjs/toolkit';
import { Button, Col, Divider, Flex, Form, Image, Input, Modal, Row, Spin, Tag, Upload, message } from 'antd';
import dayjs from 'dayjs';
import React, { Component } from 'react';
import { TitleLevel3 } from '../../components/general-component';
import NJVTable from "../../components/njv-table";
import Theme, { Colors } from "../../components/theme";
import { FORM_ITEM } from '../../core/constant';
import Api from '../../network/api';
import { HTTP_METHOD } from '../../network/httpMethod';
import { MEDIA_TYPE } from '../../network/mediaType';
import { ApiHandler, uploadAndDownload } from '../../network/network-manager';
import withRouter from '../../network/with-router';
const { Dragger } = Upload;

const paymentTypeMap = {
    'KBZ_PAY': 'KBZ Pay',
    'AYA_PAY': 'AYA Pay',
    'WAVE_PAY': 'Wave Pay',
}

const STATUS_COLLECTION = [
    {
        key: 'Pending',
        value: 'PENDING'
    },
    {
        key: 'Approved',
        value: 'APPROVED'
    },
    {
        key: 'Reject',
        value: 'REJECT'
    },
    {
        key: 'Cancel',
        value: 'CANCEL'
    },
    {
        key: 'Ready',
        value: 'READY'
    }
]

class Settlement extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openUploadExcelModal: false,
            openOrderDetailModal: false,
            openRejectModal: false,
            openApproveModal: false,
            isDataFetching: false,
            isUploading: false,
            isRejecting: false,
            isApproving: false
        }
        this.formRef = React.createRef();
    }

    handleFileUpload = async (file) => {
        try {
            this.setState({
                isUploading: true
            })
            let formData = new FormData()
            formData.append('file', file);
            await uploadAndDownload(Api.shipper_owed_excel, formData, "owed-shipper-list")

            this.reFetch()
        } catch (error) {
            console.log(error)
            if (error && error.response.status === 500) {
                message.destroy()
                message.error("Something went wrong")
            }
        }
        this.setState({
            isUploading: false,
            openUploadExcelModal: false
        })
    };

    closeModal = () => {
        if (this.state.isUploading) {
            message.error("You can not close at this time")
        } else {
            this.setState({
                openUploadExcelModal: false
            })
        }
    }

    handleApprove = async () => {
        const { owedShipperId } = this.state;

        try {
            this.setState({
                isApproving: true
            })
            const response = await ApiHandler({ url: Api.shipper_owed_approve, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, specificId: owedShipperId, customMessage: "This Settlement has been approved" })
            if (response) {
                this.setState({
                    isApproving: false,
                    openApproveModal: false
                })
            }
            this.reFetch()
        } catch (error) {
            console.log(error);
        }
    }

    handleRejectReason = (value) => {
        this.handleReject(value.reason)
    }

    handleReject = async (rejectReason) => {
        try {
            const { owedShipperId } = this.state;
            this.setState({
                isRejecting: true
            })
            const formData = new FormData();
            formData.append('rejectReason', rejectReason)
            const response = await ApiHandler({ url: Api.shipper_owed_reject, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, specificId: owedShipperId, customMessage: "This Settlement has been rejected" })
            if (response) {
                this.setState({
                    openRejectModal: false
                })
            }
            this.reFetch()
        } catch (error) {
            console.log(error);
        }finally{
            this.setState({
                isRejecting: false
            })
            this.formRef?.current?.resetFields();
        }
    }

    render() {
        const { openUploadExcelModal, isUploading, openRejectModal, openApproveModal, isRejecting, isApproving } = this.state

        const draggerProps = {
            name: 'file',
            multiple: false,
            showUploadList: false,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.handleFileUpload(file);
            },
        };

        const columns = [
            {
                title: 'Shipper Info',
                dataIndex: 'businessName',
                key: 'businessName',
                width: 300,
                render: (_, record) => (
                    <Flex vertical gap={10}>
                        <span style={{ fontWeight: 'bold' }}>{record.businessName}</span>
                        <span>{record.phoneNumber}</span>
                    </Flex>
                )
            },
            {
                title: 'Amount',
                key: 'amount',
                dataIndex: 'amount',
                align: 'center',
            },
            {
                title: 'Evidence',
                key: 'evidence',
                align: 'center',
                dataIndex: 'evidence',
                render: (evidence) =>
                    <Image
                        width={50}
                        src={evidence}
                        preview={evidence ? true : false}
                    />
            },
            {
                title: 'Payment Status',
                key: 'paymentStatus',
                dataIndex: 'paymentStatus',
                align: 'center',
                render: (_, record) => (
                    <>
                        {
                            record?.digitalPaymentTransaction && <div>
                                <span>{paymentTypeMap[record.digitalPaymentTransaction?.paymentType]}</span>
                                <Divider type='vertical' style={{ backgroundColor: 'black' }} />
                                {
                                    record.digitalPaymentTransaction?.paymentStatus &&
                                    <span>
                                        {
                                            record.digitalPaymentTransaction?.paymentStatus === 'Pending' &&
                                            <Tag color={Theme.colors.warning}>{record.digitalPaymentTransaction?.paymentStatus}</Tag>
                                        }
                                        {
                                            record.digitalPaymentTransaction?.paymentStatus === 'Fail' &&
                                            <Tag color={Colors.red}>{record.digitalPaymentTransaction?.paymentStatus}</Tag>
                                        }
                                        {
                                            record.digitalPaymentTransaction?.paymentStatus === 'Success' &&
                                            <Tag color={Theme.colors.success}>{record.digitalPaymentTransaction?.paymentStatus}</Tag>
                                        }

                                    </span>
                                }
                            </div>
                        }

                    </>
                )
            },
            {
                title: 'Upload Date',
                key: 'createdDate',
                dataIndex: 'createdDate',
                width: 200,
                render: (createdDate) => <span>{dayjs(createdDate).format('YYYY-MM-DD hh:mm A')}</span>
            },
            {
                title: 'Status',
                key: 'status',
                align: 'center',
                dataIndex: 'status',
                render: (status, row) => {
                    return status === "READY" ? (
                        <Flex vertical gap={10} align="center">
                            <Button type='primary' style={{ background: 'red' }} onClick={() => this.setState({ owedShipperId: row.id, openRejectModal: true })}>Reject</Button>
                            <Button type='primary' onClick={() => this.setState({ owedShipperId: row.id, openApproveModal: true })}>Approve</Button>
                        </Flex>) : <Tag color={`${status === 'CANCEL' ? '#FFA500' : status === 'APPROVED' ? '#1677ff' : status === 'REJECT' ? '#ff0000' : status === 'PENDING' ? '#ccc' : ''}`}>{status}</Tag>
                },
            },

        ]

        const filterColumn = [
            {
                key: 'businessName',
                name: 'Enter Business Name',
                type: FORM_ITEM.INPUT
            },
            {
                key: 'phoneNumber',
                name: 'Enter Phone Number',
                type: FORM_ITEM.INPUT
            },
            {
                key: 'status',
                name: 'Select Status',
                type: FORM_ITEM.SELECT,
                selectCollection: STATUS_COLLECTION
            },
        ]

        return (
            <>
                <Modal
                    title={"Upload Settlement Excel file"}
                    centered
                    open={openUploadExcelModal}
                    footer={null}
                    onCancel={() => this.closeModal()}
                    className='custom-modal'
                >
                    <Dragger
                        disabled={isUploading}
                        {...draggerProps}
                        accept={[".xlsx", ".xls"]}
                        maxCount={1}
                        style={{ marginTop: 15 }}>
                        <p className="ant-upload-drag-icon">
                            <InboxOutlined />
                        </p>
                        <p className="ant-upload-hint">
                            {
                                isUploading ?
                                    <div>
                                        <Spin /> <span> Uploading...</span>
                                    </div>
                                    :
                                    <span>Upload Excel File Only</span>
                            }

                        </p>
                    </Dragger>
                </Modal>
                <Modal title="Reject Modal" open={openRejectModal} footer={null} onCancel={() => this.setState({ openRejectModal: false, owedShipperId: null })} onOk={this.handleReject} style={{ top: 250 }}>
                    <Form
                        ref={this.formRef}
                        layout="vertical"
                        onFinish={this.handleRejectReason}
                    >
                        <Form.Item name="reason" label={"Reason for rejection"} rules={[{ required: true, message: '*(requried)' }]} >
                            <Input.TextArea placeholder="Enter your reason" maxLength={180} style={{ background: '#f5f5f5' }} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'end', marginBottom: 0 }}>
                            <Button style={{ marginRight: 20 }} onClick={() => this.setState({ openRejectModal: false })}>Cancel</Button>
                            <Button type="primary" loading={isRejecting} htmlType="submit">Confirm</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Modal title="Approve Modal" open={openApproveModal} onCancel={() => this.setState({ openApproveModal: false, owedShipperId: null })} onOk={this.handleApprove} style={{ top: 250 }} footer={[
                    <Button style={{ marginRight: 10 }} onClick={() => this.setState({ openApproveModal: false, owedShipperId: null })}>Cancel</Button>,
                    <Button type="primary" loading={isApproving} onClick={this.handleApprove} htmlType="submit">Confirm</Button>
                ]}>
                    <p>
                        Are you sure want to confirm this customer request?
                    </p>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={24} style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                        <TitleLevel3 label={"Settlement"} />
                        <Button type='primary' onClick={() => this.setState({ openUploadExcelModal: true })}
                            icon={<UploadOutlined />}
                        >Upload
                            Settlement</Button>
                    </Col>
                    <Col span={24}>
                        <NJVTable
                            columns={columns}
                            apiUrl={Api.shipper_owed}
                            filters={filterColumn}
                            handleRefetch={fetchData => this.reFetch = fetchData}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(Settlement)