import { PlusOutlined } from "@ant-design/icons";
import { Button, Col, Form, Modal, Radio, Row, Upload } from "antd";
import React, { useEffect, useState } from "react";
import { NJVInput } from "../../../components/core-component";
import Theme from "../../../components/theme";
import { checkValidFileSize } from "../../../components/validator";
import Api from "../../../network/api";
import { FILE_UPLOAD_PATH } from "../../../network/filePath";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { ApiHandler, FileUpload } from "../../../network/network-manager";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

const ShipperDirectoryProductUpdate = ({ data = null, open = false, onFinish }) => {

    const [openProductModal, setOpenProductModel] = useState(false)
    const [productImageUrl, setProductImageUrl] = useState(null)
    const [productFileList, setProductFileList] = useState([])
    const [isUpdating, setIsUpdating] = useState(false)


    const [form] = Form.useForm();

    useEffect(() => {
        console.log(data)
        setOpenProductModel(open)
        form.resetFields();
        form.setFieldsValue(data);
        setProductImageUrl(data?.productCoverUrl)
    }, [data, form, open])

    const clearForm = () => {
        setProductImageUrl(null)
        setProductFileList([])
        form.resetFields();
        if (onFinish) {
            onFinish()
        }
        setOpenProductModel(false)
    }

    const updateProductData = async (formData) => {
        setIsUpdating(true)
        formData['storeId'] = data.storeId
        formData['productCoverUrl'] = productImageUrl
        ApiHandler({ url: Api.userstore_product, method: HTTP_METHOD.PUT, requestData: formData, specificId: data.id })
            .then(() => {
                clearForm()
            })
            .catch(() => { })
            .finally(() => {
                setIsUpdating(false)
            })
    }

    const handleChange = (uploadObject) => {
        if (uploadObject.fileList?.length === 0) {
            setProductImageUrl(null)
            setProductFileList([])
        } else {
            if (checkValidFileSize(uploadObject.file)) {
                FileUpload({ file: uploadObject?.file, filePath: FILE_UPLOAD_PATH.USER_STORE_PRODUCT_IMAGES })
                    .then((response) => {
                        setProductImageUrl(response)
                        setProductFileList(uploadObject.fileList)
                    })
                    .catch(() => { })
            }
        }

    }

    // handlePreview = async (file) => {
    //     if (!file.url && !file.preview) {
    //         file.preview = await getBase64(file.originFileObj);
    //     }
    //     this.setState({
    //         previewImage: file.url || file.preview,
    //         previewOpen: true,
    //         previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
    //     })
    // };


    return (
        <>
            <Modal
                title="Product Update Form"
                open={openProductModal}
                onCancel={() => clearForm()}
                footer={null}
            >
                <Form
                    form={form}
                    layout="vertical"
                    onFinish={updateProductData}
                >
                    <Row gutter={[24, 16]}>
                        <Col span={24}>
                            <Upload
                                accept={["image/png", "image/jpg", "image/jpeg"]}
                                listType="picture-card"
                                fileList={productImageUrl ? [{ url: productImageUrl }] : productFileList}
                                multiple={false}
                                maxCount={1}
                                action={null}
                                onChange={(obj) => handleChange(obj)}
                                beforeUpload={() => false}
                            >
                                {productImageUrl ? null :
                                    <div>
                                        <PlusOutlined />
                                        <div
                                            style={{
                                                marginTop: 8,
                                            }}
                                        >
                                            Upload Product Image
                                        </div>
                                    </div>}
                            </Upload>
                        </Col>

                        <Col span={24} >
                            <Form.Item name="productName" label={"Product Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                <NJVInput placeholder={"Enter product name"} bgcolor={Theme.colors.input_bg_color} />

                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="productDescription" label={"Description"}>
                                <NJVInput placeholder={"Enter product description"} bgcolor={Theme.colors.input_bg_color} istextareainput={true} />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item
                                name="statusFlag">
                                <Radio.Group>
                                    <Radio value={'ACTIVE'}>Active</Radio>
                                    <Radio value={'INACTIVE'}>Inactive</Radio>
                                </Radio.Group>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={isUpdating}>Update</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form >
            </Modal>
        </>
    );
}

export default ShipperDirectoryProductUpdate