import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Input, Row, Upload, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVBackButton } from "../../components/core-component";
import DynamicEnumSelect from "../../components/fetch-enum";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { ApiPostWithFormData } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

const { TextArea } = Input

class NotificationChannelForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            fileList: [],
            isPushNotificaton: false,
            is_selected_partnership_admin: false,
            is_creating_data: false,
            pushUserType: null
        };
        this.inputRef = React.createRef()

    }

    handleChange = ({ fileList: newFileList }) => {
        let updateFileList = []
        newFileList && newFileList.forEach(file => {
            updateFileList.push({ ...file, status: 'done' })
        })
        this.setState({
            fileList: updateFileList
        })
    }

    saveNotificationChannel = async (values) => {
        this.setState({
            is_creating_data: true
        })
        try {
            let formData = new FormData()
            formData.append('title', values.title);
            formData.append('content', values.content);
            formData.append('active', values.active);
            formData.append('notificationType', values.notificationType)
            formData.append('partnerAdminSpecialCode', values.partnerAdminSpecialCode ? values.partnerAdminSpecialCode : '')
            if (values.photoUrl && values.photoUrl.file && values.photoUrl.file.originFileObj) {
                formData.append('file', values.photoUrl.file.originFileObj)
            }
            formData.append('userType', values.userType);
            formData.append('pushToSpecificUser', values.pushToSpecificUser ? values.pushToSpecificUser : false);
            if (values.isAutoPushNotification) {
                if (values.pushNotificationTime) {
                    const currentTime = dayjs()
                    if (currentTime > values.pushNotificationTime) {
                        message.error("Invalid selected push time")
                        return
                    }

                    let pushTime = values.pushNotificationTime.format("YYYY-MM-DD HH:mm:ss")
                    formData.append('isAutoPushNotification', values.isAutoPushNotification);
                    formData.append('pushNotificationTime', pushTime);
                } else {
                    message.error("Please select push time")
                    return
                }
            } else {
                formData.append('isAutoPushNotification', false);
                formData.append('pushNotificationTime', "");
            }
            const response = await ApiPostWithFormData(Api.notification_channel, formData, this.props)
            if (response) {
                this.props.navigate(CustomPath.notification_channel)
            }
        } catch (error) {
        }
        this.setState({
            is_creating_data: false
        })
    }


    getValidDate = () => {
        const currentDate = dayjs();
        const targetTime = dayjs().set('hour', 14).set('minute', 0).set('second', 0);
        const isBefore2PM = currentDate.isBefore(targetTime);
        if (isBefore2PM) {
            return dayjs().startOf('day');
        } else {
            return dayjs().endOf('day');
        }
    }

    disabledDate = (current) => {
        return current && current < dayjs().startOf('day')
    };

    onSelectAutoPushNotification = (event) => {
        this.setState({
            isPushNotificaton: event.target.checked
        })
    }

    onChangeUserType = (selectedValue) => {
        console.log(selectedValue)
        let is_selected_partner_ship = false
        if (selectedValue === 'SP_ADMIN' || selectedValue === 'SP_STAFF') {
            is_selected_partner_ship = true
        }
        if (is_selected_partner_ship) {
            this.setState({
                is_selected_partnership_admin: true
            })
            // this.inputRef.current.setFieldsValue({ userTypes: partnerList })
        } else {
            this.setState({
                is_selected_partnership_admin: false
            })
        }

    }

    render() {
        const { fileList, isPushNotificaton, is_selected_partnership_admin, is_creating_data } = this.state
        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.notification_channel}>NotificationChannel</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />

                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"NotificationChannel"}
                    extra={<Link to={CustomPath.notification_channel}><NJVBackButton /></Link>}
                >
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        initialValues={{ active: true }}
                        onFinish={this.saveNotificationChannel}>
                        <Row gutter={[16, 4]}>
                            <Col span={24}>
                                <Form.Item name="photoUrl">
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={fileList}
                                        action={null}
                                        showUploadList={{ showPreviewIcon: false }}
                                        onChange={this.handleChange}

                                    >
                                        {fileList.length >= 1 ? null :
                                            <div style={{ padding: 50 }}>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>
                                        }
                                    </Upload>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="notificationType" label={"Notification Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <DynamicEnumSelect enumtype='NOTIFICATION' size='large' placeholder={"Select notification type"} width="100%" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="userType" label={"Recipient Type"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <DynamicEnumSelect enumtype='USER_TYPE' size='large' placeholder={"Select recipient type"} width="100%" />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="title" label={"Title"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={""} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="content" label={"Content"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 150, background: '#fff' }}
                                        maxLength={1000}
                                        showCount={true}
                                        placeholder={"Something....."} />
                                </Form.Item>
                            </Col>
                            {
                                isPushNotificaton ?
                                    <Col span={12}>
                                        <Form.Item name="pushNotificationTime" label={"Select auto push notification date"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                            <DatePicker
                                                showTime
                                                disabledDate={this.disabledDate}
                                                size="large"
                                                allowClear={true}
                                                style={{ cursor: 'pointer' }}
                                                format={'YYYY-MM-DD HH:mm:ss'}
                                            />
                                        </Form.Item>
                                    </Col>
                                    :
                                    <></>
                            }

                            <Col span={24}>
                                {
                                    is_selected_partnership_admin ?
                                        <Form.Item name="partnerAdminSpecialCode" label={"Partnership Admin Unique Code"} >
                                            <TextArea
                                                style={{ height: 100, background: '#fff' }}
                                                maxLength={255}
                                                showCount={true}
                                                placeholder={"Enter partnership admin's unique code with comma seperated"} />
                                        </Form.Item>
                                        :
                                        <></>
                                }
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="pushToSpecificUser"
                                    valuePropName="checked">
                                    <Checkbox>Send to specific user</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={4}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                            {/* <Col span={24}>
                                <Form.Item
                                    name="isAutoPushNotification"
                                    onChange={this.onSelectAutoPushNotification}
                                    valuePropName="checked">
                                    <Checkbox>Enable Auto Push Notification</Checkbox>
                                </Form.Item>
                            </Col> */}
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={is_creating_data}>Create</Button>
                                </Form.Item>
                            </Col>
                        </Row>











                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(NotificationChannelForm)