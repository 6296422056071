import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Form, Input, List, Row, Switch, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import { NJVBackButton } from "../../components/core-component";

class ZoneForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            division_collection: [],
            township_collection: [],
            township_collection_full_list: [],
            selected_division: 0,
            selected_township_collection: [],
            selected_township_map: {},
            available_township_collection: []
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchAllDivisionAndTownship()
    }

    fetchAllDivisionAndTownship = async () => {
        await ApiHandler({ url: Api.zone_get_unassign_township, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            .then(response => {
                let divisionList = []
                let townshipList = []
                let townshipMap = {}
                response.map(division => {
                    if (division.townshipDTOList && division.townshipDTOList.length !== 0) {
                        divisionList.push(division)
                        townshipMap[division.id] = division.townshipDTOList
                    }

                })
                if (divisionList !== null && townshipMap !== null) {
                    townshipList = townshipMap[divisionList[0].id]
                }
                this.setState({
                    selected_division: divisionList && divisionList.length !== 0 ? divisionList[0].id : 0,
                    division_collection: divisionList,
                    township_collection_map: townshipMap,
                    township_collection: townshipList,
                    township_collection_full_list: townshipList
                });
            }).catch(error => {

            })
    }

    //filter
    handleFilterChange = (e) => {
        const { township_collection_full_list } = this.state
        const value = e.target.value;
        const filteredList = township_collection_full_list.filter((item) =>
            item.name.toLowerCase().includes(value.toLowerCase())
        );
        this.setState({
            township_collection: filteredList
        })
    };

    fetchTownship = async (id) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[id],
            selected_division: id
        })
    }

    divisionSelectorChange = (value) => {
        const { township_collection_map } = this.state
        this.inputRef.current.setFieldsValue({ township: null })
        this.setState({
            available_township_collection: township_collection_map[value],
        })
    }

    handleTownshipSwitch = (value, id) => {
        let { selected_township_map, selected_township_collection, township_collection } = this.state
        let selected_township_collection_temp = []

        if (!value) {
            selected_township_collection && selected_township_collection.map(selected_township => {
                if (selected_township.id !== id) {
                    selected_township_collection_temp.push(selected_township)
                }
            })
            selected_township_collection = selected_township_collection_temp
            delete selected_township_map[id]
        } else {

            township_collection && township_collection.map(township => {
                if (id === township.id) {
                    selected_township_collection.push(township)
                    selected_township_map[id] = township
                    return
                }
            })
        }
        this.setState({
            selected_township_map: selected_township_map,
            selected_township_collection: selected_township_collection
        })


    }

    saveZone = async (values) => {
        const { selected_township_collection } = this.state
        try {

            let township_collection = []
            selected_township_collection.map(township => {
                township_collection.push({ 'townshipId': township.id, 'price': 0 })
            })

            const response = await ApiHandler({
                url: Api.zone, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON,
                requestData: {
                    "zoneName": values.zoneName,
                    "description": values.description,
                    "zoneTownships": township_collection
                }
            })
            if (response.status === 200) {
                this.props.navigate(CustomPath.zone)
            }
        } catch (error) {
        }
    }

    render() {
        const { division_collection, township_collection, selected_division, selected_township_map, selected_township_collection } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={20}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.zone}>Zone</Link>,
                                },
                                {
                                    title: 'Form',
                                }
                            ]}
                        /></Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.zone}><NJVBackButton /></Link>
                    </Col>
                </Row>
                <Form
                    layout="vertical"
                    ref={this.inputRef}
                    initialValues={{ active: true }}
                    onFinish={this.saveZone}>
                    <Row gutter={[16, 16]}>
                        <Col span={12}>
                            <Form.Item name="zoneName" label={"Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                <Input
                                    style={{ background: '#f1f1f1', height: 40 }}
                                    placeholder="Enter zone name"
                                />
                            </Form.Item>
                        </Col>
                        <Col span={12}>
                            <Form.Item name="description" label={"Description"}>
                                <Input
                                    style={{ background: '#f1f1f1', height: 40 }}
                                    placeholder="Enter description"
                                />
                            </Form.Item>
                        </Col>
                        {
                            selected_township_collection && selected_township_collection.length !== 0 ?
                                <Col span={24} style={{ marginBottom: 20 }}>
                                    {
                                        selected_township_collection && selected_township_collection.map(item =>
                                            <span>
                                                <Tag style={{ fontSize: 13, padding: '7px 15px', fontWeight: '500', borderRadius: 90 }} bordered={false} closable onClose={() => this.handleTownshipSwitch(false, item.id)}>
                                                    {item.name}
                                                </Tag>
                                            </span>
                                        )
                                    }
                                </Col>
                                :
                                <></>
                        }
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Create</Button>
                            </Form.Item>
                        </Col>
                        <Col span={24} className="custom-btn" style={{ textAlign: 'right' }}>
                            <Input
                                onChange={this.handleFilterChange}
                                style={{ width: 200, background: '#f1f1f1', height: 40 }}
                                placeholder="Enter township"
                            />
                        </Col>
                        <Col span={12}>
                            <Card
                                style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                bordered={false}
                                title={"Division"}
                            >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={division_collection}
                                    renderItem={(item, index) => (
                                        <div className={selected_division === item.id ? "division-box-selected" : "division-box"}>
                                            <Row style={{ height: 50, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                                                <Col span={20} onClick={() => this.fetchTownship(item.id)} >
                                                    <span className="location-name">
                                                        {item.name}
                                                    </span>

                                                </Col>
                                            </Row>
                                        </div>
                                    )}
                                />
                            </Card>
                        </Col>
                        <Col span={12}>
                            <Card
                                style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                bordered={false}
                                title={"Township"}
                            >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={township_collection}
                                    renderItem={(item, index) => (
                                        <div className="division-box">
                                            <Row style={{ height: 50, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                                                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="location-name">
                                                        {item.name}
                                                    </div>
                                                    <Switch
                                                        checkedChildren={<CheckOutlined />}
                                                        unCheckedChildren={<CloseOutlined />}
                                                        onChange={(event) => this.handleTownshipSwitch(event, item.id)}
                                                        checked={selected_township_map && selected_township_map[item.id] ? true : false}
                                                    />
                                                </Col>

                                            </Row>
                                        </div>
                                    )}
                                />
                            </Card>
                        </Col>
                    </Row>







                </Form>
            </>
        );
    }
}

export default compose(withRouter)(ZoneForm)