import { DownloadOutlined, EyeOutlined } from "@ant-design/icons"
import { compose } from '@reduxjs/toolkit'
import { Button, Card, Col, message, Row } from 'antd'
import React, { Component } from 'react'
import { SubContent, SubTitle, TitleLevel3 } from '../../components/general-component'
import NJVTable from '../../components/njv-table'
import Theme from '../../components/theme'
import Api from '../../network/api'
import { DownloadFileWithUrl } from '../../network/network-manager'
import withRouter from '../../network/with-router'
import CustomPath from '../../routes/custom-path'
import { Link } from "react-router-dom"
import { NJVBackButton } from "../../components/core-component"

class JobPostCandidates extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: this.props.location?.state?.data,
            isDownloading: false,
            currentId: null
        }
    }
    handleDownload = async (downloadLink) => {
        if (downloadLink) {
            const parts = downloadLink.split("/");
            parts.toLocaleString()
            this.setState({
                isDownloading: true
            })
            DownloadFileWithUrl(downloadLink, parts[parts.length - 1])
                .then(() => { })
                .catch(() => { })
                .finally(() => {
                    this.setState({
                        isDownloading: false,
                        currentId: null
                    })
                });
        } else {
            message.error("No download link found")
        }

    };

    render() {
        const { data, isDownloading, currentId } = this.state;

        const columns = [
            {
                title: 'Name',
                key: 'name',
                dataIndex: 'name'
            },
            {
                title: 'Phone Number',
                key: 'phoneNumber',
                dataIndex: 'phoneNumber'
            },
            {
                title: 'CurrentSalary',
                key: 'currentSalary',
                dataIndex: 'currentSalary'
            },
            {
                title: 'ExpectedSalary',
                key: 'expectedSalary',
                dataIndex: 'expectedSalary'
            },
            {
                title: 'Cover Letter',
                key: 'coverLetter',
                dataIndex: 'coverLetter'
            },
            {
                title: 'CV form Url',
                key: 'cvFormUrl',
                dataIndex: 'cvFormUrl',
                render: (cvFormUrl, record) => (
                    <div>
                        <Button type='text' style={{ fontWeight: '600', color: Theme.colors.default }} onClick={() => window.open(cvFormUrl)} icon={<EyeOutlined />}>View CV</Button><br />
                        <Button type='text' style={{ fontWeight: '600', color: Theme.colors.default }} loading={currentId === record.id && isDownloading} onClick={() => {
                            this.handleDownload(cvFormUrl);
                            this.setState({
                                currentId: record.id
                            })
                        }} icon={<DownloadOutlined />}>Download CV</Button>
                    </div >
                )
            }

        ]

        return (
            <Row style={{ alignItems: 'center' }} gutter={[20, 20]} >
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Canditates"} />

                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Link to={CustomPath.job_post}><NJVBackButton /></Link>
                </Col>
                <Col span={24}>
                    {
                        data && <Card className="card-shadow">
                            <Row gutter={[4, 4]}>
                                <Col span={16}>
                                    <SubTitle label={"Position"} />
                                    <SubContent label={data?.position} />
                                </Col>
                                <Col span={8}>
                                    <SubTitle label={"Job Code"} />
                                    <SubContent label={data?.jobCode} />
                                </Col>
                                <Col span={8}>
                                    <SubTitle label={"Department"} />
                                    <SubContent label={data?.department} />
                                </Col>
                                <Col span={8}>
                                    <SubTitle label={"Gender"} />
                                    <SubContent label={data?.gender} />
                                </Col>
                                <Col span={8}>
                                    <SubTitle label={"Salary"} />
                                    <SubContent label={data?.salary} />
                                </Col>
                                <Col span={8}>
                                    <SubTitle label={"Location"} />
                                    <SubContent label={data?.location} />
                                </Col>
                            </Row>
                        </Card>
                    }
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        apiUrl={Api.job_post_canditates}
                        specificId={this.props.params.id}
                    />
                </Col>
            </Row >
        )
    }
}

export default compose(withRouter)(JobPostCandidates)