const admin = '/v1'
const CustomPath = {
    app: '/',
    dashboard: admin + '/dashboard',
    ninja_dashboard: admin + '/ninja/dashboard',

    core_config: admin + '/core-config',
    core_config_form: admin + '/core-config/form',
    core_config_update: admin + '/core-config/update',
    core_config_update_param: admin + '/core-config/update/:id',

    user: admin + '/user',
    user_get: admin + '/user/get/:id',
    user_create: admin + '/user/create',
    user_update: admin + '/user/update',
    user_update_param: admin + '/user/update/:id',

    user_group: admin + '/user-group',
    user_group_get: admin + '/user-group/get/:id',
    user_group_create: admin + '/user-group/create',
    user_group_update: admin + '/user-group/update',
    user_group_update_param: admin + '/user-group/update/:id',

    shipper: admin + '/shipper',
    shipper_get: admin + '/shipper/get/:id',
    shipper_create: admin + '/shipper/create',
    shipper_update: admin + '/shipper/update',
    shipper_update_param: admin + '/shipper/update/:id',
    shipper_approval: admin + '/shipper_approval',
    shipper_request_detail: admin + '/shipper-request-detail',
    shipper_request_detail_param: admin + '/shipper-request-detail/:id',
    shipper_settlement: admin + '/shipper-settlement',
    shipper_device_control: admin + '/shipper/device-control',
    shipper_devices: admin + '/shipper/devices',
    shipper_devices_param: admin + '/shipper/devices/:id',

    admin: admin + '/admin',
    admin_get: admin + '/admin/get/:id',
    admin_create: admin + '/admin/create',
    admin_update: admin + '/admin/update',
    admin_update_param: admin + '/admin/update/:id',

    partnership_admin: admin + '/partnership-admin',
    partnership_admin_get: admin + '/partnership-admin/get/:id',
    partnership_admin_create: admin + '/partnership-admin/create',
    partnership_admin_update: admin + '/partnership-admin/update',
    partnership_admin_update_param: admin + '/partnership-admin/update/:id',

    partnership_store: admin + '/partnership-admin/store',
    partnership_store_param: admin + '/partnership-admin/store/:id',
    partnership_store_get: admin + '/partnership-admin/store/get/:id',
    partnership_store_create: admin + '/partnership-admin/store/create',
    partnership_store_update: admin + '/partnership-admin/store/update',
    partnership_store_update_param: admin + '/partnership-admin/store/update/:id',

    driver: admin + '/driver',
    driver_get: admin + '/driver/get/:id',
    driver_create: admin + '/driver/create',
    driver_update: admin + '/driver/update',
    driver_update_param: admin + '/driver/update/:id',
    driver_location_log: admin + '/driver/location-logs',
    driver_location_log_detail: admin + '/driver/location-detail',
    driver_location_log_detail_param: admin + '/driver/location-detail/:id',

    loyalty_shop: admin + '/loyalty-shop',
    loyalty_shop_create: admin + '/loyalty-shop/create',
    loyalty_shop_update: admin + '/loyalty-shop/update',
    loyalty_shop_update_param: admin + '/loyalty-shop/update/:id',
    loyalty_usage_history: admin + '/loyalty-usage-history',
    shipper_loyalty: admin + '/shipper-loyalty-point',

    role: admin + '/role',
    role_get: admin + '/role/get/:id',
    role_create: admin + '/role/create',
    role_update: admin + '/role/update',
    role_update_param: admin + '/role/update/:id',

    division: admin + '/division',
    division_create: admin + '/division/create',
    division_update: admin + '/division/update',
    division_update_param: admin + '/division/update/:id',
    division_get: admin + '/division/get/:id',

    township: admin + '/division/township',
    township_param: admin + '/division/township/:id',
    township_create: admin + '/township/create',
    township_update: admin + '/township/update',
    township_update_param: admin + '/township/update/:id',
    township_get: admin + '/township/get/:id',
    home_delivery_manange: admin + '/manage/home-delivery',
    bus_gate_manage: admin + '/manage/bus-gate-service',

    zone: admin + '/zone',
    zone_create: admin + '/zone/create',
    zone_update: admin + '/zone/update',
    zone_update_param: admin + '/zone/update/:id',
    zone_to_zone_price_setup: admin + '/zone/zone-to-zone-price-setup',
    zone_same_city_price_setup: admin + '/zone/same-city-price-setup',
    zone_same_city_price_setup_param: admin + '/zone/same-city-price-setup/:id',

    normal_pricing_group: admin + '/normal_pricing-group',
    publishing_house_pricing_group: admin + '/publishing_house_pricing-group',
    ninja_point_pricing_group: admin + '/ninja_point_pricing-group',
    pricing_group_zone_same_city_price_setup: admin + '/pricing-group/same_city_price_setup',
    karmart_pricing_group: admin + '/karmart-pricing-group',
    safari_pricing_group: admin + '/safari-pricing-group',
    migoo_pricing_group: admin + '/migoo-pricing-group',

    bus_gate_page: admin + '/busgate',
    bus_gate_set_up: admin + '/busgate/setup',
    bus_gate_update: admin + '/busgate/update',
    bus_gate_update_param: admin + '/busgate/update/:id',

    user_address: admin + '/address',
    user_address_create: admin + '/address/create',
    user_address_update: admin + '/address/update',
    user_address_update_param: admin + '/address/update/:id',

    coverage: admin + '/coverage',
    coverage_create: admin + '/coverage/create',
    coverage_update: admin + '/coverage/update',
    coverage_update_param: admin + '/coverage/update/:id',

    product_category: admin + '/product_category',
    product_category_create: admin + '/product-category/create',
    product_category_update: admin + '/product-category/update',
    product_category_update_param: admin + '/product-category/update/:id',

    account: admin + '/account',

    shop: admin + '/shop',
    shop_create: admin + '/shop/create',
    shop_update: admin + '/shop/update',
    shop_update_param: admin + 'shop/update/:id',
    shop_get: admin + '/shop/get/:id',

    staff: admin + '/staff',
    staff_create: admin + '/staff/create',

    business_profile_request: admin + '/business_profile',
    business_profile_approval: '/business-profile-approval',

    notification_channel: admin + '/notification_channel',
    notification_channel_create: admin + '/notification-channel/create',
    notification_channel_update: admin + '/notification-channel/update',
    notification_channel_update_param: admin + '/notification-channel/update/:id',
    notification_channel_get: admin + '/notification-channel/get/:id',
    push_notification: admin + '/push-notification',
    notification_history: admin + '/notification-history',

    parcel_rating: admin + '/parcel-rating',
    order_create: admin + '/order/create',
    order_history: admin + '/order/history',
    order_detail: admin + '/order/detail',
    order_detail_param: admin + '/order/detail/:id',
    order_detail_update: admin + '/order/detail/update',
    order_detail_update_param: admin + '/order/detail/update/:id',
    awb_batch_print: admin + '/order/awb-batch-print',
    preview_and_print_awb: admin + '/order/preview-and-print-awb',
    order_price_update: admin + '/order/price_update',
    request_support: admin + '/shipper-support',
    rts_order_page: admin + '/rts-order',

    insurance_report: admin + '/insurance-report',

    parcel_pickup: admin + '/order/parcel-pickup',

    rom_order: admin + '/rom',

    nearby: admin + '/nearby',
    nearby_create: admin + '/nearby/create',
    nearby_update: admin + '/nearby/update',
    nearby_update_param: admin + '/nearby/update/:id',

    faq: admin + '/faq',
    faq_get: admin + '/faq/get/:id',
    faq_create: admin + '/faq/create',
    faq_update: admin + '/faq/update',
    faq_update_param: admin + '/faq/update/:id',
    faq_sorting: admin + '/faq/sorting',

    redis_cache: admin + '/redis',
    redis_cache_login_failure: admin + '/redis/login-failure-logs',
    announcement: admin + '/announcement',

    sos: admin + '/sos',

    logs: admin + '/logs',
    shipper_activity: admin + '/shipper-activity',
    admin_activity_log: admin + '/admin-activity-log',

    register_form: "/register",

    login: '/',
    logout: '/logout',

    inventory_driver_awb: admin + '/inventory/driver-awb',
    inventory_driver_awb_history: admin + '/inventory/driver-awb-history',
    inventory_driver_awb_history_param: admin + '/inventory/driver-awb-history/:id',

    update_order_status: admin + '/order/update_order_status',

    banner: admin + '/banner',
    banner_create: admin + '/banner/create',
    banner_update: admin + '/banner/update',

    ticket_repair_filter: admin + '/ticket_repair',

    department: admin + '/department',
    department_create: admin + '/department/create',
    department_update: admin + '/department/update',
    department_update_param: admin + '/department/update/:id',

    employee: admin + '/employee',
    employee_create: admin + '/employee/create',
    employee_update: admin + '/employee/update',
    employee_update_param: admin + '/employee/update/:id',

    feedback: admin + '/feedback',

    org_chart: admin + '/org-chart',
    org_chart_user_assign: admin + '/org-chart/approval-user-assign',
    org_chart_user_assign_param: admin + '/org-chart/approval-user-assign/:id',

    customer: admin + '/customer',
    customer_update: admin + '/customer/update',
    customer_update_param: admin + '/customer/update/:id',

    base_ticket: admin + '/tickets',

    ninja_complaint: admin + '/ninja-complaint',
    wallet_transaction: admin + '/wallet-transaction',
    wallets: admin + '/wallets',
    wallet: admin + '/wallet',

    store: admin + '/store',
    store_create: admin + '/store/create',
    store_update: admin + '/store/update',
    store_update_param: admin + '/store/update/:id',

    webHosting: admin + '/web-hosting',
    invoice: admin + '/invoice',
    invoice_list: admin + '/invoice/shipper',

    job_post: admin + '/ninja-career',
    job_post_param: admin + '/ninja-career/:id',
    job_post_create: admin + '/ninja-career/create',
    job_post_update: admin + '/ninja-career/update',
    job_post_update_param: admin + '/ninja-career/update/:id',

    osw: admin + '/osw',

    shipper_directory: admin + '/shipper/directory',
    shipper_directory_create: admin + '/shipper/directory/create',
    shipper_directory_update: admin + '/shipper/directory/update',
    shipper_directory_update_param: admin + '/shipper/directory/update/:id',

    
    

    shipper_directory_product__param: admin + '/shipper/directory/product/:id',
    shipper_directory_product_create: admin + '/shipper/directory/product/:id/create',
    shipper_directory_product_update: admin + '/shipper/directory/product/update',
    shipper_directory_product_update_param: admin + '/shipper/directory/product/update/:id',
};
export default CustomPath



