import { DeleteOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Col, Flex, List, message, Row, Tag, Upload } from 'antd';
import React from 'react';
import { NJVSelect } from '../components/core-component';
import { TitleLevel3 } from '../components/general-component';
import Theme, { Colors } from '../components/theme';
import Api from '../network/api';
import { HTTP_METHOD } from '../network/httpMethod';
import { ApiHandler } from '../network/network-manager';

const FILE_UPLOAD_STAUTS = {
    PENDING: 'Pending',
    SUCCESS: 'Success',
    FAILED: 'Failed'
}

const S3_PORTAL_BUCKET = [
    {
        value: 'NINJA_ADMIN_PORTAL_BUCKET',
        label: 'Admin Portal'
    },
    {
        value: 'NINJA_SHIPPER_PORTAL_BUCKET',
        label: 'Shipper Portal'
    },
    {
        value: 'NINJA_STORE_PORTAL_BUCKET',
        label: 'Ninja Store Portal'
    },
    {
        value: 'NINJA_PARTNER_PORTAL_BUCKET',
        label: 'Partner Portal'
    },
    {
        value: 'NINJA_LOYALTY_PARTNER_PORTAL_BUCKET',
        label: 'Loyalty Partner Portal'
    }
]

class WebHostingPage extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            files: [],
            uploading: false,
            progress: {},
            source: null,
            deployCompleted: false
        }
    }

    clearSession = () => {
        this.setState({
            files: [],
            uploading: false,
            progress: {},
            source: null,
            deployCompleted: false
        })
    }

    checkFileExistOrNot = (fileName) => {
        const { files } = this.state;
        let existingData = false;
        files?.forEach(existingFile => {
            if (existingFile?.name === fileName) {
                existingData = true;
            }
        })
        return existingData;
    }

    handleAddFolders = (info) => {
        const updatedFiles = info.fileList.map(file => {
            const folderPath = file.originFileObj.webkitRelativePath;
            if (!this.checkFileExistOrNot(folderPath)) {
                return {
                    key: file.uid,
                    uid: file.uid,
                    name: folderPath,
                    file: file.originFileObj,
                    status: FILE_UPLOAD_STAUTS.PENDING,
                    statusColor: 'gray'
                }
            } else {
                return null;
            }
        })
        if (updatedFiles[0] !== null) {
            const uniqueFiles = updatedFiles.filter(
                (file, index, self) =>
                    index === self.findIndex((f) => f.name === file.name)
            );

            this.setState(prevState => {
                const newFiles = [...prevState.files, ...uniqueFiles];
                console.log(newFiles)
                return { files: newFiles };
            });
        }
    }
    handleAddFiles = (info) => {
        console.log(this.state.files)
        const updatedFiles = info.fileList.map(file => {
            if (!this.checkFileExistOrNot(file.name)) {
                return {
                    key: file.uid,
                    uid: file.uid,
                    name: file.name,
                    file: file.originFileObj,
                    status: FILE_UPLOAD_STAUTS.PENDING,
                    statusColor: 'gray'
                }
            } else {
                return null;
            }
        })

        if (updatedFiles[0] !== null) {
            const uniqueFiles = updatedFiles.filter(
                (file, index, self) =>
                    index === self.findIndex((f) => f.name === file.name)
            );

            this.setState(prevState => {
                const newFiles = [...prevState.files, ...uniqueFiles];
                return { files: newFiles };
            });
        }
    }

    handleDeleteFile = (file) => {
        const updatedFiles = this.state.files.filter(f => f?.uid !== file?.uid);
        this.setState({ files: updatedFiles });
    }

    deploy = async () => {
        const { files } = this.state
        this.setState({ uploading: true });
        const uploadPromises = files.map(fileData => {
            if (fileData && fileData.file) {
                return this.uploadFileToServer(fileData);
            }
            return Promise.resolve();
        });
        await Promise.all(uploadPromises);
        this.setState({ uploading: false, deployCompleted: true });

    }

    uploadFileToServer = async (fileData) => {
        const { source } = this.state
        if (!source) {
            message.error("No source provided for uploading file")
            return
        }
        try {
            let formData = new FormData()
            formData.append("file", fileData.file)
            formData.append("type", source)
            formData.append("filePath", fileData.name)
            const response = await ApiHandler({ url: Api.private_hosting, method: HTTP_METHOD.POST, requestData: formData, disableShowMessage: true });
            if (response) {
                this.setState(prevState => {
                    const newFiles = prevState?.files?.map(file => {
                        if (file?.uid === fileData.uid) {
                            return { ...file, status: FILE_UPLOAD_STAUTS.SUCCESS, statusColor: Theme.colors.success }
                        } else {
                            return file;
                        }
                    })
                    return { files: newFiles };
                });
            }
        } catch (error) {
            this.setState(prevState => {
                const newFiles = prevState?.files?.map(file => {
                    if (file?.uid === fileData.uid) {
                        return { ...file, status: FILE_UPLOAD_STAUTS.FAILED, statusColor: Colors.red }
                    } else {
                        return file;
                    }
                })
                return { files: newFiles };
            });
        }
    }
    render() {
        const { files, uploading, deployCompleted, source } = this.state;
        return (
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <TitleLevel3 label="Ninja Portals Web Hosting" />
                </Col>
                <Col span={6}>
                    <NJVSelect
                        allowClear={true}
                        value={source}
                        size="large"
                        placeholder="Select Source"
                        bgcolor={Theme.colors.input_bg_color}
                        style={{ width: '100%' }} options={S3_PORTAL_BUCKET}
                        onChange={(event) => this.setState({ source: event })}
                    />
                </Col>
                <Col span={18}>
                    <Flex gap={10} justify='flex-end'>
                        <Upload
                            onChange={this.handleAddFiles}
                            multiple={true}
                            beforeUpload={() => false}
                            showUploadList={false}
                        >
                            <Button size="large" icon={<UploadOutlined />}>Add Files</Button>
                        </Upload>
                        <Upload
                            onChange={this.handleAddFolders}

                            directory
                            beforeUpload={() => false}
                            showUploadList={false}
                        >
                            <Button size="large" icon={<UploadOutlined />}>Add Folder</Button>
                        </Upload>

                    </Flex>
                </Col>
                <Col span={24}>
                    {
                        files?.length > 0 &&
                        <List
                            itemLayout="horizontal"
                            dataSource={files}
                            renderItem={(item, index) => (
                                <List.Item >
                                    {/* <Flex justify='space-between' style={{ fontSize: 16, width: '100%' }}> */}
                                    <Row style={{ fontSize: 16, width: '100%' }}>
                                        <Col span={12}>{item?.name}</Col>
                                        <Col span={4} style={{ textAlign: 'center' }}>
                                            <Tag color={item.statusColor}>{item?.status}</Tag>
                                        </Col>
                                        <Col span={8} style={{ textAlign: 'right' }}>
                                            <Button icon={<DeleteOutlined />} onClick={() => this.handleDeleteFile(item)} />
                                        </Col>
                                    </Row>
                                </List.Item>
                            )}
                        />
                    }
                </Col>
                <Col span={24}>
                    {
                        deployCompleted ?
                            <Button type='primary'
                                size='large'
                                onClick={() => this.clearSession()}
                            > Done </Button>
                            :
                            <>
                                {
                                    files?.length > 0 && <Button
                                        type='primary'
                                        size='large'
                                        onClick={() => this.deploy()}
                                        loading={uploading}> {uploading ? 'Deploying' : 'Deploy'}  </Button>
                                }
                            </>
                    }

                </Col>
            </Row >
        )
    }
}

export default WebHostingPage