import { Button, Card, Col, DatePicker, Form, Input, Row, Select } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { compose } from "redux";
import { PhoneNumberValidator, RestrictPhoneNumberValidator } from '../../components/validator';
import '../../css/order-create.css';
import Api from "../../network/api";
import { ApiFilter } from "../../network/network-manager";
import withRouter from "../../network/with-router";

class ODSenderForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            pickupInformation: {},
            c: false,
            isEditing: false,
            townshipList: [],
            previousData: {}
        }
        this.senderRef = React.createRef()
    }

    componentDidMount() {
        const { shipperAddress, divisionList } = this.props
        if (shipperAddress) {
            const shipperAddress = this.props.shipperAddress
            let township = null
            let division = null
            let townshipList = []
            if (shipperAddress.township) {
                if (shipperAddress.township.division) {
                    divisionList.map(d => {
                        if (d.id === shipperAddress.township.division.id) {
                            division = shipperAddress.township.division.id
                            if (this.props.townshipMap) {
                                townshipList = this.props.townshipMap[division]
                                if (townshipList) {
                                    townshipList.map(t => {
                                        if (t.id === shipperAddress.township.id) {
                                            township = shipperAddress.township.id
                                            return
                                        }
                                    })
                                    this.setState({ townshipList: townshipList })
                                }
                            }
                            return
                        }
                    })
                }
            }

            
            if (shipperAddress && shipperAddress.township && shipperAddress.township.division && shipperAddress.township.active && shipperAddress.township.division.active) {
                this.senderRef.current.setFieldsValue({ 'name': shipperAddress.fullName, 'phone': shipperAddress.phoneNumber, 'address': shipperAddress.address1, 'township': township, 'division': division })
            } 
        }
    }

    getValidDate = () => {
        const currentDate = dayjs();
        const targetTime = dayjs().set('hour', 13).set('minute', 0).set('second', 0);
        const isBefore2PM = currentDate.isBefore(targetTime);
        if (isBefore2PM) {
            return dayjs().startOf('day');
        } else {
            return dayjs().endOf('day');
        }
    }

    disabledDate = (current) => {
        return current && current < this.getValidDate();
    };

    handleSelect = (value) => {
        const { onSelect } = this.props
        if (onSelect) {
            onSelect(value)
        }
        this.setState({
            selectedShipper: value
        })
    }

    handleRemove = () => {
        const { onRemove } = this.props
        if (onRemove) {
            onRemove(null)
        }
        this.setState({
            selectedShipper: null
        })
    }

    handleDivisionSelect = (value) => {
        const { updateAble } = this.state
        const { townshipMap } = this.props
        const form = this.senderRef.current;
        form.setFieldsValue({
            township: null,
        });
        let townshipList = []
        if (townshipMap) {
            townshipList = townshipMap[value]
        }

        this.setState({
            isEditing: updateAble ? true : false,
            townshipList: townshipList
        })

    }

    handleEditing = () => {
        if (this.state.updateAble) {
            this.setState({
                isEditing: true
            })
        }
    }

    saveSenderInfo = async (value) => {
        const { confirmSenderForm } = this.props

        try {
            const param = { id: value.township }
            const response = await ApiFilter(Api.check_bus_gate_service_available, param, this.props)
            if (confirmSenderForm) {
                const pickupInformation = { ...value, isBusgateServiceAvailable: response, formPickupDate: value.formPickupDate, pickupDate: value.formPickupDate.format("YYYY-MM-DD") }
                confirmSenderForm(pickupInformation)
                this.setState({
                    isEditing: false,
                    updateAble: true,
                    previousData: pickupInformation
                })
            }
        } catch (error) {
        }


    }

    cancelEditing = () => {

        const { previousData } = this.state
        const { confirmSenderForm, townshipMap } = this.props
        if (confirmSenderForm) {
            confirmSenderForm(previousData)
            let townshipList = []
            if (townshipMap) {
                townshipList = townshipMap[previousData.division]
            }
            this.setState({
                isEditing: false,
                updateAble: true,
                townshipList: townshipList
            })
            this.senderRef.current.setFieldsValue(previousData)

        }


    }

    render() {
        const { updateAble, isEditing, townshipList } = this.state
        const { divisionList } = this.props
        return (
            <Card
                style={{ backgroundColor: "#f5f5f5", boxShadow: 'none', marginBottom: 20, paddingBottom: 0 }}
                bordered={false}
            >
                <Form
                    layout="vertical"
                    ref={this.senderRef}
                    onFinish={this.saveSenderInfo}
                >
                    <Row gutter={[16]} style={{ marginBottom: 15 }}>
                        <Col span={24}>
                            <h4 style={{ marginTop: 0 }}>Sender Information</h4>
                        </Col>
                        <Col lg={5} md={5} sm={24} xs={24}>
                            <Form.Item name="formPickupDate" label="Pick Up Date" rules={[{ required: true, message: '*(requried)' }]}>
                                <DatePicker
                                    allowClear={false}
                                    disabledDate={this.disabledDate}
                                    defaultValue={this.currentDate}
                                    size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                            </Form.Item>
                        </Col>


                        <Col span={24}>

                            <Row gutter={[16, 16]}>
                                <Col lg={5} md={6} sm={12} xs={12}>
                                    <Form.Item name="name" label="Name" rules={[{ required: true, message: '*(requried)' }]}>
                                        <Input size="large" onChange={this.handleEditing} />
                                    </Form.Item>
                                </Col>
                                <Col lg={5} md={6} sm={12} xs={12}>
                                    <Form.Item name="phone" label="Phone Number" rules={[{ validator: RestrictPhoneNumberValidator }]}>
                                        <Input size="large" placeholder="09" onChange={this.handleEditing} />
                                    </Form.Item>
                                </Col>
                                <Col lg={5} md={6} sm={12} xs={12}>
                                    <Form.Item name="division" label={"State & Division"} rules={[{ required: true, message: '*(requried)' }]}>
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder={"Select Division"}
                                            size="large"
                                            onChange={this.handleDivisionSelect}
                                            options={
                                                divisionList && divisionList.map((division) => {
                                                    return {
                                                        value: division.id,
                                                        label: division.name,
                                                        type: 'division'
                                                    }
                                                })
                                            }
                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={5} md={6} sm={12} xs={12}>
                                    <Form.Item name="township" label={"Township"} rules={[{ required: true, message: '*(requried)' }]}  >
                                        <Select
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder={"Select Township"}
                                            size="large"
                                            onChange={this.handleEditing}
                                            options={
                                                townshipList && townshipList.map((township) => {
                                                    return {
                                                        value: township.id,
                                                        label: township.name,
                                                        type: 'township'
                                                    }
                                                })
                                            }

                                        />
                                    </Form.Item>
                                </Col>
                                <Col lg={4} md={24} sm={24} xs={24}>
                                    <Form.Item name="address" label="Address" rules={[{ required: true, message: '*(requried)' }]}>
                                        <Input
                                            onChange={this.handleEditing}
                                            name="address"
                                            style={{
                                                width: '100%',
                                            }}
                                            placeholder="Enter full address"
                                        />
                                    </Form.Item>
                                </Col>



                                {
                                    updateAble ?
                                        <>
                                            {
                                                isEditing ?
                                                    <>
                                                        <Col span={24}>
                                                            Sender Informationမှအချက်အလက်များကို ပြုပြင်မွန်းမံလိုက်ပါက ယခင်သင်ရွေးချယ်ထားသော Orderအချက်အလက်များ ပျောက်ဆုံးသွားမည်ဖြစ်သည်။
                                                        </Col>
                                                        <Col span={24} style={{ height: 35, display: 'flex' }} >

                                                            <Form.Item>
                                                                <Button type="primary" style={{ backgroundColor: 'red' }} htmlType="submit">Update</Button>
                                                            </Form.Item>
                                                            <Form.Item>
                                                                <Button type="text" style={{ marginLeft: 10 }} onClick={() => this.cancelEditing()}>Cancel</Button>
                                                            </Form.Item>
                                                        </Col>
                                                    </>

                                                    :
                                                    <></>
                                            }
                                        </>
                                        :
                                        <Col span={24} style={{ height: 35 }}>
                                            <Form.Item >
                                                <Button type="primary" htmlType="submit">Next</Button>
                                            </Form.Item>
                                        </Col>
                                }
                            </Row>
                        </Col>
                    </Row>
                </Form>
            </Card >
        );
    }
}

export default compose(withRouter)(ODSenderForm)