import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Row, Select } from "antd";
import TextArea from "antd/es/input/TextArea";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import { NumberAndDecimelPointValidator, PhoneNumberValidator } from "../../components/validator";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import { NJVBackButton } from "../../components/core-component";

class NearbyUpdate extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            coverages: [],
            fileList: [],
            previewImage: '',
            previewOpen: false,
            fetchNearbyType: '',
            isUpdating: false
        };
        this.updateRef = React.createRef()

    }

    componentDidMount() {
        this.fetchData()
        this.fetchNearbyType()
    }

    fetchData = async () => {
        try {
            const response = await ApiHandler({ url: Api.nearby, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id })
            if (response) {
                this.setState({
                    data: response
                })
                this.updateRef.current.setFieldsValue(response);
            }
        } catch (error) {

        }
    }

    fetchNearbyType = async () => {
        try {
            const response = await ApiHandler({ url: Api.nearby_type, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                let nearbyTypeTemp = []
                response.map((value) => {
                    nearbyTypeTemp.push({
                        value: value,
                        label: value,
                    });
                })
                this.setState({
                    nearbyTypes: nearbyTypeTemp
                });
            }
        } catch (error) {

        }
    }

    updateNearby = async (values) => {
        const { data } = this.state
        try {
            this.setState({
                isUpdating: true
            })
            const response = await ApiHandler({
                url: Api.nearby, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON,
                requestData: {
                    "id": data.id,
                    "name": values.name,
                    "latitude": values.latitude,
                    "longitude": values.longitude,
                    "active": values.active,
                    "nearbyType": values.nearbyType,
                    "phone": values.phone,
                    "address": values.address
                }, specificId: data.id
            })

            if (response.status == 200) {
                this.props.navigate(CustomPath.nearby)
            }
            this.setState({
                isUpdating: false
            })
        } catch (error) {

        }
    }

    render() {
        const { nearbyTypes, isUpdating } = this.state
        const prevRoute = this.props.location.state?.route;

        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.coverage}>Coverage & Price</Link>,
                        },
                        {
                            title: 'Update',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Nearby Update"}
                    extra={
                        <Link to={prevRoute} relative="path">
                            <NJVBackButton />
                        </Link>
                    }
                >
                    <Form
                        layout="vertical"
                        ref={this.updateRef}
                        initialValues={{ active: true }}
                        onFinish={this.updateNearby}>

                        <Form.Item name="name" label={"Name"} rules={[{ required: true, message: '*(requried)' }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="phone" label={"Phone Number"} rules={[{ required: true, validator: PhoneNumberValidator }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="latitude" label={"Latitude"} rules={[{ required: true, validator: NumberAndDecimelPointValidator }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="longitude" label={"Longitude"} rules={[{ required: true, validator: NumberAndDecimelPointValidator }]}>
                            <Input />
                        </Form.Item>
                        <Form.Item name="address" label={"Adress"} rules={[{ required: true, message: '*(requried)' }]}>
                            <TextArea />
                        </Form.Item>
                        <Form.Item name="nearbyType" label={"Nearby Type"} rules={[{ required: true, message: '*(requried)' }]}>
                            <Select
                                size="large"
                                style={{
                                    width: '100%',
                                }}
                                placeholder="Select nearby type"
                                options={nearbyTypes}
                            />
                        </Form.Item>
                        <Form.Item
                            name="active"
                            valuePropName="checked">
                            <Checkbox>Active</Checkbox>
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'end' }}>
                            <Button loading={isUpdating} type="primary" htmlType="submit">Update</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(NearbyUpdate)