import { Breadcrumb, Button, Card, Col, Form, Input, List, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { ApiGet, ApiPut } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";
import { NJVBackButton } from "../../components/core-component";



class ZoneSameCityPriceSetup extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            township_collection: [],
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const response = await ApiGet(Api.zone, this.props.params.id, this.props)
            if (response) {
                this.setState({
                    data: response,
                    township_collection: response.zoneTownships
                })

                this.updateRef.current.setFieldsValue(response);
            }
        } catch (error) {
        }
    }

    saveZone = async (values) => {
        const { data, township_collection } = this.state
        try {
            const response = await ApiPut(Api.zone, data.id,
                {
                    "id": data.id,
                    "zoneTownships": township_collection
                }, this.props
            )
            if (response.status === 200) {
                this.props.navigate(CustomPath.zone)
            }
        } catch (error) {
        }
    }

    handleInput = (id, event) => {
        const { township_collection } = this.state
        const price = event.target.value
        const numberPattern = /^[0-9]*\.?[0-9]*$/;
        if (price === null || numberPattern.test(price)) {
            let list = []
            township_collection.map(t => {
                if (id === t.townshipId) {
                    t = { ...t, 'price': price }
                    list.push(t)
                } else {
                    list.push(t)
                }

            })
            this.setState({ township_collection: list })
        }
    }

    render() {
        const { data, township_collection } = this.state
        return (
            <>
                <Row style={{ marginBottom: "20px" }}>
                    <Col span={20}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.zone}>Zone</Link>,
                                },
                                {
                                    title: 'Update Same City Price',
                                }
                            ]}
                        /></Col>
                    <Col span={4} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.zone}><NJVBackButton /></Link>
                    </Col>
                </Row>
                <Form
                    layout="vertical"
                    ref={this.inputRef}
                    initialValues={{ active: true }}
                    onFinish={this.saveZone}>
                    <Row gutter={[16, 16]}>
                        <Col span={24}>
                            <h3>{data.zoneName}</h3>
                            <h5>{data.description}</h5>
                        </Col>
                        <Col span={15}>
                            <Card
                                style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                bordered={false}
                                title={"Township"}
                            >
                                <List
                                    itemLayout="horizontal"
                                    dataSource={township_collection}
                                    renderItem={(item, index) => (
                                        <div className="division-box">
                                            <Row style={{ height: 50, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                                                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                    <div className="location-name">
                                                        {item.townshipName}
                                                    </div>
                                                    <Input value={item.price === 0 ? null : item.price} onChange={(event) => this.handleInput(item.townshipId, event)} placeholder="Enter price" style={{ width: 150 }} />
                                                </Col>

                                            </Row>
                                        </div>
                                    )}
                                />
                            </Card>
                        </Col>

                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit">Save</Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Form>
            </>
        );
    }
}

export default compose(withRouter)(ZoneSameCityPriceSetup)