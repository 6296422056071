import React, { Component } from 'react'

export default class NinjaDashbord extends Component {
    render() {
        return (
            <div style={{ textAlign: 'center', marginTop: 200 }}>
                <h1 style={{ fontSize: 50 }}><span style={{ color: "#c2002f" }}>Ninja</span> Van Myanmar</h1>
            </div>
        )
    }
}
