import { InboxOutlined, OrderedListOutlined, UploadOutlined } from "@ant-design/icons";
import { compose } from '@reduxjs/toolkit';
import { Button, Col, Dropdown, Form, message, Modal, Row, Tag } from 'antd';
import Dragger from 'antd/es/upload/Dragger';
import dayjs from "dayjs";
import React, { Component } from 'react';
import { NJVInput } from '../../components/core-component';
import { SvgExcelOutlined, SvgPdfOutlined } from "../../components/custom-svg";
import { TitleLevel3 } from '../../components/general-component';
import NJVTable from "../../components/njv-table";
import Theme from '../../components/theme';
import { FORM_ITEM } from "../../core/constant";
import Api from "../../network/api";
import { DownloadFileWithUrl, uploadAndDownload } from "../../network/network-manager";
import withRouter from '../../network/with-router';

class InvoicePage extends Component {
    constructor(props) {
        super(props)
        this.state = {
            openUploadExcelModal: false,
            excelFile: null,
            isUploading: false,
            shipperView: false,
        }
        this.uploadFromRef = React.createRef()
    }

    closeModal = () => {
        if (this.state.isUploading) {
            message.error("You can not close at this time")
        } else {
            this.setState({
                openUploadExcelModal: false
            })
        }
    }

    handleFileUpload = (values) => {
        const { file, description } = values;

        this.setState({
            isUploading: true
        })
        let formData = new FormData();
        formData.append('file', file.file);
        formData.append('fileName', file.file.name);
        formData.append('description', description);

        uploadAndDownload(Api.invoice_excel_upload, formData, 'invalid-invoice_list')
            .then(() => {
                this.uploadFromRef.current.resetFields();
                this.reFetch();
            }).catch((error) => {
                if (error && error.response.status === 500) {
                    message.destroy()
                    message.error("Something went wrong")
                }
            }).finally(() => {
                this.setState({
                    isUploading: false,
                    openUploadExcelModal: false
                })
            })
    }

    downloadInvoice = (downloadLink) => {
        downloadLink = downloadLink.replace(
            'https://d1jejw4ssai0l5.cloudfront.net/',
            'https://ninjavanstorages.s3.ap-southeast-1.amazonaws.com/'
        );
        const parts = downloadLink.split("/");
        parts.toLocaleString()
        this.setState({
            isDownloading: true
        })
        DownloadFileWithUrl(downloadLink, parts[parts.length - 1])
            .then(() => { })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isDownloading: false,
                })
            });
    }

    render() {
        const { openUploadExcelModal, isUploading, isDownloading, currentId, currentName } = this.state;

        const draggerProps = {
            name: 'file',
            multiple: false,
            listType: 'picture',
            previceFile: true,
            beforeUpload: () => false,
            onChange: (info) => {
                const { file } = info;
                this.setState({ excelFile: file })
            },
        };

        const getMenuItems = (record) => [
            {
                key: '1',
                label: <SvgPdfOutlined width={25} height={25} color={Theme.colors.ninja_theme} />,
                onClick: () => {
                    this.downloadInvoice(record?.zipPdfUrl);
                    this.setState({
                        currentId: record.id,
                        currentName: 'PDF'
                    })
                }
            },
            {
                key: '2',
                label: <SvgExcelOutlined width={25} height={25} color={Theme.colors.ninja_theme} />,
                onClick: () => {
                    this.downloadInvoice(record?.zipExcelUrl);
                    this.setState({
                        currentId: record.id,
                        currentName: 'EXCEL_ZIP'
                    })
                }
            },
        ]

        const columns = [
            {
                title: 'File Name',
                key: 'fileName',
                dataIndex: 'fileName'
            },
            {
                title: 'Description',
                key: 'description',
                dataIndex: 'description'
            },
            {
                title: 'Created Date',
                key: 'createdDate',
                dataIndex: 'createdDate',
                render: (createdDate) => <span>{dayjs(createdDate).format('YYYY-MM-DD hh:mm A')}</span>
            },
            {
                title: 'Processing Time',
                key: 'processingTime',
                dataIndex: 'processingTime',
                align: 'center',
                render: (_, record) => {
                    if (!record.createdDate || !record.modifiedDate) return '-';

                    const diffInMinutes = Math.round(dayjs(record.modifiedDate).diff(dayjs(record.createdDate), 'minute', true));
                    return <span>{diffInMinutes} min</span>;
                }
            },
            {
                title: 'Download Uploaded File',
                key: 'download',
                dataIndex: 'download',
                align: 'center',
                render: (_, record) => {
                    return (
                        <Button disabled={record.status !== 'READY'} size={'large'} type="text" loading={(currentId === record.id && currentName === 'EXCEL') ? isDownloading : false} shape="circle" onClick={() => {
                            this.downloadInvoice(record?.url);
                            this.setState({
                                currentId: record.id,
                                currentName: 'EXCEL'
                            })
                        }}><SvgExcelOutlined width={25} height={25} color={Theme.colors.ninja_theme} /></Button>
                    )
                }
            },
            {
                title: 'Download as Zip',
                key: 'action',
                dataIndex: 'action',
                align: 'center',
                render: (_, record) => (
                    <Dropdown
                        menu={{
                            items: getMenuItems(record)
                        }}
                        trigger={['click']}
                    >
                        <Button size={'large'} type="text" disabled={record.status !== 'READY'}>
                            <OrderedListOutlined />
                        </Button>
                    </Dropdown>
                )
            },
            {
                title: 'Status',
                key: 'status',
                dataIndex: 'status',
                align: 'center',
                render: (value) => value && <Tag color={`${value === 'FAIL' ? '#f50' : value === 'READY' ? '#2db7f5' : value === 'IN_PROGRESS' ? Theme.colors.warning : 'default'}`}>{value}</Tag>
            }
        ]

        const filterColumn = [
            {
                key: 'fromDate-toDate',
                name: 'Date',
                type: FORM_ITEM.RANGEPICKER,
            },
        ]

        return (
            <>
                <Modal
                    title={"Upload Excel file"}
                    centered
                    open={openUploadExcelModal}
                    footer={null}
                    onCancel={() => this.closeModal()}
                >
                    <Form ref={this.uploadFromRef} onFinish={this.handleFileUpload}>
                        <Form.Item name="file" rules={[{ required: true, message: '*(requried)' }]}>
                            <Dragger
                                disabled={isUploading}
                                {...draggerProps}
                                accept={[".xlsx", ".xls"]}
                                maxCount={1}
                                style={{ marginTop: 15 }}>
                                <p className="ant-upload-drag-icon">
                                    <InboxOutlined />
                                </p>
                                <p className="ant-upload-hint">
                                    <span>Upload Excel File Only</span>
                                </p>
                            </Dragger>
                        </Form.Item>
                        <Form.Item name="description" rules={[{ required: true, message: '*(requried)' }]}>
                            <NJVInput type="primary" autoComplete="off"
                                bgcolor={Theme.colors.secondary} placeholder="Description" style={{ marginTop: 15 }} />
                        </Form.Item>
                        <Form.Item>
                            <Button type="primary" htmlType="submit" loading={isUploading} style={{ marginTop: 15 }}>Upload</Button>
                        </Form.Item>
                    </Form>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <TitleLevel3 label={"Invoice Upload History"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Button
                            type="primary"
                            icon={<UploadOutlined />}
                            onClick={() => this.setState({ openUploadExcelModal: true })}>Upload Excel</Button>
                    </Col>
                    <Col span={24}>
                        <NJVTable
                            columns={columns}
                            filters={filterColumn}
                            apiUrl={Api.invoice_upload_history}
                            handleRefetch={fetchData => this.reFetch = fetchData}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(InvoicePage)
