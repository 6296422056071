import React, { Component } from 'react'
import NJVTable from '../../components/njv-table'
import { Button, Col, Rate, Row, Tabs } from 'antd'
import { TitleLevel3 } from '../../components/general-component'
import Api from '../../network/api'
import { FORM_ITEM } from '../../core/constant'
import { Link } from 'react-router-dom'
import CustomPath from '../../routes/custom-path'

export default class ParcelRating extends Component {
    render() {
        const currentSearchUrl = window.location.search;

        const columns = [
            {
                title: 'Tracking Number',
                key: 'trackingNumber',
                dataIndex: 'trackingNumber'
            },
            {
                title: 'Shipper',
                key: 'rating',
                dataIndex: 'rating',
                render: (_, record) => (
                    <>
                        <Rate disabled defaultValue={record.rating} />
                        <br />
                        <span style={{ fontWeight: 600, fontSize: 16 }}>{record.feedback}</span>
                    </>
                )
            },
            {
                title: 'Customer',
                key: 'customerRating',
                dataIndex: 'customerRating',
                render: (_, record) => (
                    <>
                        <Rate disabled defaultValue={record.customerRating} />
                        <br />
                        <span style={{ fontWeight: 600, fontSize: 16 }}>{record.customerFeedback}</span>
                    </>
                )
            },
            {
                title: 'Detail',
                key: 'detail',
                dataIndex: 'detail',
                textAlign: 'center',
                width: 10,
                render: (_, record) => (
                    <Link to={`${CustomPath.order_detail}/${record.orderId}`} state={{ data: { id: record.orderId, route: `${CustomPath.parcel_rating}${currentSearchUrl}` } }}>
                        <Button size={'middle'} type="primary" style={{ marginBottom: 5 }} >View</Button>
                    </Link>
                ),
            }
        ]

        const filterColumn = [
            {
                key: 'number',
                name: 'Tracking Number',
                type: FORM_ITEM.INPUT
            },
        ]

        return (
            <Row gutter={[16, 16]}>
                <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                    <TitleLevel3 label={"Parcel Rating"} />
                </Col>
                <Col span={24}>
                    <NJVTable
                        columns={columns}
                        filters={filterColumn}
                        apiUrl={Api.parcel_rating}
                    />
                </Col>
            </Row>
        )
    }
}
