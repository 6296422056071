import { EditOutlined, EyeFilled } from "@ant-design/icons";
import { compose } from '@reduxjs/toolkit';
import { Button, Col, Drawer, Row, Space, Tag } from 'antd';
import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { TitleLevel3 } from '../../../components/general-component';
import NJVTable from '../../../components/njv-table';
import { FORM_ITEM } from "../../../core/constant";
import Api from '../../../network/api';
import withRouter from '../../../network/with-router';
import CustomPath from '../../../routes/custom-path';
import ShipperDirectoryProductPage from "./shipper-directory-product-page";

class ShipperDirectoryPage extends Component {

    constructor(props) {
        super(props);
        this.state = {
            openProductDrawer: false,
            current_store: null
        }
    }

    handleProductDrawer = (data) => {
        this.setState({ openProductDrawer: true, current_store: data })
    }

    render() {
        const { openProductDrawer, current_store } = this.state
        const columns = [
            {
                title: 'Shipper Shop Name',
                key: 'storeName',
                dataIndex: 'storeName'
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { statusFlag }) => (
                    <>
                        {
                            (
                                statusFlag === "ACTIVE" ? <Tag color="#87d068">{statusFlag}</Tag> : <Tag color="#cd201f">{statusFlag}</Tag>
                            )
                        }
                    </>
                ),

            },
            {
                title: 'Action',
                key: 'action',
                width: 60,
                render: (shipperShopDirectory) => (
                    <Space>
                        <Button
                            onClick={() => this.handleProductDrawer(shipperShopDirectory)}
                            size={'middle'}
                            type="primary"
                            icon={<EyeFilled />}>View Products</Button>
                        <Link to={`${CustomPath.shipper_directory_update}/${shipperShopDirectory.id}`}><Button size={'middle'} type="primary" shape="circle" icon={<EditOutlined />}></Button></Link>
                    </Space>

                ),
            },
        ]

        const filterColumn = [
            {
                key: 'name',
                name: 'Search by name',
                type: FORM_ITEM.INPUT,
            },
            {
                key: 'specialCode',
                name: 'Search by special code',
                type: FORM_ITEM.INPUT,
            },
            // {
            //     key: 'type',
            //     name: 'Select store type',
            //     type: FORM_ITEM.SELECT,
            //     selectCollection: [
            //         {
            //             label: 'Restaurant',
            //             value: 'RESTAURANT',
            //         },
            //     ]
            // },

        ]


        return (
            <>
                <Drawer
                    title={current_store?.storeName}
                    width='100%'
                    onClose={() => this.setState({ openProductDrawer: false, current_store: null })}
                    open={openProductDrawer}>
                    <ShipperDirectoryProductPage data={current_store} open={openProductDrawer} />
                </Drawer>
                <Row gutter={[16, 16]}>
                    <Col span={12} style={{ display: 'flex', alignItems: 'center' }}>
                        <TitleLevel3 label={"Shipper Shop Directory"} />
                    </Col>
                    <Col span={24}>
                        <NJVTable
                            columns={columns}
                            apiUrl={Api.userstore_filter}
                            filters={filterColumn}
                        />
                    </Col>
                </Row>
            </>
        )
    }
}

export default compose(withRouter)(ShipperDirectoryPage)