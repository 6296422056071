import { compose } from "@reduxjs/toolkit";
import { Card, Col, Row, Skeleton, Statistic } from "antd";
import React from "react";
import CountUp from 'react-countup';
import Theme from "../components/theme";
import Api from "../network/api";
import { HTTP_METHOD } from "../network/httpMethod";
import { MEDIA_TYPE } from "../network/mediaType";
import { ApiHandler, DownloadFileWithUrl } from "../network/network-manager";
import withRouter from "../network/with-router";

const formatter = (value) => <CountUp end={value} separator="," />;

const titlfontStyle = {
    fontWeight: '600',
    fontSize: 16,
}

const order_chart = [
    [`${new Date().getFullYear()}`, ""]
];
const days = [];
const values = [50, 250, 300, 350, 500, 700, 200];

for (let i = 0; i < 7; i++) {
    const date = new Date();
    date.setDate(date.getDate() - i);
    const formattedDate = date.toDateString().split(' ');
    days.push([`${formattedDate[1]} ${formattedDate[2]}`, values[6 - i]]);
}
order_chart.push(...days.reverse());

const options = {
    chart: {
        title: "Order Chart",
    },
    legend: { position: "none" },
    bar: { groupWidth: "95%" },
}

const statisticList = [
    {
        title: "Today's Order",
        value: 2000,
        color: '#10C200',
    },
    {
        title: "Active Shipper",
        value: 200,
        color: '#C2BC00',
    },
    {
        title: "Driver",
        value: 30,
        color: '#10C200',
    }
]

const titlefontStyle = {
    fontWeight: '600',
    fontSize: 16,
    color: '#000'
}

class DashboardPage extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            admin_count: 0,
            shipper_count: 0,
            pending_shipper_count: 0,
            reject_shipper_count: 0,
            isAdmin: false,
            isFetchingData: false
        }
    }

    componentDidMount() {
        this.fetchBaseUserSummary()
    }

    handleDownload = async (downloadLink) => {
        DownloadFileWithUrl("https://ninjavanstorages.s3.ap-southeast-1.amazonaws.com/application/NinjaVanMyanmar.apk", "HELLO")

    };

    fetchBaseUserSummary = async () => {
        this.setState({
            isFetchingData: true
        })
        try {
            const response = await ApiHandler({ url: Api.base_user_summary, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                this.setState({
                    admin_count: response.adminCount,
                    shipper_count: response.shipperCount,
                    pending_shipper_count: response.pendingShipperCount,
                    reject_shipper_count: response.rejectShipperCount,
                    isAdmin: response.admin
                })
            }
        } catch (error) {
            console.log(error);
        } finally {
            this.setState({
                isFetchingData: false
            })
        }
    }

    render() {
        const { admin_count,
            shipper_count,
            pending_shipper_count,
            reject_shipper_count, isAdmin, isFetchingData } = this.state
        return (
            <>
                {
                    isFetchingData ?
                        <Skeleton active />
                        :
                        isAdmin ?
                            <Row gutter={[16, 16]}>
                                <Col span={6}>
                                    <Card
                                        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                        bordered={false}>
                                        <Statistic
                                            title={<span style={titlfontStyle}>Admin</span>}
                                            value={admin_count}
                                            valueStyle={{
                                                color: '#3f8600',
                                            }}
                                            formatter={formatter}
                                        />
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card
                                        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                        bordered={false}>
                                        <Statistic
                                            title={<span style={titlfontStyle}>Shipper</span>}
                                            value={shipper_count}
                                            valueStyle={{
                                                color: '#3f8600',
                                            }}
                                            formatter={formatter}
                                        />
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card
                                        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                        bordered={false}>
                                        <Statistic
                                            title={<span style={titlfontStyle}>Pending Shipper</span>}
                                            value={pending_shipper_count}
                                            valueStyle={{
                                                color: '#3f8600',
                                            }}
                                            formatter={formatter}
                                        />
                                    </Card>
                                </Col>
                                <Col span={6}>
                                    <Card
                                        style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                        bordered={false}>
                                        <Statistic
                                            title={<span style={titlfontStyle}>Rejected Shipper</span>}
                                            value={reject_shipper_count}
                                            valueStyle={{
                                                color: '#3f8600',
                                            }}
                                            formatter={formatter}
                                        />
                                    </Card>
                                </Col>
                                {/* <Col span={24}>
                                    <Row gutter={[16, 16]}>
                                        {
                                            statisticList.map((item, index) => (
                                                <Col lg={6} md={6} xs={12} sm={12} key={index}>
                                                    <Card>
                                                        <Statistic
                                                            title={<Flex justify='space-between' align='center'>
                                                                <span style={titlefontStyle}>{item.title}</span>
                                                            </Flex>
                                                            }
                                                            value={item.value}
                                                            valueStyle={{
                                                                color: item.color,
                                                                fontWeight: 700
                                                            }}
                                                            formatter={formatter}
                                                        />
                                                    </Card>
                                                </Col>
                                            ))
                                        }
                                    </Row>
                                </Col>
                                <Col span={12} style={{ marginTop: 15 }}>
                                    <Chart
                                        chartType="Bar"
                                        width="100%"
                                        height="400px"
                                        data={order_chart}
                                        options={options}
                                    />
                                </Col> */}
                            </Row> :
                            <div style={{ textAlign: 'center', marginTop: 200 }}>
                                <h1 style={{ fontSize: 50 }}><span style={{ color: "#c2002f" }}>Ninja</span> Van Myanmar</h1>
                            </div>
                }
            </>
        )
    }
}

export default compose(withRouter)(DashboardPage)