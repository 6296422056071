import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Input, Modal, Radio, Row, Select, Upload, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVBackButton } from "../../../components/core-component";
import Theme from "../../../components/theme";
import { AdminPasswordValidator, RestrictPhoneNumberValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { ApiFilter, ApiPostWithFormData } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
class PartnershipAdminForm extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
            previewImage: null,
            previewTitle: null,
            roleData: [],
            roleOptions: [],
            currentDate: '',
            fileList: [],
            singleFile: {},
            isLoading: false,
            previewOpen: false
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        const date = dayjs();
        this.setState({ currentDate: date.subtract(18, 'year') })
        this.fetchRole()
    }

    fetchRole = async () => {
        try {
            var params = {
                page: 0,
                size: 100
            }
            const response = await ApiFilter(Api.role_filter, params, this.props)
            if (response && response.content) {
                this.setState({
                    roleData: response.content
                });
            }
        } catch (error) {
        }
    }

    savePartnershipAdmin = async (values) => {
        this.setState({
            isLoading: true
        })
        try {
            if (values.primaryPhoneNumber.length > 12 || values.primaryPhoneNumber.length < 5) {
                message.destroy()
                message.error("Invalid Phone Number")
                return
            }
            if (values.dateOfBirth === undefined) {
                const date = this.currentDate()
                values = { ...values, dateOfBirth: date.format("YYYY-MM-DD") }
            }

            let formData = new FormData()
            formData.append('firstName', values.firstName);
            formData.append('lastName', values.lastName);
            if (values.lastName) {
                formData.append('fullName', values.firstName + " " + values.lastName);
            } else {
                formData.append('fullName', values.firstName);
            }
            formData.append('approvalStatus', 'APPROVED');
            formData.append('userStatus', values.active ? "ACTIVE" : "INACTIVE");
            formData.append('phoneNumber', values.primaryPhoneNumber);
            formData.append('accounts[0].loginId', values.primaryPhoneNumber);
            formData.append('accounts[0].primaryPhoneNumber', values.primaryPhoneNumber);
            formData.append('accounts[0].password', values.password);
            formData.append('accounts[0].accountType', "PHONE");
            formData.append('role.id', values.role);
            if (values.photoUrl && values.photoUrl.file && values.photoUrl.file.originFileObj) {
                formData.append('storeLogo', values.photoUrl.file.originFileObj)
            }
            const response = await ApiPostWithFormData(Api.partnership_admin, formData)
            if (response.status === 200) {
                this.props.navigate(CustomPath.partnership_admin)
            }
        } catch (error) {
        }
        this.setState({
            isLoading: false
        })
    }

    disabledDate = (current) => {
        const eighteenYearsAgo = dayjs().subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        return dayjs().subtract(18, 'year')
    }

    handleChange = (uploadObject) => {
        if (this.checkValidFileSize(uploadObject.file)) {
            this.setState({
                fileList: uploadObject.fileList
            })
        }

    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    };
    checkValidFileSize = (file) => {
        const isLt2M = file.size / 1024 / 1024 < 5;
        if (!isLt2M) {
            message.destroy()
            message.error('Image must smaller than 5MB!');
        }
        return isLt2M;
    };

    render() {
        const { roleData, isLoading, fileList, previewOpen, previewImage, previewTitle } = this.state
        return (
            <>
                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.partnership_admin}>Partnership Admin</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Partnership Admin Form"}
                    extra={<Link to={CustomPath.partnership_admin}><NJVBackButton /></Link>}
                >
                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        initialValues={{ gender: 'MALE', active: true }}
                        onFinish={this.savePartnershipAdmin}
                    >
                        <Row>
                            <Col span={12} >
                                <Form.Item name="specialCode" label={"Unique Id"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter unique id"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} />
                            <Col span={12} style={{ paddingRight: 5 }}>
                                <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter first name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5 }}>
                                <Form.Item name="lastName" label={"Last Name"}>
                                    <Input placeholder={"Enter last name"} />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{ paddingRight: 5 }}>
                                <Form.Item
                                    name="primaryPhoneNumber"
                                    label={"Phone Number"}
                                    rules={[
                                        { required: true, message: '*(requried)' },
                                        { validator: RestrictPhoneNumberValidator }
                                    ]}>
                                    <Input placeholder={"09xxxxxxxxx"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} style={{ paddingLeft: 5 }}>
                                <Form.Item name="password" label={"Password"}
                                    rules={[{ required: true, message: '*(requried)' },
                                    { validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" autocomplete="new-password" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12} style={{ paddingRight: 5 }}>
                                <Form.Item name="gender" label={"Gender"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        size="large"
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select gender"
                                        options={[
                                            {
                                                label: 'Male',
                                                value: 'MALE',
                                            },
                                            {
                                                label: 'Female',
                                                value: 'FEMALE',
                                            },
                                        ]}
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={12} style={{ paddingLeft: 5 }}>
                                <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                    <DatePicker
                                        disabledDate={this.disabledDate}
                                        defaultValue={this.currentDate()}
                                        allowClear={false}
                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="photoUrl" label={"Upload Store Logo"}>
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={fileList}
                                        multiple={false}
                                        maxCount={1}
                                        action={null}
                                        onPreview={this.handlePreview}
                                        onChange={this.handleChange}

                                    >
                                        {fileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                            </div>}
                                    </Upload>
                                </Form.Item>
                            </Col>
                            {
                                roleData && roleData.length !== 0 ?
                                    <Col span={24}>
                                        <Form.Item name="role" label={"Role"}>
                                            <Radio.Group
                                                style={{
                                                    width: '100%',
                                                }}
                                            >
                                                <Row>
                                                    {
                                                        roleData.map((value) =>
                                                            <Col span={24} style={{ marginBottom: 5 }}>
                                                                <Radio value={value.id}>{value.roleTypeName}</Radio>
                                                            </Col>
                                                        )

                                                    }
                                                </Row>
                                            </Radio.Group>

                                        </Form.Item>
                                    </Col>
                                    :
                                    <></>
                            }
                        </Row>


                        <Form.Item style={{ textAlign: 'end' }}>
                            {/* <Button style={{ marginRight: '20px' }}>Reset</Button> */}
                            <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(PartnershipAdminForm)