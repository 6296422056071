import { MinusCircleOutlined, PlusCircleFilled } from '@ant-design/icons';
import { Breadcrumb, Button, Card, Col, Input, Modal, Row, Skeleton, Table, Tag } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVBackButton } from '../../../components/core-component';
import Theme from "../../../components/theme";
import { Constant } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiFilter, ApiGet, ApiPost } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

class UserGroupUpdate extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            userGroupData: {},
            data: [],
            current_user_data: [],
            no_group_user_data: [],

            added_user_list: [],
            remove_user_list: [],

            pageSize: Constant.pageSize,
            totalPagination: 0,
            adduser_page: 1,
            adduser_totalPagination: 0,
            page: 1,

            updateForm: {},
            updateDivision: {},
            roles: [],

            openModal: false,
            openCreateModal: false,
            openUpdateModal: false,
            activeStatus: true,
            isDataFetching: false,
            filterData: {},
            nonUserGroupFilterData: {},
            openAddModal: false,
            removeModalConfirmation: false,
            adding_to_group_loading: false,
            removing_from_group_loading: false
        };
        this.updateRef = React.createRef()

    }

    componentDidMount() {
        this.fetchNoGroupUser()
        this.fetchUserGroup()
    }

    fetchUserGroup = async () => {
        try {
            const response = await ApiGet(Api.user_group, this.props.params.id, this.props)
            if (response) {
                this.setState({
                    userGroupData: response
                }, () => this.fetchGroupUser())
            }
        } catch (error) {
        }
    }

    removeUserFromGroup = async () => {
        const { remove_user_list } = this.state
        this.setState({
            removing_from_group_loading: true
        })
        try {
            let requestBody = {
                'userIds': remove_user_list
            }
            const response = await ApiPost(Api.remove_user_from_group, requestBody, this.props)
            if (response) {
                this.setState({
                    removeModalConfirmation: false,
                    remove_user_list: []
                }, () => { this.fetchGroupUser(1); this.fetchNoGroupUser(1); })
            }
        } catch (error) {
        }
        this.setState({
            removing_from_group_loading: false
        })
    }


    updateUserGroup = async () => {
        const { userGroupData, added_user_list } = this.state
        this.setState({
            adding_to_group_loading: true
        })
        try {
            let requestBody = {
                'id': userGroupData.id,
                'userIds': added_user_list
            }
            const response = await ApiPost(Api.add_user_to_group, requestBody, this.props)
            if (response) {
                this.setState({
                    openAddModal: false,
                    added_user_list: []
                }, () => { this.fetchGroupUser(1); this.fetchNoGroupUser(1) })
            }
        } catch (error) {
        }
        this.setState({
            adding_to_group_loading: false
        })
    }

    fetchGroupUser = async (pageNumber) => {
        const { pageSize, adduser_page, filterData, userGroupData } = this.state
        this.setState({
            isDataFetching: true
        })
        try {
            var params = {
                ...filterData,
                size: pageSize,
                userGroupId: userGroupData.id,
                page: pageNumber ? pageNumber - 1 : adduser_page - 1,

            }
            const response = await ApiFilter(Api.user_group_memeber_filter, params, this.props)
            if (response && response.content) {
                let newList = []
                response.content.map(shipper => {
                    shipper = { ...shipper, key: shipper.id }
                    newList.push(shipper)
                })
                this.setState(prevState => ({
                    current_user_data: newList,
                    totalPagination: response.totalElements,
                    page: pageNumber ? pageNumber : adduser_page
                }));
            }
        } catch (error) {
        }
        this.setState({
            isDataFetching: false
        })
    }


    fetchNoGroupUser = async (pageNumber, value) => {
        const { adduser_page, nonUserGroupFilterData } = this.state
        this.setState({
            isNoGroupUserDataFetching: true
        })
        try {
            var params = {
                ...nonUserGroupFilterData,
                size: 100,
                page: pageNumber ? pageNumber - 1 : adduser_page - 1,
            }
            const response = await ApiFilter(Api.user_group_memeber_filter, params, this.props)
            if (response && response.content) {
                let newList = []
                response.content.map(shipper => {
                    shipper = { ...shipper, key: shipper.id }
                    newList.push(shipper)
                })
                this.setState(prevState => ({
                    no_group_user_data: newList,
                    adduser_totalPagination: response.totalElements,
                    adduser_page: pageNumber ? pageNumber : adduser_page,
                }));
            }
        } catch (error) {
        }
        this.setState({
            isNoGroupUserDataFetching: false
        })
    }

    updateFilterData = (key, value) => {
        let { filterData } = this.state
        filterData = {
            ...filterData,
            [key]: value
        }
        this.setState({
            filterData: filterData
        })
    }

    updateNonUserGroupFilterData = (key, value) => {
        let { nonUserGroupFilterData } = this.state
        nonUserGroupFilterData = {
            ...nonUserGroupFilterData,
            [key]: value
        }
        this.setState({
            nonUserGroupFilterData: nonUserGroupFilterData
        })
    }

    handleRoleChange = (value) => {
        this.setState({
            searchRole: value
        })
    }
    noGroupUserSearch = () => {
        this.fetchData(1)
    }



    handleAddUserPaginationChange = (pageNumber, pageSize) => {
        this.fetchNoGroupUser(pageNumber);
    };

    handleCurrentUserPaginationChange = (pageNumber, pageSize) => {
        this.fetchGroupUser(pageNumber);
    };

    render() {
        const { current_user_data, no_group_user_data, adduser_page, adduser_totalPagination, totalPagination, page, pageSize, isDataFetching, isNoGroupUserDataFetching, openAddModal, removeModalConfirmation, userGroupData, added_user_list, removing_from_group_loading, adding_to_group_loading } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(adduser_page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Full Name',
                key: 'fullName',
                render: (user) => (
                    <span>{user.firstName} {user.lastName}</span>
                ),
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'User Type',
                key: 'userType',
                dataIndex: 'userType'

            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            }
        ]

        const currentUserColumns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{(page - 1) * pageSize + index + 1}</span>,
                width: 5
            },
            {
                title: 'Full Name',
                key: 'fullName',
                render: (user) => (
                    <span>{user.firstName} {user.lastName}</span>
                ),
            },
            {
                title: 'Phone Number',
                dataIndex: 'phoneNumber',
                key: 'phoneNumber'
            },
            {
                title: 'User Type',
                key: 'userType',
                dataIndex: 'userType'

            },
            {
                title: 'Status',
                key: 'active',
                render: (_, { userStatus }) => (
                    <>
                        {
                            (
                                userStatus === "ACTIVE" ? <Tag color="#87d068">{userStatus}</Tag> : <Tag color="#cd201f">{userStatus}</Tag>
                            )
                        }
                    </>
                ),

            }
        ]


        const addRowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    added_user_list: selectedRowKeys
                })
            }
        };

        const removeRowSelection = {
            onChange: (selectedRowKeys, selectedRows) => {
                this.setState({
                    remove_user_list: selectedRowKeys
                })
            },
            getCheckboxProps: (record) => ({
                disabled: record.name === 'Disabled User',
                name: record.name,
            }),
        };
        return (
            <>
                <Modal
                    title="Remove shipper confirmation"
                    open={removeModalConfirmation}
                    onOk={() => this.removeUserFromGroup()}
                    onCancel={() => this.setState({
                        removeModalConfirmation: false
                    })}
                    confirmLoading={removing_from_group_loading}
                    okText="Confirm"
                    cancelText={null}
                >
                    <p>Are you sure to remove selected shipper?</p>
                </Modal>
                <Modal
                    title="Select & add to group"
                    width={'100%'}
                    height={'100%'}
                    style={{
                        top: 10,
                        bottom: 10,
                        right: 10,
                        left: 10
                    }}
                    open={openAddModal}
                    onOk={() => this.updateUserGroup()}
                    confirmLoading={adding_to_group_loading}
                    onCancel={() => this.setState({
                        openAddModal: false
                    })}
                    okText="Add To Group"
                    cancelText={null}
                >
                    <Row gutter={[16, 16]} style={{ marginTop: 30 }}>
                        <Col span={6}>
                            <Input
                                name="fullName"
                                onChange={(event) => this.updateNonUserGroupFilterData('fullName', event.target.value)}
                                style={{ background: '#f1f1f1', marginRight: 8 }} placeholder="Type and search" />
                        </Col>
                        <Col span={6}>
                            <Input name="businessName"
                                onChange={(event) => this.updateNonUserGroupFilterData('businessName', event.target.value)}
                                style={{ background: '#f1f1f1', marginRight: 8 }}
                                placeholder="Search by business name" />
                        </Col>
                        <Col span={6}>
                            <Button
                                style={{ width: '100%' }}
                                type="primary" size="large" onClick={() => this.fetchNoGroupUser()}>Search</Button>
                        </Col>
                        <Col span={24}>
                            {
                                isNoGroupUserDataFetching ?
                                    <Skeleton active />
                                    :
                                    <Table
                                        style={{ borderRadius: 10 }}
                                        rowSelection={addRowSelection}
                                        selectedRowKeys={added_user_list}
                                        pagination={{
                                            position: 'top' | 'bottom',
                                            total: adduser_totalPagination,
                                            current: adduser_page,
                                            onChange: this.handleAddUserPaginationChange,
                                            defaultPageSize: 100,
                                            showSizeChanger: false
                                        }}
                                        columns={columns}
                                        dataSource={no_group_user_data}
                                        rowClassName={getRowClassName} />
                            }
                        </Col>
                    </Row>
                </Modal>
                <Row gutter={[16, 16]}>
                    <Col span={24}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.user_group}>User Group</Link>,
                                },
                                {
                                    title: 'Update',
                                }
                            ]}
                        />
                    </Col>
                    <Col span={24}>
                        <Card
                            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                            bordered={false}
                            title={"User Group Update"}
                            extra={<Link to={CustomPath.user_group}><NJVBackButton /></Link>}
                        >
                            <Row gutter={[16, 24]}>
                                <Col span={24} style={{ display: 'flex', fontSize: 20, fontWeight: '600' }}>
                                    <div>{userGroupData.name} </div>
                                    {
                                        userGroupData && userGroupData.description ?
                                            <div>
                                                ( {userGroupData.description} )
                                            </div>
                                            :
                                            <></>
                                    }

                                </Col>
                                <Col span={6}>
                                    <Input name="fullName"
                                        onChange={(event) => this.updateFilterData('fullName', event.target.value)}
                                        placeholder="Search by name" />
                                </Col>
                                <Col span={6}>
                                    <Input name="businessName"
                                        onChange={(event) => this.updateFilterData('businessName', event.target.value)}
                                        placeholder="Search by business name" />
                                </Col>
                                <Col span={6}>
                                    <Button type="primary" style={{ width: '100%' }} size="large" onClick={() => this.fetchGroupUser()}>Search</Button>
                                </Col>
                                <Col span={6} >
                                    <Button
                                        style={{ width: '100%' }}
                                        type="primary"
                                        size="large"
                                        icon={<PlusCircleFilled />}
                                        onClick={() => this.setState({ openAddModal: true })}>
                                        Add New Shipper
                                    </Button>
                                </Col>

                                <Col span={18}>
                                    <span style={{ fontSize: 18, fontWeight: '600' }}>Current Group Memebers</span>
                                </Col>
                                <Col span={6}>
                                    <Button
                                        type="primary"
                                        size="large"
                                        icon={<MinusCircleOutlined />}
                                        style={{ width: '100%', backgroundColor: 'red' }}
                                        onClick={() => this.setState({ removeModalConfirmation: true })}>
                                        Remove
                                    </Button>
                                </Col>

                                <Col span={24}>
                                    {
                                        isDataFetching ?
                                            <Skeleton active />
                                            :
                                            <Table
                                                style={{ borderRadius: 10 }}
                                                rowSelection={{
                                                    ...removeRowSelection,
                                                }}
                                                pagination={{
                                                    position: 'top' | 'bottom',
                                                    total: totalPagination,
                                                    current: page,
                                                    onChange: this.handleCurrentUserPaginationChange,
                                                    defaultPageSize: pageSize,
                                                    showSizeChanger: false
                                                }}
                                                columns={currentUserColumns}
                                                dataSource={current_user_data}
                                                rowClassName={getRowClassName} />
                                    }
                                </Col>
                            </Row>

                        </Card>
                    </Col>

                </Row>
            </>
        );
    }
}

export default compose(withRouter)(UserGroupUpdate)