import { PlusOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Form, Input, message, Modal, Radio, Row, Select, Space, Upload } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVBackButton, NJVSearchFilterOption } from "../../../components/core-component";
import Theme, { Colors } from "../../../components/theme";
import { checkValidFileSize, RestrictPhoneNumberValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { FILE_UPLOAD_PATH } from "../../../network/filePath";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { ApiHandler, FileUpload } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const getBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
const { TextArea } = Input
class ShipperDirectoryForm extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            shipperData: null,
            previewImage: null,
            previewTitle: null,
            currentDate: '',
            profileFileList: [],
            coverFileList: [],
            isLoading: false,
            previewOpen: false,
            township_collection: [],
            product_category_collection: [],
            coverPhotoUrl: null,
            profilePhotoUrl: null
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        const shipperData = this.props.location.state?.data
        this.setState({
            shipperData
        })
        this.fetchProductCategory();
        this.handleDivisionSelect(shipperData?.township?.division?.id)
        this.inputRef.current.setFieldsValue({
            townshipId: shipperData?.township?.id,
            divisionId: shipperData?.township?.division?.id,
            phoneNumber: shipperData?.phoneNumber,
            storeName: shipperData?.business?.businessName,
            productCategory: shipperData?.business?.productCategory?.id,
            address: shipperData?.address1,
            contactFacebook: shipperData?.business?.facebookPageLink,
            contactTiktok: shipperData?.business?.tiktokUserId
        })
    }
    handlePhoneNumberChange = (e) => {
        const inputValue = e.target.value;
        const numericValue = inputValue.replace(/[^0-9]/g, '');
        this.inputRef.current.setFieldsValue({ primaryPhoneNumber: numericValue })
    };


    saveShipperDirectoryForm = async (formData) => {
        const { coverPhotoUrl, profilePhotoUrl, shipperData } = this.state;

        if (!profilePhotoUrl) {
            message.error("Please upload store profile")
            return;
        }

        formData['userId'] = shipperData?.id
        formData['profileUrl'] = profilePhotoUrl
        formData['coverUrl'] = coverPhotoUrl
        formData['township'] = { id: formData.townshipId }
        formData['productCategory'] = { id: formData.productCategory }
        ApiHandler({ url: Api.userstore, method: HTTP_METHOD.POST, requestData: formData })
            .then(() => { this.props.navigate(CustomPath.shipper_directory) })
            .catch(() => { })
            .finally(() => {
                this.setState({
                    isLoading: false
                })
            })
    }

    handleChange = (type, uploadObject) => {
        if (checkValidFileSize(uploadObject.file)) {
            if (type === 'profile') {
                FileUpload({ file: uploadObject?.file, filePath: FILE_UPLOAD_PATH.USER_STORE_IMAGES })
                    .then((response) => {
                        this.setState({
                            profilePhotoUrl: response,
                            profileFileList: uploadObject.fileList
                        })
                    })
                    .catch(() => { })
            } else if (type === 'cover') {
                FileUpload({ file: uploadObject?.file, filePath: FILE_UPLOAD_PATH.USER_STORE_IMAGES })
                    .then((response) => {
                        this.setState({
                            coverPhotoUrl: response,
                            coverFileList: uploadObject.fileList
                        })
                    })
                    .catch(() => { })
            }
        }
    }

    handlePreview = async (file) => {
        if (!file.url && !file.preview) {
            file.preview = await getBase64(file.originFileObj);
        }
        this.setState({
            previewImage: file.url || file.preview,
            previewOpen: true,
            previewTitle: file.name || file.url.substring(file.url.lastIndexOf('/') + 1)
        })
    };
    handleDivisionSelect = (value) => {
        this.setState({
            township_collection: this.props?.townshipCollectionMap?.[value]
        })
        this.inputRef?.current?.setFieldsValue({ townshipId: null });
    }

    fetchProductCategory = () => {
        const params = {
            page: 0,
            size: 100
        }
        ApiHandler({ url: Api.product_category_filter, requestParams: params })
            .then(response => {
                this.setState({
                    product_category_collection: response.content
                })
            })
            .catch(err => { })
            .finally(() => { })


    }


    render() {
        const { isLoading, profileFileList, coverFileList, previewOpen, previewImage, previewTitle, township_collection, product_category_collection } = this.state
        return (
            <>
                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.store}>Shipper Shop Directory</Link>,
                        },
                        {
                            title: 'Form',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />

                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Shipper Directory Form"}
                    extra={<Link to={CustomPath.shipper_directory}><NJVBackButton /></Link>}
                >
                    <Form
                        ref={this.inputRef}
                        layout="vertical"
                        initialValues={{ statusFlag: 'ACTIVE' }}
                        onFinish={this.saveShipperDirectoryForm}
                    >
                        <Row gutter={[24, 16]}>
                            <Col span={24}>
                                <Space>
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={profileFileList}
                                        multiple={false}
                                        maxCount={1}
                                        action={null}
                                        onPreview={this.handlePreview}
                                        onChange={(obj) => this.handleChange('profile', obj)}
                                        beforeUpload={() => false}

                                    >
                                        {
                                            profileFileList.length >= 1 ? null :
                                                <div>
                                                    <PlusOutlined />
                                                    <div
                                                        style={{
                                                            marginTop: 8,
                                                        }}
                                                    >
                                                        Upload
                                                    </div>
                                                    <div>Profile</div>
                                                </div>}
                                    </Upload>
                                    <Upload
                                        accept={["image/png", "image/jpg", "image/jpeg"]}
                                        listType="picture-card"
                                        fileList={coverFileList}
                                        multiple={false}
                                        maxCount={1}
                                        action={null}
                                        onPreview={this.handlePreview}
                                        onChange={(obj) => this.handleChange('cover', obj)}
                                        beforeUpload={() => false}
                                    >
                                        {coverFileList.length >= 1 ? null :
                                            <div>
                                                <PlusOutlined />
                                                <div
                                                    style={{
                                                        marginTop: 8,
                                                    }}
                                                >
                                                    Upload
                                                </div>
                                                <div>
                                                    Cover
                                                </div>
                                            </div>
                                        }
                                    </Upload>
                                </Space>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="storeName" label={"Shop Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter shop name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="phoneNumber" label={"Phone Number"} rules={[
                                    { required: true, message: '*(requried)' },
                                    { validator: RestrictPhoneNumberValidator }]}>
                                    <Input placeholder={"Enter phone number"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="storeBio" label={"Store Bio"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        showCount
                                        maxLength={200}
                                        style={{ height: 60 }} placeholder={"Enter store bio"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="productCategory" label={"Choose Category"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Choose Business Category"}
                                        size="large"
                                        options={
                                            product_category_collection && product_category_collection.map(category => {
                                                return {
                                                    value: category.id,
                                                    label: category.name,
                                                }
                                            })
                                        }
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select Division"}
                                        onChange={this.handleDivisionSelect}
                                        showSearch
                                        filterOption={NJVSearchFilterOption}
                                        size="large"
                                        options={
                                            this.props?.divisionCollection && this.props?.divisionCollection.map((division) => {
                                                return {
                                                    value: division.id,
                                                    label: division.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select township"}
                                        showSearch
                                        filterOption={NJVSearchFilterOption}
                                        size="large"
                                        options={
                                            township_collection && township_collection.map((township) => {
                                                return {
                                                    value: township.id,
                                                    label: township.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="address" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        showCount
                                        maxLength={200}
                                        style={{ height: 60 }} placeholder={"Enter address"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="contactFacebook" label={"Facebook Page ID"}>
                                    <Input placeholder={"Enter facebook page ID"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="contactMessenger"
                                    label={
                                        <>
                                            Messenger Page ID
                                        </>
                                    }
                                >
                                    <Input placeholder={"Enter messenger page ID"} />
                                </Form.Item>
                                <span style={{ color: Colors.gray, fontStyle: 'italic', marginLeft: 5 }}>(Hint: xxxxxxxxxxxx)</span>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="contactViber"
                                    label={
                                        <>
                                            Viber Phone Number
                                        </>
                                    }>
                                    <Input placeholder={"Enter Viber Phone Number"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="contactTiktok"
                                    label={
                                        <>
                                            Tiktok Profile Link
                                        </>
                                    } >
                                    <Input placeholder={"Enter Tiktok Profile Link"} />
                                </Form.Item>
                                <span style={{ color: Colors.gray, fontStyle: 'italic', marginLeft: 5 }}>(Hint: open Tiktok app  go to Profile → click share profile → click copy link)</span>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="contactWebsite"
                                    label={
                                        <>
                                            Website
                                        </>
                                    }>
                                    <Input placeholder={"Enter website link "} />
                                </Form.Item>
                                <span style={{ color: Colors.gray, fontStyle: 'italic', marginLeft: 5 }}>(Hint: https://yourwebsite.com/)</span>
                            </Col>
                            <Col span={24}>
                                <Form.Item
                                    name="statusFlag">
                                    <Radio.Group>
                                        <Radio value={'ACTIVE'}>Active</Radio>
                                        <Radio value={'INACTIVE'}>Inactive</Radio>
                                    </Radio.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" loading={isLoading}>Create</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(ShipperDirectoryForm)