import { Breadcrumb, Button, Card, Checkbox, Col, Form, Input, Modal, Row, Select, message } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import { AdminPasswordValidator, NumberAndDecimelPointValidatorNullable, PhoneNumberValidator } from "../../../components/validator";
import Api from "../../../network/api";
import { HTTP_METHOD } from "../../../network/httpMethod";
import { MEDIA_TYPE } from "../../../network/mediaType";
import { ApiGet, ApiHandler } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";
import { NJVBackButton } from "../../../components/core-component";

const { TextArea } = Input
class PartnershipAdminUpdate extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            parentData: props.location.state?.data || {},
            data: [],
            roleData: [],
            storeAccount: {},
            previewImage: null,
            previewTitle: null,
            fileList: [],
            isLoading: false,
            previewOpen: false, division_collection: [],
            township_collection_map: {},
            township_collection: []
        };
        this.inputRef = React.createRef()
    }

    componentDidMount() {
        this.fetchAllDivision()

    }

    fetchAllDivision = async () => {
        try {
            const response = await ApiHandler({ url: Api.division_get_all, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON })
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                }, () => this.fetchData(townshipMap))
            }
        } catch (error) {
        }
    }

    fetchData = async (township_map) => {
        try {
            const response = await ApiGet(Api.partnership_store, this.props.params.id, this.props)
            if (response) {
                this.setState(prevState => ({
                    data: response,
                }));
                let partnershipStore = { ...response }

                if (response.userStatus === 'ACTIVE') {
                    partnershipStore = { ...partnershipStore, active: true }
                } else {
                    partnershipStore = { ...partnershipStore, active: false }
                }
                if (partnershipStore.accounts) {
                    partnershipStore.accounts.map((account) => {
                        if (account.accountType === "UNIQUE_ID") {
                            partnershipStore = { ...partnershipStore, primaryPhoneNumber: account.primaryPhoneNumber, password: account.password }
                            this.setState({
                                storeAccount: account
                            })
                        }
                    })
                }
                if (partnershipStore.township) {
                    let township_collection = township_map[partnershipStore.township.division.id]
                    partnershipStore = {
                        ...partnershipStore,
                        divisionId: partnershipStore.township.division ? partnershipStore.township.division.id : null,
                        townshipId: partnershipStore.township ? partnershipStore.township.id : null,
                    }
                    this.setState({
                        township_collection: township_collection
                    })
                }


                this.inputRef.current.setFieldsValue(partnershipStore);
            }
        } catch (error) {
        }
    }

    updateGender = (event) => {
        this.setState({
            gender: event.target.value
        })
    }

    updatePartnershipStore = async (values) => {
        const { storeAccount, data } = this.state
        this.setState({
            isLoading: true
        })
        try {
            if (!values.latitude && values.longitude) {
                message.error("Enter latitude")
                return
            } else if (values.latitude && !values.longitude) {
                message.error("Enter longitude")
                return
            }
            let requestData = {
                'id': data.id,
                'firstName': values.firstName,
                'fullName': values.firstName,
                'specialCode': values.specialCode,
                'userStatus': values.active ? "ACTIVE" : "INACTIVE",
                'address1': values.address1,
                'phoneNumber': values.primaryPhoneNumber,
                'latitude': values.latitude ? values.latitude : null,
                'longitude': values.longitude ? values.longitude : null,
                'township': {
                    'id': values.townshipId,
                },
                "accounts": [
                    {
                        "id": storeAccount.id,
                        "loginId": values.specialCode,
                        "primaryPhoneNumber": values.primaryPhoneNumber,
                        "password": values.password
                    }
                ],
            }
            const response = await ApiHandler({ url: Api.partnership_store, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData })
            if (response.status === 200) {
                this.gotoStore()
            }
        } catch (error) {

        }
        this.setState({
            isLoading: false
        })
    }

    disabledDate = (current) => {
        const currentDate = dayjs();
        const eighteenYearsAgo = currentDate.subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        const date = dayjs();
        return date.subtract(18, 'year');
    }

    gotoStore = () => {
        const { parentData } = this.state
        let data = {
            id: parentData.partnershipAdminId,
            name: parentData.partnershipAdminName
        }
        this.props.navigate(
            CustomPath.partnership_store,
            { state: { data: data } }
        );
    }


    render() {
        const { isLoading, previewOpen, previewImage, previewTitle, division_collection, township_collection } = this.state
        return (
            <>

                <Modal
                    open={previewOpen}
                    title={previewTitle}
                    footer={null}
                    onCancel={() => this.setState({ previewOpen: false })}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={previewImage}
                    />
                </Modal>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.partnership_admin}>Partnership Admin</Link>,
                        },
                        {
                            title: <Link to={CustomPath.partnership_store}>Store</Link>,
                        },
                        {
                            title: 'Update',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Partnership store update"}
                    extra={<NJVBackButton handleClick={() => this.gotoStore()} />}
                >

                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        onFinish={this.updatePartnershipStore}>
                        <Row gutter={[16, 16]}>
                            <Col span={12} >
                                <Form.Item name="firstName" label={"Store Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter Store name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    name="primaryPhoneNumber"
                                    label={"Phone Number"}
                                    rules={[
                                        { required: true, message: '*(requried)' },
                                        { validator: PhoneNumberValidator }
                                    ]}>
                                    <Input placeholder={"09xxxxxxxxx"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="specialCode" label={"Store Code"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter store code"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="password" label={"Password"}
                                    rules={[{ validator: AdminPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" autocomplete="new-password" />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select Division"}
                                        onChange={this.handleDivisionSelect}
                                        size="large"
                                        options={
                                            division_collection && division_collection.map((division) => {
                                                return {
                                                    value: division.id,
                                                    label: division.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select township"}
                                        onChange={this.handleTownshipSelect}
                                        size="large"
                                        options={
                                            township_collection && township_collection.map((township) => {
                                                return {
                                                    value: township.id,
                                                    label: township.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={24} >
                                <Form.Item name="address1" label={"Store Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter store address"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="latitude" label={"Latitude"} rules={[{ validator: NumberAndDecimelPointValidatorNullable }]}>
                                    <Input placeholder={"Enter lattitude"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="longitude" label={"Longitude"} rules={[{ validator: NumberAndDecimelPointValidatorNullable }]}>
                                    <Input placeholder={"Enter longitude"} />
                                </Form.Item>
                            </Col>

                            <Col span={24}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>

                        <Form.Item style={{ textAlign: 'end' }}>
                            {/* <Button style={{ marginRight: '20px' }}>Reset</Button> */}
                            <Button type="primary" htmlType="submit" loading={isLoading}>Update</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(PartnershipAdminUpdate)