import { Breadcrumb, Button, Card, Checkbox, Col, DatePicker, Form, Image, Input, Row, Select } from "antd";
import dayjs from 'dayjs';
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVBackButton, NJVButton, NJVSearchFilterOption } from "../../../components/core-component";
import { FormLabelOptional } from "../../../components/general-component";
import Theme, { Colors } from "../../../components/theme";
import { NRCValidator, NumberOnlyForOptionalValidator, PhoneNumberValidator, ShipperPasswordValidator } from "../../../components/validator";
import { MENU_NAME, nrcCode_map, nrcNo_collection, payment_type_collection } from "../../../core/constant";
import Api from "../../../network/api";
import { ApiFilter, ApiGet, ApiPut } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";

const { Option } = Select
const { TextArea } = Input

class ShipperUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
            shipperAccount: {},
            roleData: [],
            division_collection: [],
            township_collection_map: {},
            township_collection: [],
            product_category_collection: [],
            nrcDivisionNumber: null,
            nrctownshipCode: null,
            nrcCitizen: null,
            townshipCode_collection: [],
            is_loading: false,
            disableEditing: true
        };
        this.inputRef = React.createRef()

    }

    componentDidMount() {
        this.fetchAllDivision()
        this.fetchProductCategory()
    }

    fetchAllDivision = async () => {
        try {
            const response = await ApiFilter(Api.division_get_all, null, this.props)
            if (response) {
                let divisionList = []
                let townshipMap = {}
                response.map(division => {
                    divisionList.push(division)
                    townshipMap[division.id] = division.townshipDTOList
                })
                this.setState({
                    division_collection: divisionList,
                    township_collection_map: townshipMap
                })
                this.fetchData(townshipMap)
            }
        } catch (error) {
        }
    }

    fetchProductCategory = async () => {
        try {
            var params = {
                page: 0,
                size: 100
            }
            const response = await ApiFilter(Api.product_category_filter, params, this.props)
            if (response && response.content) {
                this.setState({
                    product_category_collection: response.content
                })
            }
        } catch (error) { }


    }
    handleOnCodeChange = (value) => {
        this.setState({
            townshipCode_collection: nrcCode_map[value],
            nrctownshipCode: null,
            nrcDivisionNumber: value
        })
    }
    handleOnTownShipCodeChange = (value) => {
        this.setState({
            nrctownshipCode: value
        })
    }

    handleDivisionSelect = (value) => {
        const { township_collection_map } = this.state
        this.setState({
            township_collection: township_collection_map[value],
        })
        this.inputRef.current.setFieldsValue({ townshipId: null });
    }
    fetchData = async (township_map) => {
        try {

            const response = await ApiGet(Api.shipper, this.props.params.id, this.props)
            if (response) {
                this.setState({
                    data: response
                })
                let shipper;
                if (response.dateOfBirth) {
                    const dob = dayjs(response.dateOfBirth, 'YYYY-MM-DD');
                    shipper = { ...response, dateOfBirth: dob }
                } else {
                    shipper = { ...response }
                }
                if (response.userStatus === 'ACTIVE') {
                    shipper = { ...shipper, active: true }
                } else {
                    shipper = { ...shipper, active: false }
                }
                if (shipper.accounts) {
                    shipper.accounts.map((account) => {
                        if (account.accountType === "PHONE") {
                            shipper = { ...shipper, primaryPhoneNumber: account.primaryPhoneNumber, password: account.password }
                            this.setState({
                                shipperAccount: account
                            })
                        }
                    })
                }
                if (shipper.business) {
                    let nrcNo = ''
                    if (shipper.business.businessOwnerNRC && shipper.business.businessOwnerNRC.includes('/') && shipper.business.businessOwnerNRC.includes('(') && shipper.business.businessOwnerNRC.includes(')')) {
                        const nrc = shipper.business.businessOwnerNRC.split('/');
                        const p2 = nrc[1].split(')')
                        const p3 = nrc[1].split('(')
                        const p4 = p3[1].split(')')
                        nrcNo = p2[1]
                        let township_code_list = []
                        if (nrc[0]) {
                            township_code_list = nrcCode_map[nrc[0]]
                        }
                        this.setState({
                            nrcDivisionNumber: nrc[0],
                            nrcCitizen: p4[0],
                            nrctownshipCode: p3[0],
                            townshipCode_collection: township_code_list
                        })
                    }
                    shipper = {
                        ...shipper,
                        nrcNo: nrcNo,
                        ecommerceRegNumber: shipper.business.ecommerceRegNumber,
                        businessName: shipper.business.businessName,
                        businessOwnerNRC: shipper.business.businessOwnerNRC,
                        facebookPageLink: shipper.business.facebookPageLink,
                        tiktokUserId: shipper.business.tiktokUserId,
                        accountOwnerName: shipper.business.accountOwnerName,
                        paymentType: shipper.business.paymentType,
                        productCategory: shipper.business.productCategory ? shipper.business.productCategory.id : '',
                    }
                    if (this.props.roleType && (this.props.roleType === 'ADMIN' || this.props.roleType === 'BUSINESS_ADMIN' || this.props.roleType === 'SM_BANK_ACCOUNT_CHECKER' || this.props.roleType === 'SM_COMMINITY')) {
                        shipper = {
                            ...shipper,
                            paymentNumber: shipper.business.paymentNumber
                        }
                        this.setState({
                            disableEditing: false
                        })
                    }
                }

                if (shipper.township) {
                    let township_collection = township_map[shipper.township.division.id]
                    shipper = {
                        ...shipper,
                        divisionId: shipper.township.division ? shipper.township.division.id : null,
                        townshipId: shipper.township ? shipper.township.id : null,
                    }
                    this.setState({
                        township_collection: township_collection
                    })
                }
                this.setState({
                    shipper
                })
                this.inputRef.current.setFieldsValue(shipper);
            }


        } catch (error) {

        }

    }

    updateGender = (event) => {
        this.setState({
            gender: event.target.value
        })
    }

    updateShipper = async (values) => {
        const { shipperAccount, data, nrcDivisionNumber, nrctownshipCode, nrcCitizen } = this.state
        this.setState({
            is_loading: true
        })
        try {
            const response = await ApiPut(Api.shipper, data.id,
                {
                    "id": data.id,
                    "firstName": values.firstName,
                    "lastName": values.lastName,
                    "dateOfBirth": values.dateOfBirth ? values.dateOfBirth.format("YYYY-MM-DD") : null,
                    "address1": values.address1,
                    "note": values.note,
                    "osGuid": values.osGuid,
                    "phoneNumber": values.primaryPhoneNumber,
                    'emailAddress': values.emailAddress,
                    "gender": values.gender ? values.gender : null,
                    "userStatus": values.active ? "ACTIVE" : "INACTIVE",
                    "salePersonCode": values.salePersonCode,
                    "ninjaCoreOldShipperId": values.ninjaCoreOldShipperId,
                    "accounts": [
                        {
                            "id": shipperAccount.id,
                            "loginId": values.primaryPhoneNumber,
                            "primaryPhoneNumber": values.primaryPhoneNumber,
                            "password": values.password,
                            "accountType": "PHONE"
                        }
                    ],
                    'business': {
                        'id': data.business && data.business.id ? data.business.id : null,
                        'businessName': values.businessName,
                        'ecommerceRegNumber': values.ecommerceRegNumber,
                        'businessOwnerNRC': (nrcDivisionNumber && nrctownshipCode && nrcCitizen && values.nrcNo) ? nrcDivisionNumber + "/" + nrctownshipCode + "(" + nrcCitizen + ")" + values.nrcNo : null,
                        'facebookPageLink': values.facebookPageLink,
                        'tiktokUserId': values.tiktokUserId,
                        'accountOwnerName': values.accountOwnerName,
                        'paymentType': values.paymentType,
                        'paymentNumber': values.paymentNumber,
                        'productCategory': {
                            'id': values.productCategory
                        }
                    },
                    'township': {
                        'id': values.townshipId
                    }
                }, this.props
            )
            if (response.status === 200) {
                this.props.navigate(CustomPath.shipper)
            }
        } catch (error) {
        }
        this.setState({
            is_loading: false
        })
    }

    disabledDate = (current) => {
        const currentDate = dayjs();
        const eighteenYearsAgo = currentDate.subtract(18, 'year');
        return current && current > eighteenYearsAgo;
    };

    currentDate = () => {
        const date = dayjs();
        return date.subtract(18, 'year');
    }

    render() {
        const { is_loading, division_collection, township_collection, product_category_collection, nrcCitizen, townshipCode_collection, nrcDivisionNumber, nrctownshipCode, disableEditing, shipper } = this.state
        const prevRoute = this.props.location.state?.route;

        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.shipper}>Shipper</Link>,
                        },
                        {
                            title: 'Update',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Shipper Form Update"}
                    extra={
                        <Link to={prevRoute} relative="path">
                            <NJVBackButton />
                        </Link>
                    }
                >

                    <Form
                        layout="vertical"
                        ref={this.inputRef}
                        onFinish={this.updateShipper}>
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item name="uniqueId" label={"Shipper unique id"}>
                                    <Input placeholder={"Enter unique id"} disabled={true} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="osGuid" label={"Invoice Id"}>
                                    <Input placeholder={"Enter invoice id"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="firstName" label={"First Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input placeholder={"Enter first name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="lastName" label={"Last Name"}>
                                    <Input placeholder={"Enter last name"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="primaryPhoneNumber" label={"Phone Number"} rules={[{ required: true, message: '*(requried)' }, { validator: PhoneNumberValidator }]}>
                                    <Input placeholder={"09xxxxxxxxx"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="emailAddress" label={"Email Address"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input size="large" placeholder={"example@gmail.com"} />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="password" label={"Password"} rules={[{ validator: ShipperPasswordValidator }]} style={{ cursor: 'pointer' }}>
                                    <Input.Password size="small" placeholder="Enter password" autocomplete="new-password" />
                                </Form.Item>
                            </Col>
                            <Col span={12} >
                                <Form.Item name="gender" label={"Gender"}>
                                    <Select
                                        size="large"
                                        allowClear={true}
                                        style={{
                                            width: '100%',
                                        }}
                                        placeholder="Select gender"
                                        options={[
                                            {
                                                label: 'Male',
                                                value: 'MALE',
                                            },
                                            {
                                                label: 'Female',
                                                value: 'FEMALE',
                                            },
                                        ]}
                                    />
                                </Form.Item>

                            </Col>
                            <Col span={12} >
                                <Form.Item name="dateOfBirth" label={"Date Of Birth"} style={{ cursor: 'pointer' }} className="custom-date-picker">
                                    <DatePicker
                                        disabledDate={this.disabledDate}
                                        allowClear={true}
                                        size="large" style={{ width: '100%', cursor: 'pointer' }} format={'YYYY-MM-DD'} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>

                                <Form.Item name="divisionId" label={"Division/State"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select Division"}
                                        onChange={this.handleDivisionSelect}
                                        size="large"
                                        options={
                                            division_collection && division_collection.map((division) => {
                                                return {
                                                    value: division.id,
                                                    label: division.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>

                            <Col span={12}>
                                <Form.Item name="townshipId" label={"Township/City"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Select Division"}
                                        onChange={this.divisionSelectorChange}
                                        size="large"
                                        options={
                                            township_collection && township_collection.map((township) => {
                                                return {
                                                    value: township.id,
                                                    label: township.name,
                                                    type: 'division'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}></Col>
                            <Col span={12}>
                                <Form.Item name="address1" label={"Address"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter address"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="note" label={"Note"}>
                                    <TextArea
                                        style={{ height: 60 }} placeholder={"Enter note"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <h4>Business Information</h4>

                            </Col>
                            <Col span={12}>
                                <Form.Item name="businessName" label={"Business Name"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input size="large" placeholder={"Fashion House"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="ecommerceRegNumber" label={"E-commerce Registration Number"} size={'large'}>
                                    <Input size="large" placeholder={"1234343"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="facebookPageLink" label={"Business Facebook Page Link"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="tiktokUserId" label={"Tiktok Profile Link"} size={'large'}>
                                    <Input size="large" />
                                </Form.Item>
                                <span style={{ color: Colors.gray, fontStyle: 'italic', marginLeft: 5 }}>(Hint: open Tiktok app  go to Profile → click share profile → click copy link)</span>
                            </Col>
                            <Col span={12}>
                                <Row gutter={16}>
                                    <Col span={24}>
                                        <FormLabelOptional label={"Business Owner NRC"} />
                                    </Col>
                                    <Col span={4}>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder={"1"}
                                            onChange={this.handleOnCodeChange}
                                            size="large"
                                            value={nrcDivisionNumber}
                                            options={nrcNo_collection}
                                        />
                                    </Col>
                                    <Col span={6}>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder={"LAYANA"}
                                            onChange={this.handleOnTownShipCodeChange}
                                            showSearch
                                            filterOption={NJVSearchFilterOption}
                                            value={nrctownshipCode}
                                            size="large"
                                            options={
                                                townshipCode_collection && townshipCode_collection.map((code, index) => {
                                                    return {
                                                        key: index,
                                                        value: code.value,
                                                        label: code.value,
                                                    }
                                                })
                                            }
                                        />
                                    </Col>
                                    <Col span={4}>
                                        <Select
                                            style={{ width: '100%' }}
                                            placeholder={"N"}
                                            value={nrcCitizen}
                                            onChange={(value) => this.setState({ nrcCitizen: value })}
                                            size="large"
                                        >
                                            <Option value={"N"}>N</Option>
                                            <Option value={"F"}>F</Option>
                                            <Option value={"P"}>P</Option>
                                            <Option value={"T"}>T</Option>
                                            <Option value={"E"}>E</Option>
                                        </Select>
                                    </Col>
                                    <Col span={10}>
                                        <Form.Item name="nrcNo" size={'large'}
                                            rules={[
                                                { validator: NRCValidator }
                                            ]}>
                                            <Input size="large" placeholder={"123456"} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Col>


                            <Col span={12}>
                                <Form.Item name="productCategory" label={"Choose Business Category"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Choose Business Category"}
                                        size="large"
                                        options={
                                            product_category_collection && product_category_collection.map(category => {
                                                return {
                                                    value: category.id,
                                                    label: category.name,
                                                }
                                            })
                                        }
                                    >
                                    </Select>
                                </Form.Item>
                            </Col>
                            <Col span={12} />
                            <Col span={12}>
                                <Form.Item name="accountOwnerName" label={"Account Owner Name"} size={'large'} rules={[{ required: true }]}>
                                    <Input size="large" />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="paymentType" label={"Primary Payment"} size={'large'} rules={[{ required: true, message: '*(requried)' }]}>
                                    <Select
                                        className="my-location-selector"
                                        placeholder={"Choose Primary Payment"}
                                        size="large"
                                        options={
                                            payment_type_collection && payment_type_collection.map((payment) => {
                                                return {
                                                    value: payment.key,
                                                    label: payment.value,
                                                    type: 'primaryPayment'
                                                }
                                            })
                                        }
                                    />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="paymentNumber" label={"Primary Account Number"} size={'large'} rules={[{ validator: NumberOnlyForOptionalValidator }]}>
                                    <Input size="large" disabled={disableEditing} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="ninjaCoreOldShipperId" label={"Ninja Van Old Shipper Id"}>
                                    <Input placeholder={"Enter ninja core old shipper id"} />
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item name="salePersonCode" label={"Sale Person Code"}>
                                    <Input placeholder={"Enter sale person code"} disabled={true} />
                                </Form.Item>
                            </Col>
                            {
                                !disableEditing && <Col span={12}>
                                    <Form.Item name="bankBookImg" label={"Bank Account Number Photo"}>
                                        {shipper?.business.bankBookImg ? <Image width={100} src={shipper?.business.bankBookImg} /> : <div style={{ width: 200, height: 100, backgroundColor: '#f0f0f0', borderRadius: 8, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>No Image</div>}
                                    </Form.Item>
                                </Col>
                            }
                            <Col span={24}>
                                <Form.Item
                                    name="active"
                                    valuePropName="checked">
                                    <Checkbox>Active</Checkbox>
                                </Form.Item>
                            </Col>
                        </Row>


                        {/* {
                                        roleData && roleData.length != 0 ?
                                            <Col span={24}>
                                                <Form.Item name="roles" label={"Role"}>
                                                    <Checkbox.Group
                                                        style={{
                                                            width: '100%',
                                                        }}
                                                    >
                                                        <Row>
                                                            {
                                                                roleData.map((value) =>
                                                                    <Col span={24} style={{ marginBottom: 5 }}>
                                                                        <Checkbox value={value.id}>{value.roleType}</Checkbox>
                                                                    </Col>
                                                                )

                                                            }
                                                        </Row>
                                                    </Checkbox.Group>

                                                </Form.Item>
                                            </Col>
                                            :
                                            <></>
                                    } */}


                        <Form.Item style={{ textAlign: 'end' }}>
                            {/* <NJVAuthActionButton buttonName={"Update"} type="primary" htmlType="submit" loading={is_loading} menuName={MENU_NAME.SHIPPER} /> */}
                            <NJVButton type="primary" text="Update" htmlType="submit" loading={is_loading} menuName={MENU_NAME.SHIPPER} />
                            {/* <Button type="primary" htmlType="submit" loading={is_loading}>Update</Button> */}
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(ShipperUpdate)