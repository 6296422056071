import { EditOutlined } from "@ant-design/icons";
import { Breadcrumb, Button, Card, Col, Drawer, Form, Row, Skeleton, Table } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVAddButton, NJVBackButton, NJVInput } from "../../components/core-component";
import { TitleLevel3 } from "../../components/general-component";
import Theme, { Colors } from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";


class DepartmentUpdate extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            departmentData: {},
            teamData: [],
            faqTypes: [],
            teamUpdateId: null,
            isUpdating: false,
            open_team_create_drawer: false,
            open_team_update_drawer: false,
        };
        this.createRef = React.createRef()
        this.updateRef = React.createRef()
    }

    componentDidMount() {
        this.fetchAllData()
    }

    fetchAllData = async () => {
        try {
            await Promise.all([
                this.fetchDepartment(),
                this.fetchData(),
                // this.fetchFaqType()
            ]);
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    }

    fetchDepartment = async () => {
        await ApiHandler({ url: Api.department, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id })
            .then(response => {
                this.setState({
                    departmentData: response
                })
            })
            .catch(error => { })
            .finally(() => { })
    }

    fetchData = async () => {
        await ApiHandler({ url: Api.team_filter, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id })
            .then(response => {
                this.setState({
                    teamData: response
                })
            })
            .catch(error => { })
            .finally(() => { })

    }


    createTeam = async (formData) => {
        let { teamData } = this.state
        this.setState({ loading: true })
        const requestData = {
            ...formData,
            "department": {
                "id": this.props.params.id
            }
        }
        await ApiHandler({ url: Api.team, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData })
            .then(response => {
                teamData.push(response?.data)
                this.createRef?.current?.setFieldsValue({ name: null, description: null })
                this.setState({
                    teamData,
                    open_team_create_drawer: false
                })
            })
            .catch(err => { })
            .finally(() => {
                this.setState({ loading: false })
            })

    }



    updateTeam = async (formData) => {
        let { teamData, teamUpdateId } = this.state
        this.setState({ isUpdating: true })
        const requestData = {
            ...formData,
            id: teamUpdateId
        }

        await ApiHandler({ url: Api.team, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON, requestData, specificId: teamUpdateId })
            .then(response => {
                let teamDataTemp = []
                teamData.forEach(team => {
                    if (team.id === response?.data?.id) {
                        team = response.data
                    }
                    teamDataTemp.push(team)
                })
                this.setState({
                    teamData: teamDataTemp,
                    open_team_update_drawer: false
                })
            })
            .catch(err => { })
            .finally(() => {
            })
    }

    handleTeamUpdate = (data) => {
        this.setState({ open_team_update_drawer: true, teamUpdateId: data.id }, () => {
            this.updateRef?.current?.setFieldsValue({
                id: data?.id,
                name: data?.name,
                description: data?.description
            })
        })
    }

    render() {
        const { teamData, isDataFetching, open_team_create_drawer, open_team_update_drawer, departmentData } = this.state
        const getRowClassName = (record, index) => {
            return index % 2 === 0 ? 'blue-row' : 'black-row';
        };
        const columns = [
            {
                title: 'No.',
                key: 'index',
                render: (value, item, index) => <span>{index + 1}</span>,
                width: 100
            },
            {
                title: 'Name',
                dataIndex: 'name',
                key: 'name',
                width: 250
            },
            {
                title: 'Description',
                dataIndex: 'description',
                key: 'description',
            },
            {
                title: 'Action',
                key: 'action',
                dataIndex: '',
                textAlign: 'center',
                width: 60,
                render: (team) => (
                    <Button size={'middle'} type="primary" shape="circle" onClick={() => this.handleTeamUpdate(team)}><EditOutlined /></Button>
                ),
            },
        ]
        return (
            <>
                <Drawer
                    open={open_team_create_drawer}
                    title={"Team Form"}
                    onClose={() => this.setState({ open_team_create_drawer: false })}>
                    <Form
                        layout="vertical"
                        ref={this.createRef}
                        initialValues={{ active: true }}
                        onFinish={this.createTeam}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name="name" label={"Team Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <NJVInput bgcolor={Theme.colors.input_bg_color} placeholder={"Enter team name"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="description" label={"Description"}>
                                    <NJVInput bgcolor={Theme.colors.input_bg_color} placeholder={"Enter description"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Update</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
                <Drawer
                    open={open_team_update_drawer}
                    title={"Team Update"}
                    onClose={() => this.setState({ open_team_update_drawer: false })}>
                    <Form
                        layout="vertical"
                        ref={this.updateRef}
                        initialValues={{ active: true }}
                        onFinish={this.updateTeam}>
                        <Row gutter={16}>
                            <Col span={24}>
                                <Form.Item name="name" label={"Team Name"} rules={[{ required: true, message: '*(requried)' }]}>
                                    <NJVInput bgcolor={Theme.colors.input_bg_color} placeholder={"Enter team name"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item name="description" label={"Description"}>
                                    <NJVInput bgcolor={Theme.colors.input_bg_color} placeholder={"Enter description"} />
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit">Update</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </Drawer>
                <Row gutter={[16, 16]}>
                    <Col span={12}>
                        <Breadcrumb
                            items={[
                                {
                                    title: 'Home',
                                },
                                {
                                    title: <Link to={CustomPath.department}>Department</Link>,
                                },
                                {
                                    title: 'Team Management',
                                }
                            ]}
                        /></Col>
                    <Col span={12} style={{ textAlign: 'right' }}>
                        <Link to={CustomPath.department}><NJVBackButton /></Link>
                    </Col>
                    <Col span={24}>
                        <Card className="card-shadow">
                            <div style={{ fontSize: 14, color: Colors.gray }}>Department</div>
                            <div style={{ fontSize: 18, fontWeight: '600' }}>{departmentData?.name}</div>
                            <div style={{ fontSize: 14, color: Colors.gray, marginTop: 10 }}>Description</div>
                            <div style={{ fontSize: 18, fontWeight: '600' }}>{departmentData?.description}</div>
                        </Card>
                    </Col>

                    <Col span={12} style={{ marginTop: 30 }}>
                        <TitleLevel3 label={"Teams"} />
                    </Col>
                    <Col span={12} style={{ textAlign: 'right', marginTop: 30 }}>
                        <NJVAddButton label='Create Team' handleClick={() => this.setState({ open_team_create_drawer: true })} />
                    </Col>
                    <Col span={24}>
                        {
                            isDataFetching ?
                                <Skeleton active />
                                :
                                <Table
                                    style={{ borderRadius: 10 }}
                                    pagination={false}
                                    columns={columns} dataSource={teamData}
                                    rowClassName={getRowClassName} />
                        }
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(DepartmentUpdate)