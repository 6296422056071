import { Breadcrumb, Button, Card, Form, Input } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import { NJVBackButton } from "../../components/core-component";
import Theme from "../../components/theme";
import Api from "../../network/api";
import { HTTP_METHOD } from "../../network/httpMethod";
import { MEDIA_TYPE } from "../../network/mediaType";
import { ApiHandler } from "../../network/network-manager";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class ProductCategoryUpdate extends React.Component {


    constructor(props) {
        super(props);
        this.state = {
            data: [],
        };
        this.updateRef = React.createRef()
    }

    componentDidMount() {
        this.fetchData()
    }

    fetchData = async () => {
        try {
            const response = await ApiHandler({ url: Api.product_category, method: HTTP_METHOD.GET, mediaType: MEDIA_TYPE.JSON, specificId: this.props.params.id })
            if (response) {
                this.setState({
                    data: response
                })
                this.updateRef.current.setFieldsValue(response);
            }
        } catch (error) {

        }
    }

    updateProductCategory = async (values) => {
        const { data } = this.state
        try {
            const response = await ApiHandler({
                url: Api.product_category, method: HTTP_METHOD.PUT, mediaType: MEDIA_TYPE.JSON,
                requestData: {
                    "name": values.name,
                    "description": values.description,
                }, specificId: data.id
            })
            if (response.status == 200) {
                this.props.navigate(CustomPath.product_category)
            }
        } catch (error) {

        }
    }

    render() {
        return (
            <>
                <Breadcrumb
                    items={[
                        {
                            title: 'Home',
                        },
                        {
                            title: <Link to={CustomPath.productCategory}>Product Category</Link>,
                        },
                        {
                            title: 'Update',
                        }
                    ]}
                    style={{ marginBottom: "20px" }}
                />
                <Card
                    style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none', marginBottom: "20px" }}
                    bordered={false}
                    title={"Product Category Update"}
                    extra={<Link to={CustomPath.product_category}><NJVBackButton /></Link>}
                >
                    <Form
                        layout="vertical"
                        ref={this.updateRef}
                        initialValues={{ active: true }}
                        onFinish={this.updateProductCategory}>

                        <Form.Item name="name" label={"Product Category Name"} rules={[{ required: true, message: '*(requried)' }]}>
                            <Input placeholder={"Enter product category name"} />
                        </Form.Item>

                        <Form.Item name="description" label={"Description"}>
                            <Input placeholder={"Enter description"} />
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'end' }}>
                            <Button style={{ marginRight: '20px' }}>Reset</Button>
                            <Button type="primary" htmlType="submit">Update</Button>
                        </Form.Item>
                    </Form>
                </Card>
            </>
        );
    }
}

export default compose(withRouter)(ProductCategoryUpdate)