import { Breadcrumb, Button, Card, Col, Form, Input, List, Row } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import { compose } from "redux";
import Theme from "../../../components/theme";
import Api from "../../../network/api";
import { ApiFilter, ApiGet, ApiPost } from "../../../network/network-manager";
import withRouter from "../../../network/with-router";
import CustomPath from "../../../routes/custom-path";
import { NJVBackButton } from "../../../components/core-component";

class PricingGroupZoneSameCityPriceSetup extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            primaryData: props.location.state?.data || {},
            data: [],
            township_collection: [],
            township_price_map: {},

            is_data_saving: false

        };
    }

    componentDidMount() {
        this.fetchPriceData()
    }

    fetchPriceData = async () => {
        const { primaryData } = this.state
        try {
            const param = {
                'zoneId': primaryData.zoneId,
                'pricingGroupId': primaryData.pricingGroupId
            }
            const response = await ApiFilter(Api.get_townships_with_zone_id_and_pricing_group_id, param)
            if (response) {
                let map = {}
                response.map((townshipPrice => {
                    map[townshipPrice.townshipId] = townshipPrice
                }))
                this.setState({
                    township_price_map: map
                }, () => this.fetchData())
            }
        } catch (error) {
        }
    }

    fetchData = async () => {
        const { primaryData, township_price_map } = this.state
        try {
            const response = await ApiGet(Api.zone, primaryData.zoneId)
            if (response) {
                let townshipCollection = []
                response.zoneTownships.map(zoneTownship => {
                    if (township_price_map[zoneTownship.townshipId] != undefined) {
                        zoneTownship.price = township_price_map[zoneTownship.townshipId].price
                    } else {
                        zoneTownship.price = 0
                    }
                    townshipCollection.push(zoneTownship)
                })
                this.setState({
                    data: response,
                    township_collection: townshipCollection
                })
            }
        } catch (error) {
        }
    }


    saveZone = async () => {
        const { township_collection, primaryData, township_price_map } = this.state
        
        this.setState({
            is_data_saving: true
        })
        try {
            let townshipPricePostCollection = []
            township_collection.map((townshipPrice) => {
                let townshipPriceId = null
                if (township_price_map[townshipPrice.townshipId]) {
                    townshipPriceId = township_price_map[townshipPrice.townshipId].id
                }
                townshipPricePostCollection.push({
                    "id": townshipPriceId,
                    "zoneId": primaryData.zoneId,
                    "pricingGroup": {
                        "id": primaryData.pricingGroupId
                    },
                    township: {
                        "id": townshipPrice.townshipId
                    },
                    price: townshipPrice.price
                })
            })
            const response = await ApiPost(Api.update_township_price, {
                "zoneId": primaryData.zoneId,
                "pricingGroupId": primaryData.pricingGroupId,
                "townshipPrices": townshipPricePostCollection
            })
            if (response.status == 200) {
                this.props.navigate(primaryData.pricingGroupRoute)
            }
        } catch (error) {
        }
        this.setState({
            is_data_saving: false
        })
    }

    handleInput = (id, event) => {
        const { township_collection } = this.state
        const price = event.target.value
        const numberPattern = /^[0-9]*\.?[0-9]*$/;
        if (price === null || numberPattern.test(price)) {
            let list = []
            township_collection.map(t => {
                if (id === t.townshipId) {
                    t = { ...t, 'price': price }
                    list.push(t)
                } else {
                    list.push(t)
                }

            })
            this.setState({ township_collection: list })
        }
    }

    render() {
        const { data, township_collection, is_data_saving, primaryData } = this.state
        
        return (
            <>

                <Row>
                    <Col span={6} />
                    <Col span={12}>
                        <Row gutter={24}>
                            <Col span={20}>
                                <Breadcrumb
                                    items={[
                                        {
                                            title: 'Home',
                                        },
                                        {
                                            title: <Link to={primaryData.pricingGroupRoute}>Pricing Group</Link>,
                                        },
                                        {
                                            title: 'Update Same City Price',
                                        }
                                    ]}
                                /></Col>
                            <Col span={4} style={{ textAlign: 'right' }}>
                                <Link to={primaryData.pricingGroupRoute}><NJVBackButton /></Link>
                            </Col>
                            <Col span={24}>
                                <Form
                                    layout="vertical"
                                    onFinish={this.saveZone}>
                                    <Row gutter={[16, 16]}>
                                        <Col span={24} style={{ display: 'flex', justifyItems: 'center' }}>
                                            <h2>{data.zoneName}</h2>
                                            {
                                                data.description ?
                                                    <h2 style={{ marginLeft: 10 }}>( {data.description} )</h2>
                                                    :
                                                    <></>
                                            }
                                        </Col>
                                        <Col span={24}>
                                            <Card
                                                style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
                                                bordered={false}
                                                title={"Township"}
                                            >
                                                <List
                                                    itemLayout="horizontal"
                                                    dataSource={township_collection}
                                                    renderItem={(item, index) => (
                                                        <div className="division-box">
                                                            <Row style={{ height: 50, display: 'flex', alignItems: 'center', paddingLeft: 10, paddingRight: 10 }}>
                                                                <Col span={24} style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                                                                    <div className="location-name">
                                                                        {item.townshipName}
                                                                    </div>
                                                                    <Input value={item.price === 0 ? null : item.price} onChange={(event) => this.handleInput(item.townshipId, event)} placeholder="Enter price" style={{ width: 150 }} />
                                                                </Col>

                                                            </Row>
                                                        </div>
                                                    )}
                                                />
                                            </Card>
                                        </Col>

                                        <Col span={24}>
                                            <Form.Item>
                                                <Button type="primary" htmlType="submit" loading={is_data_saving}>Save</Button>
                                            </Form.Item>
                                        </Col>
                                    </Row>
                                </Form>

                            </Col>
                        </Row>
                    </Col>
                </Row>
            </>
        );
    }
}

export default compose(withRouter)(PricingGroupZoneSameCityPriceSetup)