import { DeleteOutlined } from "@ant-design/icons"
import { compose } from '@reduxjs/toolkit'
import { Button, Card, Col, DatePicker, Form, message, Modal, Row, Table, Tag, Typography } from 'antd'
import dayjs from "dayjs"
import React, { Component } from 'react'
import Webcam from 'react-webcam'
import { v4 as uuidv4 } from 'uuid'
import { NJVInput } from '../../components/core-component'
import { TitleLevel3 } from '../../components/general-component'
import Theme from '../../components/theme'
import { NumberAndDecimalOnly } from "../../components/validator"
import Api from '../../network/api'
import { FILE_UPLOAD_PATH } from '../../network/filePath'
import { HTTP_METHOD } from '../../network/httpMethod'
import { MEDIA_TYPE } from '../../network/mediaType'
import { ApiHandler, downloadExcel } from '../../network/network-manager'
import withRouter from '../../network/with-router'

const { RangePicker } = DatePicker;

const camWidth = 279
const camHeight = 210

class OSWPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      images: [],
      weight: '',
      size: 0,
      openModal: false,
      openDeleteModal: false,
      isUploading: false,
      isUpdatingOSW: false,
      showCapture: false,
      formValues: null,
      filterData: null
    }
    this.formRef = React.createRef();
    this.trackingRef = React.createRef();
    this.sizeRef = React.createRef();
    this.weightRef = React.createRef();
    this.productWebcamRef = React.createRef();
    this.formWeightRef = React.createRef();
    this.photoconstraints = {
      width: 279,
      height: 210,
    }
  }

  componentDidMount() {
    this.weightRef.current?.focus();
    const handleKeyPress = (event) => {
      //Ctrl + C => capture photo
      if (event.ctrlKey && event.key === 'c') {
        event.preventDefault();
        this.handleCapture();
      }
      //Ctrl + S => submit OSW list
      if (event.ctrlKey && event.key === 's') {
        event.preventDefault();
        this.submitOSWList()
      }
    }
    document.addEventListener("keydown", handleKeyPress);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
  }

  base64StringToFile = (base64String, fileName) => {
    const byteCharacters = atob(base64String?.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    const blob = new Blob([byteArray], { type: 'image/jpeg' });
    return new File([blob], fileName, { type: 'image/jpeg' });
  };

  handleCapture = () => {
    this.canvasCapture(this.productWebcamRef, 'productImg')
  }

  canvasCapture = (webcamRef, type) => {
    const imageSrc = webcamRef.current.getScreenshot();
    const fileName = 'image.jpg';
    const imageFile = this.base64StringToFile(imageSrc, fileName);

    if (type === 'productImg') {
      this.setState({
        isUploading: true
      })
      const formData = new FormData();
      formData.append('file', imageFile);
      formData.append('filePath', FILE_UPLOAD_PATH.PARCEL_OSW_UPDATE)
      ApiHandler({ url: Api.file_upload, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.FORM_DATA, requestData: formData, disableShowMessage: true })
        .then((response) => {
          this.setState((prev) => ({ images: [...prev.images, response.data] }),
            () => {
              this.formRef.current.setFieldsValue({ filePathImages: this.state.images })
            }
          );
        }).catch((err) => { })
        .finally(() => {
          this.setState({
            isUploading: false
          })
        })
    }
  };

  handleChange = (key, value) => {
    if (key === 'trackingId') {
      if (value.length > 5) {
        setTimeout(() => {
          if (this.sizeRef?.current) {
            this.sizeRef.current?.focus();
          }
        }, 2000);
      }
    }
    if (key === 'weight') {
      const intValue = parseFloat(value)
      NumberAndDecimalOnly(null, intValue).then(() => {
        this.setState({
          weight: value,
          openModal: true,
          showCapture: true
        }, () => {
          this.formRef?.current?.setFieldsValue({ weight: value })
        })
        setTimeout(() => {
          this.trackingRef.current?.focus();
        }, 1000);
      }).catch((err) => {
        message.destroy();
        message.error(err);
      })
    }
    if (key === 'size') {
      NumberAndDecimalOnly(null, value).then(() => {
        this.setState({
          size: value
        })
      }).catch((err) => {
        message.destroy();
        message.error(err);
      })
    }
  }

  handleRestField = () => {
    this.setState({
      weight: '',
      openModal: false,
      images: []
    }, () => {
      this.weightRef.current?.focus();
    })
    this.formRef?.current?.resetFields();
  }

  handleConfirmOSW = (values) => {
    const { data } = this.state;
    const duplicateTrackingIndex = data.findIndex((item) => item.trackingId === values.trackingId);
    if (duplicateTrackingIndex !== -1) {
      message.destroy();
      message.error('Duplicate tracking ID');
      return;
    }
    this.setState({
      data: [...data, { ...values, id: uuidv4() }],
    })
    this.handleRestField();
    this.weightRef.current.focus();
  }

  handleClearListItem = () => {
    const { data, currentId } = this.state;
    const newData = data.filter((item) => item.id !== currentId);
    this.setState({
      data: newData,
      openDeleteModal: false
    })
    this.weightRef.current.focus();
  }

  submitOSWList = () => {
    const { data } = this.state;

    if (!Array.isArray(data) || data.length === 0) {
      console.warn("No data available for submit");
      return;
    }

    this.setState({
      isUpdatingOSW: true
    })
    const requestData = data.map(({ weight, size, trackingId, filePathImages }) => ({
      weight: parseFloat(weight),
      size: parseFloat(size),
      trackingNumber: trackingId,
      codUpdateProofImageUrl: filePathImages?.length >= 2
        ? `${filePathImages[0]},${filePathImages[1]}`
        : filePathImages?.length === 1
          ? filePathImages[0]
          : ''
    }));

    ApiHandler({ url: Api.osw_update, method: HTTP_METHOD.POST, mediaType: MEDIA_TYPE.JSON, requestData })
      .then(() => {
        this.setState({
          data: [],
          images: []
        })
      }).catch((err) => { })
      .finally(() => {
        this.setState({
          isUpdatingOSW: false
        })
        this.weightRef.current.focus();
      })
  }

  downloadExcel = async () => {
    const { startDate, endDate, startHour, endHour } = this.state?.filterData
    this.setState({
      downloading_excel: true
    })
    try {
      let params = {
        startDate,
        endDate,
        startHour,
        endHour
      }
      await downloadExcel(Api.osw_excel_export, params, "Updated OSW List")
    } catch (error) {
    }
    this.setState({
      downloading_excel: false,
      filterData: null
    })
  }

  render() {
    const { data, weight, openModal, openDeleteModal, photoconstraints, images, isUploading, isUpdatingOSW, downloading_excel } = this.state;

    const columns = [
      {
        title: 'No',
        key: 'index',
        render: (value, item, index) => {
          return <span>{index + 1}</span>
        },
        width: 5
      },
      {
        title: 'Tracking ID',
        dataIndex: 'trackingId',
        key: 'trackingId',
      },
      {
        title: 'Weight(KG)',
        dataIndex: 'weight',
        key: 'weight',
      },
      {
        title: 'Size(CM)',
        dataIndex: 'size',
        key: 'size',
      },
      {
        title: 'Action',
        dataIndex: 'action',
        width: 100,
        render: (_, record) => <Button type='primary' style={{ background: 'red' }} onClick={() => this.setState({ openDeleteModal: true, currentId: record.id })}>Delete</Button>
      }
    ]

    return (
      <>
        <Modal
          title="Delete"
          open={openDeleteModal}
          onOk={() => this.handleClearListItem()}
          onCancel={() => this.setState({ openDeleteModal: false })}
          okText="Confirm"
          cancelText="Cancel"
        >
          <p>Are you sure to delete?</p>
        </Modal>
        <Modal
          title="OSW Form"
          centered
          open={openModal}
          footer={null}
          onCancel={() => {
            this.handleRestField();
          }}
          afterClose={() => {
            console.log("Modal closed completely.");
          }}
        >
          <Card
            style={{ backgroundColor: Theme.colors.card_bg_color, boxShadow: 'none' }}
            bordered={false}
          >
            <Form ref={this.formRef} layout='vertical' onFinish={this.handleConfirmOSW}>
              <Row gutter={[16, 16]}>
                <Col span={24}>
                  <Form.Item name="trackingId" label="Tracking ID" rules={[{ required: true, message: '*( required )' }]}>
                    <NJVInput ref={this.trackingRef} onChange={(e) => this.handleChange('trackingId', e.target.value)} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="weight" label="Weight(KG)" rules={[{ required: true, message: '*( required )' }]}>
                    <NJVInput ref={this.formWeightRef} disabled />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="size" label="Size(CM)">
                    <NJVInput ref={this.sizeRef} onChange={(e) => this.handleChange('size', e.target.value)} />
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item name="filePathImages" label="Capture Image"
                    rules={[{ required: true, message: '*( required )' }]}
                  >
                    {
                      images.length < 2 ?
                        <>
                          <Webcam
                            audio={false}
                            imageSmoothing
                            width={camWidth}
                            height={camHeight}
                            ref={this.productWebcamRef}
                            screenshotFormat="image/jpeg"
                            photoconstraints={photoconstraints}
                            style={{ borderRadius: '10px', backgroundColor: '#f1f1f1' }}
                          />
                          <br />
                          <Button type="primary" loading={isUploading} onClick={this.handleCapture} style={{ marginBlock: 10 }}>Capture Photo</Button>
                        </> : <></>
                    }
                    <div style={{ display: 'flex', flexWrap: 'wrap', gap: '5px' }}>
                      {
                        images.length >= 1 ?
                          <>
                            {
                              images.map((img, index) => (
                                <div style={{ position: 'relative' }} key={index}>
                                  <img src={img} alt="Captured" width={180} height={140} style={{ borderRadius: '10px' }} />
                                  <Button
                                    icon={<DeleteOutlined />}
                                    type="primary"
                                    style={{
                                      position: 'absolute',
                                      top: '4px',
                                      right: '4px',
                                    }}
                                    onClick={() => {
                                      const newImages = images.filter((_, i) => i !== index);
                                      this.setState({ images: newImages });
                                    }}
                                  />
                                </div>
                              ))
                            }
                          </>
                          : <></>
                      }
                    </div>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Form.Item>
                    <Button
                      block
                      size='large'
                      type="primary"
                      htmlType="submit"
                      disabled={isUploading}
                    >Add to OSW list</Button>
                  </Form.Item>
                </Col>
              </Row>
            </Form>
          </Card>
        </Modal>
        <Row gutter={[16, 16]}>
          <Col span={8} style={{ marginBottom: 10 }}>
            <TitleLevel3 label={"OSW Management"} />
          </Col>
          <Col span={10} style={{ display: 'flex', gap: 20 }}>
            <RangePicker
              value={this.state.filterData?.value}
              showTime={{
                format: 'HH',
              }}
              style={{ width: '100%', background: '#f5f5f5', height: 45 }}
              allowClear
              format="YYYY-MM-DD HH:mm"
              onChange={(value, dateString) => {
                const startDate = dayjs(dateString[0]).format('YYYY-MM-DD')
                const endDate = dayjs(dateString[1]).format('YYYY-MM-DD')
                const startHour = dayjs(dateString[0]).format('HH')
                const endHour = dayjs(dateString[1]).format('HH')

                this.setState({
                  filterData: {
                    value,
                    startDate,
                    endDate,
                    startHour,
                    endHour
                  }
                })
              }}
            />
            <Button type="primary" size="large" disabled={this.state?.filterData === null} onClick={() => this.downloadExcel()} style={{ backgroundColor: this.state?.filterData === null ? '#d9d9d9' : 'green', marginRight: 10 }} loading={downloading_excel}>Export Excel</Button>
          </Col>
          <Col span={6}>
            <Typography.Paragraph style={{ color: Theme.colors.gray, marginBottom: 0, display: 'flex', justifyContent: 'right' }}>
              <ul>
                <li>
                  <Tag>ctrl + s</Tag> ⇒ <span style={{ fontSize: 16, fontWeight: 500 }}>Submit OSW</span>
                </li>
                <li>
                  <Tag>ctrl+c</Tag> ⇒ <span style={{ fontSize: 15, fontWeight: 500 }}>Capture Image</span>
                </li>
                <li>
                  <Tag>enter</Tag> ⇒ <span style={{ fontSize: 15, fontWeight: 500 }}>Add to OSW list</span>
                </li>
                <li>
                  <Tag>esc</Tag> ⇒ <span style={{ fontSize: 15, fontWeight: 500 }}>Close OSW Modal</span>
                </li>
              </ul>
            </Typography.Paragraph>
          </Col>
          <Col span={24}>
            <Row gutter={[16, 16]}>
              <Col span={8}>
                <NJVInput
                  ref={this.weightRef}
                  value={weight}
                  bgcolor={Theme.colors.card_bg_color}
                  size="large"
                  placeholder="Enter weight in KG"
                  onChange={(e) => this.handleChange('weight', e.target.value)}
                  disabled={data.length > 10}
                  style={{ height: 50 }}
                />
              </Col>
              <Col span={16} style={{ textAlign: 'right' }}>
                {
                  data.length >= 1 && <Button
                    type="primary"
                    size="large"
                    onClick={() => this.submitOSWList()}
                    loading={isUpdatingOSW}
                  >
                    Submit OSW
                  </Button>
                }
              </Col>
              <Col span={24}
                style={{ marginTop: 10 }}>
                <Table
                  dataSource={data}
                  columns={columns}
                  pagination={false}
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </>
    )
  }
}

export default compose(withRouter)(OSWPage)