import { compose } from "@reduxjs/toolkit";
import { Button, Result } from "antd";
import React from "react";
import { Link } from "react-router-dom";
import withRouter from "../../network/with-router";
import CustomPath from "../../routes/custom-path";

class NotFound extends React.Component {
    render() {
        return (
            <Result
                status="404"
                title="404"
                subTitle="Sorry, the page you visited does not exist."
                extra={<Link to={CustomPath.dashboard}><Button type="primary">Back Home</Button></Link>}
            />
        )
    }
}

export default compose(withRouter)(NotFound);
